<template>
  <div>
    <!-- Hidden File Upload Item-->
    <input id="mediaUploadLBC" ref="mediaUploadLBC" type="file" hidden @change="uploadFile"/>
    <!-- Pagination limit & Upload area -->
    <v-row>
      <!-- Upload area -->
      <v-col cols="12" class="d-flex justify-end align-center" style="margin-top: 2px;">
        <!-- Kill All (to Draft) -->
        <div class="mr-2">
          <v-btn v-if="screenContents && screenContents.length > 0" color="secondary" class="darken-2"
            @click="openKillAllDialog = true">
            Revert to Default
          </v-btn>
        </div>
        <!-- drag & drop upload -->
        <div v-if="$store.state.Permissions.uploadMedia"
          @click.stop="uploadMedia('click')" @drop="(event) => {uploadMedia('drop', event)}"
          @dragover.prevent
          @drop.prevent style="width: 150px;">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <div ref="dragDiv" v-bind="attrs" v-on="on" @drop="dragFile"
                  @dragleave="$refs.dragDiv.style.backgroundColor = 'transparent'"
                  @dragover="$refs.dragDiv.style.backgroundColor = 'grey'" style="
                    background-color: transparent;
                    padding: 0.66em 0em;
                    transition: 0.1s;
                  "
                  :style="hover ? { border: 'solid 1px rgba(255,255,255, .8)' } : { border: 'solid 1px rgba(255,255,255, .3)' }"
                  class="rounded">
                  <div style="width:100%; text-align:center;">
                    <v-icon large>
                      mdi-cloud-upload
                    </v-icon>
                  </div>
                </div>
              </v-hover>
            </template>
            <span>Upload Creatives</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>

    <!-- Content cards (the uploaded media) -->
    <v-row v-if="screenContents">
      <v-col
        v-for="(screenContent, index) in filteredScreenContents.slice((this.pageNum - 1) * this.limit, this.pageNum * this.limit)"
        :key="index" cols="auto">
        <v-card style="height: 100%; width: 100%;" class="ma-0 pa-0 d-flex flex-column card"
          v-bind:class="{ flipme: cardOne[screenContent.id] == 'flipped' }"
          :style="activeNewsStory.id === screenContent.id ? 'background-color: #2B2B2B;' : 'background-color: transparent;'">
          <div class="px-0 py-0">
            <!-- Raw media preview (middle of card) -->
            <v-row class="mt-0" :class="cardOne[screenContent.id] == 'flipped' ? 'card__face--back' : 'card__face'">
              <v-col cols="12" class="mt-0 mr-0 d-flex align-left justify-center">
                <!-- Image -->
                <!-- Skeleton loader  -->
                <div name="fade">
                  <div 
                  v-show="previewLoading" 
                  style="position: absolute; 
                  z-index: 3; 
                  background-color: black;"
                  :style="{ width: previewWidth * (previewScale / 100) + 'px', height: previewHeight * (previewScale / 100) + 'px', }">
                    <v-skeleton-loader 
                    type="image" 
                    width="100%" 
                    height="100%"
                    style="border-radius: 0px !important;" />
                  </div>
                  <v-skeleton-loader v-show="previewLoading" type="image" />
                </div>
                <div ref="iFrameContainer"
                  :style="{ width: previewWidth * (previewScale / 100) + 'px', height: previewHeight * (previewScale / 100) + 'px', transform: 'scale(' + previewScale / 100 + ')', transformOrigin: '0 0', }">
                  <iframe ref="iFrameView"
                    :style="{ width: (100 * (100 / previewScale)) + '%', height: (100 * (100 / previewScale)) + '%', border: 'none', }"
                    :src="screenContent.iFrameURL" allow="autoplay" id="htmlPreviewIframeMicro"
                    onLoad="this.name = Date.now();">
                  </iframe>
                </div>
              </v-col>
            </v-row>
            <!-- Triggers -->
            <!-- Triggers: UI Indicators -->
            <v-row class="mt-0 align-center mx-n2">
              <!-- Delete media -->
              <v-col cols="1" class="d-flex align-left justify-center" style="margin-left:15px">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" v-if="allowScreenMediaRemove" color="red" icon class="mt-n2 mx-2"
                      @click="
                      deleteContentDialog = true;
                    activeNewsStory = screenContent;
                    selectedContentForTriggerEdit = JSON.parse(JSON.stringify(screenContent));
                    " style="background-color: transparent;">
                      <v-icon style="font-size: 20px">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete News Story</span>
                </v-tooltip>
              </v-col>
              <!-- Triggers: GoLive Trigger -->
              <v-col v-if="screenLevelTriggers.includes('golive') && screenContent.triggers.golive" cols="8"
                class=" mt-n2 d-flex align-center justify-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <div style="cursor: pointer"
                        :style="{
                          color: screenContent.liveStatusColor
                        }"
                        @click="indicatorClick(screenContent)">
                        <span>
                          <i class="mdi mdi-flash" aria-hidden="true"></i>
                        </span>
                        <span>{{ screenContent.liveStatusText }}</span>
                      </div>
                    </div>
                  </template>
                  <span class="d-flex align-center justify-center" v-html="screenContent.liveStatusTooltip"></span>
                </v-tooltip>
              </v-col>

              <!-- Open LBC Modal -->
              <!--
              <v-col cols="1" class="d-flex align-left justify-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary" icon small class="mr-1 mt-0 mx-2 my-2"
                      @click="openMultipleFormatsDialog(screenContent)">
                      <v-icon style="font-size: 20px">mdi-link-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Multiple Screens Preview</span>
                </v-tooltip>
              </v-col>
              -->

              <!-- Edit Content Image -->
              <v-col cols="1" class="d-flex align-left justify-center">

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-btn v-bind="attrs" v-on="on" color="primary" icon small class="mr-1 mt-0 mx-2 my-2"
                        @click.stop="replaceArtwork('click',screenContent)" @drop="(event) => {replaceArtwork('drop',screenContent, event)}"
                        @dragleave="dragFileUpload('dragDiv' + index, 'transparent'); dragFilePlusUpload('dragDivPlus' + index, 'hide');"
                        @dragover="dragFileUpload('dragDiv' + index, 'grey'); dragFilePlusUpload('dragDivPlus' + index, 'show'); activeNewsStory = screenContent;"
                        @mouseleave="dragFileUpload('dragDiv' + index, 'transparent'); dragFilePlusUpload('dragDivPlus' + index, 'hide');"
                        @mouseover="dragFileUpload('dragDiv' + index, 'grey'); dragFilePlusUpload('dragDivPlus' + index, 'show');"
                        @dragover.prevent @drop.prevent :style="{cursor: 'pointer'}">
                        <v-icon style="font-size: 20px">mdi-image-edit-outline</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Replace Artwork</span>
                </v-tooltip>
              </v-col>

              <!-- Open LBC Modal -->
              <v-col cols="1" class="d-flex align-left justify-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary" icon small class="mr-1 mt-0 mx-2 my-2"
                      @click="openMultipleFormatsDialog(screenContent)">
                      <v-icon style="font-size: 20px">mdi-text-box-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit News Story</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- No media items added yet -->
    <v-row v-if="filteredScreenContents === null || filteredScreenContents.length === 0">
      <v-col cols="12">
        <span>No media items have been uploaded to this screen.</span>
      </v-col>
    </v-row>
    <!-- Pagination -->
    <v-row>
      <v-col cols="12" class="text-right">
        <!-- v-pagionation and the length is based on the filtee -->
        <v-pagination v-if="this.screenContents && Math.ceil(this.screenContents.length / this.limit) > 1"
          v-model="pageNum" circle :length="Math.ceil(this.filteredScreenContents.length / this.limit)" />
      </v-col>
    </v-row>

    <!-- Dialogs -->
    <!-- DraftPlaceholder Dialog -->
    <v-dialog v-model="showDraftPlaceholderDialog" persistent width="500">
      <v-card>
        <v-card-title class="white--text primary">
          Create Placeholder
        </v-card-title>
        <v-card-text>
          <!-- Placeholder Text input -->
          <v-row>
            <h4>Type in placeholder description:</h4>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field v-model="draftPlacholderText" outlined label="Placeholder Text" />
            </v-col>
          </v-row>

          <!-- Create buttons -->
          <v-row justify="center" class="mt-3">
            <!-- Confirm create button -->
            <v-btn class="mx-3" color="primary" :disabled="placeholderCreatingFlag" :loading="placeholderCreatingFlag"
              @click="confirmCreatePlaceholder">
              Create
            </v-btn>

            <!-- Decline create button -->
            <v-btn class="mx-3" color="red" :disabled="placeholderCreatingFlag" @click="cancelCreatePlaceholder()">
              Cancel
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- File Upload Dialog -->
    <v-dialog v-model="showUploadMediaDialog" persistent width="500">
      <v-card>
        <v-card-title class="white--text primary">
          {{ artworkReplace ? "Replace Media" : "Upload Media" }}
        </v-card-title>
        <v-card-text>
          <!-- Files selected title -->
          <v-row>
            <p>JPG recommended</p>
          </v-row>

          <v-row v-if="isArtworkFileBigger">
            <v-alert type="warning">
              Artwork exceeds recommended file size limits. Please compress and then re-upload.
            </v-alert>
          </v-row>
          <v-row>
            <h4>Files Selected:</h4>
          </v-row>

          <!-- Files selected -->
          <v-row>
            <ul>
              <li v-for="(media, index) in uploadedFileCreative" :key="index">
                <span  
                  :style="{
                    color: media.err ? 'red' : 'white',
                  }">
                  {{ media.name }}
                </span>
                <v-tooltip content-class="top" top>
                  <template v-slot:activator="{ attrs, on }">
                    <v-icon v-bind="attrs" v-on="on" @click="removeUploadMedia(media)" small color="black"
                      class="fab-icon ml-1">
                      mdi-close
                    </v-icon>
                  </template>
                  <span>Remove</span>
                </v-tooltip>
              </li>
            </ul>
          </v-row>

          <v-row v-if="artworkReplace && !isArtworkFileBigger">
            <v-alert type="warning">
              Replacing the media will set the news story to draft. Please confirm to proceed.
            </v-alert>
          </v-row>
          <!-- Upload buttons -->
          <v-row justify="center" class="mt-3">
            <!-- Confirm upload button -->
            <v-btn class="mx-3" color="primary" :disabled="uploadedFileCreative === null || mediaUploadingFlag"
              :loading="mediaUploadingFlag" @click="uploadContentQueue" v-if="!isArtworkFileBigger">
              Confirm
            </v-btn>

            <!-- Decline upload button -->
            <v-btn class="mx-3" color="red" @click="cancelUploadMedia()">
              {{ (isArtworkFileBigger ? "Close" : "Cancel") }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Delete content dialog -->
    <v-dialog v-if="selectedContentForTriggerEdit" v-model="deleteContentDialog" width="600" py-2>
      <v-card class="px-6 py-3">
        Are you sure you want to delete this news story?
        <!--{{ selectedContentForTriggerEdit.media.originalFileName }}?-->
        <v-card-actions>
          <v-col cols="12" class="text-right ma-0 pa-0">
            <v-btn color="primary" class="mr-3" @click="deleteContentFromScreen">
              Confirm
            </v-btn>
            <v-btn color="red" @click="deleteContentDialog = false;">
              Cancel
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Full screen preview dialog -->
    <v-dialog v-model="fullscreenPreviewDialog" width="600">
      <v-card>
        <v-row>
          <v-col cols="12" class="text-left">
            <v-btn color="primary" icon :href="this.fullsizePreviewImgUrl" target="blank">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-img contain :src="fullsizePreviewImgUrl" />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Go Live Change onClick modal confirmation-->
    <v-dialog v-model="goLiveDialog" v-if="selectedContentForTriggerEdit" width="500">
      <v-card>
        <v-card-title class="white--text primary">
          {{
            getGoLivePublishState(selectedContentForTriggerEdit.triggers.golive.islive) === 'Draft' ?
              'Publish News Story' :
              'Unpublish News Story'
          }}
        </v-card-title>
        <v-card-text>
          <v-row class="align-center text-center">
            <v-col cols="12">
              <p>{{ getGoLivePublishState(selectedContentForTriggerEdit.triggers.golive.islive) === 'Draft' ?
            'You are publishing this news story. \n Continue?' :
            'You are unpublishing this news story. \n Continue?'
                }}</p>
                <v-alert :type="(getGoLivePublishState(selectedContentForTriggerEdit.triggers.golive.islive) === 'Draft' ? 'success' : 'error')">This will {{ getGoLivePublishState(selectedContentForTriggerEdit.triggers.golive.islive) === 'Draft' ? 'publish' : 'unpublish'}} to each aspect ratio.</v-alert>
            </v-col>
          </v-row>
          <v-row justify="center" class="my-3">
            <v-btn class="mx-3" color="primary" @click="togglePublishState" :disabled="goLiveDialogSaving" :loading="goLiveDialogSaving">
              Confirm
            </v-btn>
            <v-btn class="mx-3" color="red" @click="goLiveDialog = false; selectedContentForTriggerEdit = null;" :disabled="goLiveDialogSaving" :loading="goLiveDialogSaving">
              Cancel
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- Kill all -->
    <v-dialog v-model="openKillAllDialog" width="500">
      <v-card>
        <v-card-title class="white--text primary">
          Kill All
        </v-card-title>
        <v-card-text>
          <v-row class="align-center text-center">
            <v-col cols="12">
              <p>Are you sure you want to move all news stories to DRAFT?</p>
              <!--<v-alert type="warning">This will only apply to this specific aspect ratio.</v-alert>-->
            </v-col>
          </v-row>
          <v-row justify="center" class="my-3">
            <v-btn class="mx-3" color="primary" @click="killAllNewsStories" :disabled="killAllDialogSaving" :loading="killAllDialogSaving">
              Confirm
            </v-btn>
            <v-btn class="mx-3" color="red" @click="openKillAllDialog = false" :disabled="killAllDialogSaving" :loading="killAllDialogSaving">
              Cancel
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Multiple Formats Dialog-->
    <v-dialog
      v-model="multipleFormatsDialog"
      ref="multipleFormatsDialog"
      v-if="selectedContentForTriggerEdit"
      :fullscreen= isFullScreen
      persistent

    >
      <v-card style="padding-top: 22px !important;">
        <v-progress-linear v-if="!linkedFormatsLoaderProgress.isloaded"
        :indeterminate="linkedFormatsLoaderProgress.current === 0"
        :value="linkedFormatsLoaderProgress.percentage"
        absolute
        top
        color="primary"
      ></v-progress-linear>
        <v-card-text>
          <v-row>
            <v-col style="background-color: rgb(22, 22, 22); border-radius: 4px;">
              <v-row class="br" style="height: calc(100%)">
                <ScreenManager :linkedFormats.sync="linkedFormats" style="width: 100%"/>
                <v-col v-if="false" cols="12" class="outer containerLBC">
                  <template >
                  <v-row class="top" style="
                      width: 450px;
                      height: 45px;
                      backdrop-filter: blur(10px) brightness(0.9) contrast(0.9) saturate(0.65);
                      box-shadow: rgb(0 0 0 / 38%) 0px 0px 15px !important;
                      border: 1px solid rgb(75 75 75 / 42%) !important;
                      border-radius: 7px;
                      margin-top: 16px;
                      margin-left: auto;
                      margin-right: auto;
                      padding: 5px 10px;
                      background: #1e1e1ed1;
                  ">

                      <!-- Portrait Orientation Button with Tooltip -->
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-btn :disabled="!linkedFormatsLoaderProgress.isloaded" fab x-small elevation="0" @click="setOrientation('portrait', 'free-form')" :color="(orientation == 'portrait' || orientation == 'both') ? 'var(--v-primary-base)' : ''" class="mr-2">
                                  <v-icon>mdi-crop-portrait</v-icon>
                              </v-btn>
                          </template>
                          <span>View Portrait Screens</span>
                      </v-tooltip>

                      <!-- Landscape Orientation Button with Tooltip -->
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-btn :disabled="!linkedFormatsLoaderProgress.isloaded" fab x-small elevation="0" @click="setOrientation('landscape', 'free-form')" :color="(orientation == 'landscape' || orientation == 'both') ? 'var(--v-primary-base)' : ''" class="mr-2">
                                  <v-icon>mdi-crop-landscape</v-icon>
                              </v-btn>
                          </template>
                          <span>View Landscape Screens</span>
                      </v-tooltip>

                      <!-- All Orientation Button with Tooltip -->
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-btn :disabled="!linkedFormatsLoaderProgress.isloaded" fab x-small elevation="0" @click="setOrientation('all', 'free-form')" :color="(orientation == 'all' || orientation == 'both') ? 'var(--v-primary-base)' : ''" class="mr-2">
                                  <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
                              </v-btn>
                          </template>
                          <span>View All Screens</span>
                      </v-tooltip>

                      <!-- Divider -->
                      <v-divider vertical style="border-color: #ffffff33;margin-left: 10px;margin-right: 10px;"></v-divider>

                      <!-- Toggle Lock All Button with Tooltip -->
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-btn :disabled="!linkedFormatsLoaderProgress.isloaded" fab x-small elevation="0" @click="toggleLockAllButton()" :color="(toggleLockAll) ? '#7f6520' : ''" class="mr-2">
                                  <v-icon>mdi-lock-open</v-icon>
                              </v-btn>
                          </template>
                          <span>Lock All Toggle</span>
                      </v-tooltip>

                      <!-- Toggle Select All Button with Tooltip -->
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-btn :disabled="!linkedFormatsLoaderProgress.isloaded" fab x-small elevation="0" @click="toggleSelectAllButton()" :color="(toggleSelectAll) ? 'var(--v-primary-base)' : ''" class="mr-2">
                                  <v-icon>mdi-select-all</v-icon>
                              </v-btn>
                          </template>
                          <span>Select All Toggle</span>
                      </v-tooltip>

                      <!-- Divider -->
                      <v-divider vertical style="border-color: #ffffff33;margin-left: 10px;margin-right: 10px;"></v-divider>

                      <!-- Fit Button with Tooltip -->
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-btn :disabled="!linkedFormatsLoaderProgress.isloaded" fab x-small elevation="0" @click="setOrientation(orientation, 'fit')" :color="(fit) ? 'var(--v-primary-base)' : ''" class="mr-2">
                                  <v-icon>mdi-package-variant-closed</v-icon>
                              </v-btn>
                          </template>
                          <span>Pack All Screens into View</span>
                      </v-tooltip>

                      <!-- Full Screen Button with Tooltip -->
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-btn :disabled="!linkedFormatsLoaderProgress.isloaded" fab x-small elevation="0" @click="isFullScreen = !isFullScreen; toggleFullScreen(false, true)" :color="(isFullScreen) ? 'var(--v-primary-base)' : ''">
                                  <v-icon>mdi-fullscreen</v-icon>
                              </v-btn>
                          </template>
                          <span>Full Screen Toggle</span>
                      </v-tooltip>

                      <!-- Slider with Tooltip -->
                      <v-slider
                      :disabled="!linkedFormatsLoaderProgress.isloaded"
                                  v-model="viewScale"
                                  :max="10"
                                  :min="1"
                                  :step="1"
                                  thumb-label
                                  style="padding-left: 5px;margin-right: -4px;"
                                  @change=" fit = false; frameFormatterColumn()"
                              ></v-slider>
                  </v-row>
                  <div ref="lbcViewport" class="bottom lbc-viewport lbc-viewport-windowed scroll" id="masterContainer" :style="{
                    overflow: linkedFormatsLoaderProgress.isloaded ? 'auto' : 'hidden'}">                  
                    <div class="box" v-for="format in linkedFormats" ref="iframeBoxes"
                        :style="{
                          width: format.campaignBurstScreenContent.campaignBurstScreen.screen.width + 'px',
                          height: format.campaignBurstScreenContent.campaignBurstScreen.screen.height + 'px',
                          backgroundColor: format.isFlagged && format.activeSave ? 'red' : (format.isFlagged ? 'darkred' : (format.isLocked ? '#7f6520' : (format.activeSave ? 'var(--v-primary-base)' : ''))),
                          opacity: linkedFormatsLoaderProgress.isloaded ? 1 : 0,
                          scale: linkedFormatsLoaderProgress.isloaded ? 1 : 0.8,
                          pointerEvents: linkedFormatsLoaderProgress.isloaded ? 'auto' : 'none',
                          }" 
                        v-bind:key="format.campaignBurstScreenContent.id"
                        :orientation='format.campaignBurstScreenContent.campaignBurstScreen.screen.orientation.toLowerCase()'
                        :aspectRatio='format.campaignBurstScreenContent.campaignBurstScreen.screen.aspectRatio'
                        :w='format.campaignBurstScreenContent.campaignBurstScreen.screen.width'
                        :h='format.campaignBurstScreenContent.campaignBurstScreen.screen.height'
                        @mouseenter="hoveredFormat = format.campaignBurstScreenContent.campaignBurstScreen.screen.width + 'x' + format.campaignBurstScreenContent.campaignBurstScreen.screen.height"
                        @mouseleave="hoveredFormat = null"
                      >
                      <v-btn style="position: absolute;width: 100%;height: 100%;z-index: 1;background: transparent;" @click="toggleSelected(format)"></v-btn>
                      <div class="box-bar">
                        <span style="font-weight: 500; font-size: x-small; position: absolute;left: 4px;top: -2px; z-index: 1;">{{ format.campaignBurstScreenContent.campaignBurstScreen.screen.aspectRatio + ' (' + format.campaignBurstScreenContent.campaignBurstScreen.screen.width + 'x' + format.campaignBurstScreenContent.campaignBurstScreen.screen.height + ')' }}</span>
                        <!-- Solo Button -->
                        <div style="position: absolute;right: 22px;top: -2px;z-index: 2;">
                          <v-btn class="smaller-hit-area" fab x-small elevation="0" style="margin-top: -3px;background: transparent;" @click="toggleSolo(format)">
                            <v-icon small v-if="format.isSolo">mdi-circle</v-icon>
                            <v-icon small v-else>mdi-circle-outline</v-icon>
                          </v-btn>
                        </div>
                        <!-- Lock/Unlock button -->
                        <div style="position: absolute;right: 2px;top: -2px;z-index: 2;">
                          <v-btn class="smaller-hit-area" fab x-small elevation="0" style="margin-top: -3px;background: transparent;" @click="toggleLock(format)" :disabled="format.isFlagged">
                            <v-icon small v-if="format.isLocked">mdi-lock</v-icon>
                            <v-icon small v-else>mdi-lock-open-variant</v-icon>
                          </v-btn>
                        </div>
                        
                      </div>
                        <iframe 
                        class="box-iframe"
                    :style="{
                      width: format.campaignBurstScreenContent.campaignBurstScreen.screen.width + 'px',
                      height: format.campaignBurstScreenContent.campaignBurstScreen.screen.height  + 'px',
                      filter: format.activeSave ? 'brightness(100%)' : 'brightness(50%)' // Set brightness based on format.activeSave
                    }"
                    :src="format.campaignBurstScreenContent.iFrameURL"
                    :ref="'iframe' + format.campaignBurstScreenContent.id"
                    allow="autoplay"
                    onLoad="this.name = Date.now();"
                    @load="onIframeLoaded">
                  </iframe>
                  </div>
                  </div>
                    
                    </template>
                  </v-col>
              </v-row>
              <v-row class="bb" style="height: 40px">
                <div style="margin: -3px 8px;">
                  <v-tooltip top v-if="bottomBarSelectedFormats.totalCount > 0">
                      <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-overline">{{ bottomBarSelectedFormats.selectedCount }}/{{ bottomBarSelectedFormats.totalCount }} Selected</span>
                      </template>
                      <span v-if="bottomBarSelectedFormats.selectedCount > 0">{{ bottomBarSelectedFormats.selected }}</span>
                  </v-tooltip>
      <!-- <v-btn elevation="0" x-small tile color="transparent" height="40px"><v-icon color="grey">mdi-card-outline</v-icon></v-btn>
      <v-btn elevation="0" x-small tile color="transparent" height="40px"><v-icon color="grey">mdi-card-multiple-outline</v-icon></v-btn>
      <v-btn elevation="0" x-small tile color="transparent" height="40px"><v-icon color="grey">mdi-select-group</v-icon></v-btn> -->
    </div>
    <!-- <v-slide-group
      v-model="model"
      multiple
      show-arrows
      class ="mt-2 mb-2"
    >
      <v-slide-item
        v-for="n in 15"
        :key="n"
        v-slot="{ active, toggle }"
      >
        <v-card
          :color="active ? 'primary' : 'grey lighten-1'"
          class="ma-1"
          height="100"
          width="75"
          @click="toggle"
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-scale-transition>
              <v-icon
                v-if="active"
                color="white"
                size="20"
                v-text="'mdi-check-circle-outline'"
              ></v-icon>
            </v-scale-transition>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group> -->

              </v-row>
            <!-- IFrame Grid -->
            
          </v-col>

            <!-- TRIGGERS EDIT -->
            <v-col cols="auto" class="text-left " style="display: flex;flex-direction: column;flex-wrap: wrap;padding: 0px 0px 0px 10px !important;width:402px">
              <div ref="lbcViewport2" class="scroll lbc-viewport-2" id="lbcViewport2">
              <div v-for="(triggerForm, i) in triggerForm">
                <div v-if="triggersToShow.includes(triggerForm.paramName)" class="d-flex flex-column">
                  <!-- Trigger edit container (shorthand: display day of week horizontally and not vertical) -->
                  <div :class="(triggerForm.paramName === 'dayOfWeek') ? 'd-flex justify-center px-2' : ''">
                    <div v-for="(item, i) in triggerForm.parameters" :key="i">
                      <v-col
                        v-if="shouldShowOn(item.showOn, selectedContentForTriggerEdit.triggers, triggerForm.paramName)" class="my-0 py-0 mt-2" >
                        <div v-if="item && item.paramName !== 'previewPlaceholder'">

                          <!-- Dropdown option (item.format === 'dropdown')  -->  
                          <!-- <v-divider v-if="item.friendlyName === 'Story State' || item.friendlyName === 'Headline' || item.friendlyName === 'Move Left/Right'" style="border-color: rgba(255, 255, 255, 0.2);margin-top: 13px;margin-left: 7px;"></v-divider> -->
                          <div style="display:flex"> 
                            <div v-if="triggersToHide.includes(triggerForm.paramName + '.' + item.paramName) || item.format === 'singleSlider'">
                              <!-- Do Nothing - Hide This -->
                            </div>
                            <span v-else>
                              <div class="text-overline" style="display: flex;margin-top:0px">{{ item.friendlyName }}
                                <div style="margin-top:-1px">
                                  <InfoHover :text="item.comments" />
                                </div>
                                </div>
                            </span>
                          </div>
                          <div v-if="triggersToHide.includes(triggerForm.paramName + '.' + item.paramName) || item.format === 'singleSlider'">
                            <!-- Do Nothing - Hide This -->
                          </div>
                          <v-select v-else-if="item.format === 'dropdown'"
                            :autofocus='openOnSequence' 
                            :items="item.values" 
                            :disabled="!linkedFormatsLoaderProgress.isloaded" 
                            :input-value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                            :key="item.label"
                            :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                            :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                            :label="item.friendlyName" :outlined="true" :type="item.type"
                            @input="updateTrigger(triggerForm.paramName, item.paramName, $event)"
                            hide-details dense></v-select>

                          <!-- Dropdown Multiple option (item.format === 'dropdownMultiple')  -->
                          <v-select :autofocus='openOnSequence' :items="item.values" :disabled="!linkedFormatsLoaderProgress.isloaded"
                            v-else-if="item.format === 'dropdownMultiple'"
                            :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName] ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split(',') : []"
                            :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                            :label="item.friendlyName" :outlined="true" :type="item.type"
                            @input="updateTrigger(triggerForm.paramName, item.paramName, $event.join(','))" hide-details
                            multiple dense></v-select>

                          <!--*******************************************************************************************************************-->

                          <!-- HTML Preview (item.format === 'htmlPreview')  -->
                          <div class="pb-5" v-else-if="item.format === 'htmlPreview'">
                            <div ref="iFrameContainer" :style="{
                              width: previewWidth * (previewScale / 100) + 'px',
                              height: previewHeight * (previewScale / 100) + 'px',
                              transform: 'scale(' + previewScale / 100 + ')',
                              transformOrigin: '0 0',
                              }" v-if="false">
                                                  <iframe ref="iFrameView" :style="{
                                width: (100 * (100 / previewScale)) + '%',
                                height: (100 * (100 / previewScale)) + '%',
                                border: 'none',
                              }" :src="previewUrl" allow="autoplay" id="htmlPreviewIframeRight"
                                onLoad="this.name = Date.now();">
                              </iframe>
                            </div>
                          </div>

                          <!-- Dual Single Slider (item.format === 'singleSlider')  -->
                          <!-- <div v-else-if="item.format === 'singleSlider'" class="ma-0 pa-0">
                            <p>triggerForm.paramName{{ triggerForm.paramName }}, item.paramName{{ item.paramName }},</p>
                            <v-slider color="primary" :disabled="!linkedFormatsLoaderProgress.isloaded"
                              :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                              :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                              @input="updateTrigger(triggerForm.paramName, item.paramName, $event);" class="ma-0 pa-0"
                              :min="getRule('min', item.rules)" :max="getRule('max', item.rules)" step="0.1" hide-details dense>
                              <template v-slot:prepend>
                                <input
                                  :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  class="ma-0 pa-0" hide-details flat solo type="number" style="width: 54px;"
                                  :min="getRule('min', item.rules)" :max="getRule('max', item.rules)"
                                  @input="updateTrigger(triggerForm.paramName, item.paramName, $event.target.value)" />
                              </template>
                            </v-slider>
                          </div> -->

                          <!-- Dual Range Slider (item.format === 'dualSlider')  -->
                          <div v-else-if="item.format === 'dualSlider'" class="ma-0 pa-0">
                            <v-range-slider color="info" track-color="info lighten-4" :disabled="!linkedFormatsLoaderProgress.isloaded"
                              :value="(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]) ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split(',').map(Number) : item.defaultValue.split(',').map(Number)"
                              :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                              @input="updateTrigger(triggerForm.paramName, item.paramName, $event.join(','))"
                              class="ma-0 pa-0" :min="getRule('min', item.rules)" :max="getRule('max', item.rules)"
                              step="0.1" hide-details dense>
                              <template v-slot:prepend>
                                <input
                                  :value="(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]) ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split(',').map(Number)[0] : item.defaultValue.split(',').map(Number)"
                                  class="ma-0 pa-0" hide-details flat solo type="number" style="width: 80px"
                                  :min="getRule('min', item.rules)" :max="getRule('max', item.rules)"
                                  @input="updateTriggerStrArr(triggerForm.paramName, item.paramName, $event.target.value, 0)" />
                              </template>
                              <template v-slot:append>
                                <input
                                  :value="(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]) ? selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName].split(',').map(Number)[1] : item.defaultValue.split(',').map(Number)"
                                  class="ma-0 pa-0" hide-details flat solo type="number" style="width: 80px"
                                  :min="getRule('min', item.rules)" :max="getRule('max', item.rules)"
                                  @input="updateTriggerStrArr(triggerForm.paramName, item.paramName, $event.target.value, 1)" />
                              </template>
                            </v-range-slider>
                          </div>

                          <!-- Dual Range Slider (item.format === 'dateRange')  -->
                          <div v-else-if="item.format === 'dateRange'" class="ma-0 pa-0">
                            <v-row>
                              <v-col cols=12 style="margin-bottom: -12px;">
                                <div>
                                <date-range-picker ref="picker"
                                  :disabled="!linkedFormatsLoaderProgress.isloaded" 
                                  :locale-data="{ firstDay: 0, format: 'yyyy-mm-dd HH:MM', separator: ' - ' }"
                                  :dateRange="toDateRange(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName])"
                                  :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                  :single-date-picker=false :time-picker=true :auto-apply=false :append-to-body=true
                                  :ranges=false opens="left"
                                  @update="updateTrigger(triggerForm.paramName, item.paramName, selectedContentForTriggerEdit.dateRangeIndefinite ? '|' : toTriggerDateRange($event))"
                                  @select="handleCalendarSelect"
                                  @start-selection="handleCalendarSelect"
                                  @finish-selection="handleCalendarSelect"
                                  @toggle="handleCalendarToggle" dense>
                                  <template v-slot:input="picker" style="min-width: 350px; background-color: black;">
                                    {{ selectedContentForTriggerEdit.dateRangeIndefinite ? 'Indefinite' : calendarDateRange(picker.startDate) + ' - ' + calendarDateRange(picker.endDate) }}
                                  </template>
                                  <!-- -->
                                  <template v-slot:footer="picker">

                                    <div class="drp-buttons" style="
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                    ">
                                        <div style="
                                            width: 20%;
                                            margin-left: -16px;
                                        ">
                                            <input 
                                                type="checkbox" 
                                                @click="setIndefinite(picker)" 
                                                :disabled="!linkedFormatsLoaderProgress.isloaded" 
                                                :checked="selectedContentForTriggerEdit.dateRangeIndefinite ? true : false">
                                                Indefinite?
                                            </input>
                                        </div>
                                        <span class="drp-selected" style="
                                            flex-grow: 1;
                                            text-align: center;
                                        ">{{ picker.rangeText }}</span>
                                        <div style="width: 20%; display: flex; justify-content: flex-end;">
                                            <v-btn @click="handleCalendarCancel(picker)" elevation="0"  color="var(--v-sidebarColorLight-lighten2)" style="margin-right: 8px;" small>Cancel</v-btn>
                                            <v-btn @click="handleCalendarApply(picker)" elevation="0" color="var(--v-secondary-base)" small>Apply</v-btn>
                                        </div>
                                    </div>

                                  </template>
                                </date-range-picker>
                                </div>
                              </v-col>
                            </v-row>
                          </div>

                          <!-- Dropdown Multiple option (item.format === 'locationListDropdownMultiple')  -->
                          <v-combobox
                              :autofocus='openOnSequence'
                              :items="regions"
                              v-else-if="item.format === 'locationListDropdownMultiple'"
                              v-model="regionsSearchText[item.paramName]"
                              :label="item.friendlyName"
                              :outlined="true"
                              @change="updateTrigger(triggerForm.paramName, item.paramName, regionsSearchText[item.paramName].join(','))"
                              @input="updateTrigger(triggerForm.paramName, item.paramName, regionsSearchText[item.paramName].join(','))"
                              hide-details
                              multiple
                              item-text="value"
                              small-chips
                              style="width: 100%"
                              append-icon="mdi-plus"
                              @keypress.tab.prevent.native="stopKey"
                              @keydown.tab.prevent.native="stopKey"
                              @keyup.tab.prevent.native="stopKey"
                              @keydown.enter.prevent.native="submitSearch(triggerForm.paramName, item.paramName, i, $event)"
                              @keyup="stopKey"
                              @keypress="stopKey"
                              :search-input.sync="searchInputs[i]"
                              :return-object="false"
                              :key="`lldm_${item.paramName}_${i}`"
                              :disabled="!linkedFormatsLoaderProgress.isloaded"
                            >
                            <template v-slot:no-data>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            No matching region "<strong>{{ searchInputs[i] }}</strong>". Click <kbd>+</kbd> to create a new one
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <template v-slot:selection="{ attrs, item, parent, selected }" >
                                <v-chip
                                    v-bind="attrs"
                                    :style="'background: none; border: 1px solid #fff'"
                                    :input-value="selected"
                                    label
                                    small
                                >
                                    <span class="pr-2">
                                        {{ item }}
                                    </span>
                                    <v-icon
                                        small
                                        @click="parent.selectItem(item)"
                                    >
                                        mdi-close
                                    </v-icon>
                                </v-chip>
                            </template>
                          </v-combobox>

                          <!-- Dynamically inject other formats -->
                          <div v-else>
                            <validation-provider v-slot="{ errors }" :rules="item.rules">
                              <v-textarea
                                v-if="item.friendlyName === 'Headline' && multiLineEditor"
                                :disabled="!linkedFormatsLoaderProgress.isloaded" 
                                :ref="item.paramName"
                                :key="item.label"
                                :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                @paste="checkTriggerRules($event, item)"
                                @keyup="disableSaveTriggerBtn(errors[0], item.paramName)"
                                @keypress="checkTriggerRules($event, item)"
                                @input="updateTrigger(triggerForm.paramName, item.paramName, $event)"
                                hide-details
                                :label="item.friendlyName"
                                :outlined="true"
                                dense
                                rows="2"
                              />
                              <component
                              :ref="item.paramName"
                              :key="item.label"
                              :is="item.component"
                              :type="item.type"
                                      :input-value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                              :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                      :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                    
                              @paste="checkTriggerRules($event, item)"
                              @keyup="disableSaveTriggerBtn(errors[0], item.paramName)"
                              @keypress="checkTriggerRules($event, item)"
                              @input="updateTrigger(triggerForm.paramName, item.paramName, $event)"
                              @change="updateTrigger(triggerForm.paramName, item.paramName, $event)"
                              dense
                              hide-details
                                      :true-value="true" :false-value="false"
                                      :disabled="!linkedFormatsLoaderProgress.isloaded || item.format === 'Warning'"
                              :label="item.friendlyName"
                              :outlined="true"
                                      :color="(item.format === 'CheckBoxTrafficLight') ? (isTrue(selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]) ? '#98341f' : '#00FF00') : ''"
                            />
                                    <div class="mt-1 ml-1 red--text">
                                      {{ triggerErrorMessage(errors[0], item.paramName) }}
                                    </div>
                            <!-- add quotations button on headline and sub-headline-->
                            <v-btn
                              v-if="item.paramName === 'headline' || item.paramName === 'subHeadline'"
                              @click="addQuotations(triggerForm.paramName, item.paramName)"
                              small
                              elevation="0"
                              color="var(--v-sidebarColorLight-lighten1)"
                              class="mr-1 mt-1"
                              style="height: 26px;"
                              outlined
                              :disabled="!linkedFormatsLoaderProgress.isloaded" 
                            >
                              <v-icon style="color:lightgrey">mdi-format-quote-open</v-icon>
                            </v-btn>
                            <div class="mt-1 ml-1 red--text">
                              {{ triggerErrorMessage(errors[0], item.paramName) }}
                            </div>
                          </validation-provider>
                        </div>
                      </div>

                      </v-col>
                    </div>
                  </div>
                </div>
              </div>
              <div>


                <v-card v-if="linkedFormatsLoaderProgress.isloaded && containerWidth > 0"
                  class="mx-auto"
                  max-width="376"
                  elevation="0"
                  background="none"
                  style="border-radius:4px; background-color: #2c2c2c"
                >
                <div ref="focalContainerWrapper">
                  <div 
                    ref="focalContainer"
                    class="focal-container" 
                    :style="focalContainerStyle" 
                    @mousedown="startDrag" 
                    @mousemove="onMouseMove" 
                    @mouseup="stopDrag" 
                    @mouseleave="stopDrag"
                  >
                    <v-img :src="activeNewsStory.media.url" style="width: 100%;height:100%"></v-img>
                    <div v-if="!imageManualMode"
                      class="crop-box" 
                      :style="cropBoxStyle" 
                      @click="saveFocalPoint" 
                      @mousedown.stop="startDrag"
                    >
                      <div 
                        v-for="handle in resizeHandles" 
                        :key="handle" 
                        :class="['resize-handle', handle]" 
                        @mousedown.stop="startResize(handle, $event)"
                      ></div>
                    </div>
                    <div v-if="!imageManualMode" class="saved-focal-point" :style="savedFocalPointStyle">
                      <div style="width: 25px; height: 25px; transform: 50% 50%;">
                        <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="-60.67 -60.67 525.8 525.8" xml:space="preserve">
  <defs>
    <filter id="a" x="-50%" y="-50%" width="200%" height="200%">
      <feDropShadow dx="0" dy="0" stdDeviation="27" flood-color="rgba(0,0,0,1)"/>
    </filter>
  </defs>
  <g filter="url(#a)">
    <circle stroke-width="80" cx="202.224" cy="202.228" r="26.686" fill="var(--v-primary-base)" stroke="var(--v-primary-base)"/>
    <path fill="#dcdcdc" stroke="#dcdcdc" stroke-width="40" d="M83.807 49.322 50.87 4.891c-9.519-9.527-30.71-3.78-40.236 5.747S-4.64 41.347 4.887 50.874l44.431 32.937c9.519 9.519 24.963 9.527 34.49 0 9.527-9.52 9.519-24.964 0-34.489zm310.016-38.684c-9.527-9.527-30.71-15.274-40.236-5.747L320.65 49.322c-9.527 9.527-9.527 24.963 0 34.49s24.963 9.519 34.49 0l44.431-32.937c9.527-9.527 3.78-30.71-5.747-40.237zm-38.684 310.007c-9.527-9.527-24.963-9.527-34.49 0s-9.527 24.963 0 34.49l32.937 44.431c9.527 9.519 30.71 3.78 40.236-5.747s15.274-30.71 5.747-40.236zm-305.821 0L4.887 353.582c-9.527 9.527-3.78 30.71 5.747 40.236s30.71 15.274 40.236 5.747l32.937-44.431c9.527-9.519 9.527-24.963 0-34.49-9.527-9.52-24.963-9.52-34.49 0z"/>
  </g>
</svg>


                      </div>
                    </div>

                    <div v-if="false &&!imageManualMode" class="info">
                      Size: <span class="size-info">{{ sizeInfo }}</span><br>
                      Position: <span class="position-info">{{ positionInfo }}</span><br>
                      Focal Point: <span class="focal-info">{{ focalInfo }}</span><br>
                      Saved Focal Point: <span class="saved-focal-info">{{ savedFocalInfo }}</span>
                    </div>
                    <div
                      v-for="(box, index) in focalPointBoundaries" 
                      :key="index"
                      class="aspect-ratio-box"
                      :class="{ 'aspect-ratio-box-selected': hoveredFormat === box.containerWidth + 'x' + box.containerHeight, 'aspect-ratio-box-unselected': hoveredFormat !== null && hoveredFormat !== box.containerWidth + 'x' + box.containerHeight, 'aspect-ratio-box-disabled': !aspectRatiosVisible  }"
                      :style="getAspectRatioBoxStyle(box)"
                    >
                    <span v-if="hoveredFormat === box.containerWidth + 'x' + box.containerHeight" class="text-overline" style="top: -4px;position: absolute;left: 5px;">
                      {{box.aspectRatio}}
                    </span>
                  </div>
                  </div>
                </div>

                  <v-card-actions style="padding: 5px 8px;">

                    <v-tooltip top open-delay="400">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs" v-on="on"
                          :color="aspectRatiosVisible ? 'primary' : ''"
                          x-small
                          fab
                          elevation="0"
                          @click="toggleAspectRatios"
                        >
                        <v-icon>mdi-grid-large</v-icon>
                        </v-btn>
                       </template>
                      <span>Toggle Aspect Ratio Boxes</span>
                    </v-tooltip>

                    <v-tooltip top open-delay="400">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs" v-on="on"
                          class="ml-3"
                          :disabled="imageManualMode"
                          x-small
                          fab
                          elevation="0"
                          @click="resetCropBox"
                        >
                        <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                       </template>
                      <span>Reset Zoom and Focal Point</span>
                    </v-tooltip>
<!-- 
                    <v-tooltip top open-delay="400">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs" v-on="on"
                          :disabled="imageManualMode"
                          class="ml-3"
                          :color="autoZoom ? 'primary' : ''"
                          x-small
                          fab
                          elevation="0"
                          @click="toggleAutoZoom"
                        >
                        <v-icon >mdi-magnify-expand</v-icon>
                        </v-btn>
                       </template>
                      <span>Toggle Auto Zoom</span>
                    </v-tooltip> -->

                    <v-tooltip top open-delay="400">
                      <template v-slot:activator="{ on, attrs }">
                    <v-slider
                      v-if="autoZoom"
                      :disabled="imageManualMode"
                      color="primary"
                      v-model="autoZoomSlider"
                      class="ml-3"
                      :min="0"
                      :max="100"
                      step="0.001"
                      @end="updateAspectRatioBoxes"
                      hide-details
                      dense
                    ></v-slider>
                  </template>
                      <span>Zoom Slider</span>
                    </v-tooltip>
                    <div v-if="autoZoom" v-show="!imageManualMode" style="position: absolute;right: 41px;">{{autoZoomSlider}}</div>

                    <v-spacer></v-spacer>

                    <v-btn
                      icon
                      @click="imageManualMode = !imageManualMode"
                    >
                      <v-icon>{{ imageManualMode ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </v-card-actions>

                  <v-expand-transition>
                    <div v-show="imageManualMode">
                      <div style="width:100%; padding-bottom: 15px;">
                      <div v-for="(triggerForm, i) in triggerForm">
                        <div v-if="triggersToShow.includes(triggerForm.paramName)" class="d-flex flex-column">
                          <!-- Trigger edit container (shorthand: display day of week horizontally and not vertical) -->
                          <div :class="(triggerForm.paramName === 'dayOfWeek') ? 'd-flex justify-center px-2' : ''">
                            <div v-for="(item, i) in triggerForm.parameters" :key="i" :style="{display: item.format === 'singleSlider' ? 'block' : 'none'}">
                              <v-col v-if="shouldShowOn(item.showOn, selectedContentForTriggerEdit.triggers, triggerForm.paramName)" class="my-0 py-0 mt-2" >
                                <div v-if="item && item.paramName !== 'previewPlaceholder'">

                                  <!-- Dropdown option (item.format === 'dropdown')  -->  
                                  <div style="display:flex"> 
                                    <div v-if="item.format === 'singleSlider'" class="text-overline" style="margin-top:0px">{{ item.friendlyName }}
                                  <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on" style="margin-top: -2px; margin-left: 5px; margin-right: -6px;">
                                              <v-icon small style="color:grey;opacity: 0.5;">mdi-information-outline</v-icon>
                                            </span>
                                            </template>
                                            <span>{{ item.comments }}</span>
                                          </v-tooltip>
                                        </div>
                                  </div>
                                  <!-- Dual Single Slider (item.format === 'singleSlider')  -->
                                  <div v-if="item.format === 'singleSlider'" class="ma-0 pa-0">
                                    <v-slider
                                      color="primary"
                                      :disabled="!imageManualMode || !linkedFormatsLoaderProgress.isloaded"
                                      :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                      :v-model="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                      @input="handleImageInput(triggerForm.paramName, item.paramName, $event)"
                                      class="ma-0 pa-0"
                                      :min="getRule('min', item.rules)"
                                      :max="getRule('max', item.rules)"
                                      step="0.1"
                                      hide-details
                                      dense
                                    >
                                      <template v-slot:prepend>
                                        <v-btn
                                          x-small
                                          icon
                                          text
                                          class="mr-2"
                                          @click="handleImageInput(triggerForm.paramName, item.paramName, 0)"
                                        >
                                          <v-icon small>mdi-refresh</v-icon>
                                        </v-btn>
                                        <input
                                          :disabled="!imageManualMode"
                                          :value="selectedContentForTriggerEdit.triggers[triggerForm.paramName][item.paramName]"
                                          class="ma-0 pa-0"
                                          hide-details
                                          flat
                                          solo
                                          step="0.1"
                                          type="number"
                                          style="width: 59px;"
                                          :min="getRule('min', item.rules)"
                                          :max="getRule('max', item.rules)"
                                          @input="handleImageInput(triggerForm.paramName, item.paramName, $event.target.value)"
                                        />
                                      </template>
                                    </v-slider>
                                  </div>
                                </div>

                      </v-col>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                    </div>
                  </v-expand-transition>
                </v-card>
              </div>
              <!-- Save trigger -->

               <!-- TRIGGER END -->
                </div>
                <div style="height: 65px; border-radius: 4px; border-top-left-radius: 0px; border-top-right-radius: 0px;">
                  <v-col cols="12" class="text-right">
                <v-btn 
                  :loading="disableTriggerSave"
                  :disabled="!linkedFormatsLoaderProgress.isloaded"
                  @click="
                    submitTriggers(true), // Go Live -> Published
                    preventReload = false,
                    multipleFormatsDialog = false;
                  " 
                  color="pink" 
                  class="mx-1"
                >
                  Publish
                </v-btn>
                <v-btn 
                  :loading="disableTriggerSave" 
                  :disabled="!linkedFormatsLoaderProgress.isloaded"
                  @click="
                    submitTriggers(false), // Go Live -> Draft
                    preventReload = false,
                    multipleFormatsDialog = false;
                  " 
                  color="secondary" class="mx-1">
                  Save Draft
                </v-btn>
                <v-btn 
                  @click="
                    resetTriggers(); 
                    multipleFormatsDialog = false;
                  " 
                  color="red" 
                  class="mx-1" 
                  outlined
                  dense
                >
                  Cancel
                </v-btn>
              </v-col>
                </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Publish modal confirmation -->

    <!-- Save to Draft modal confirmation -->

    <!-- Cancel modal confirmation (if dataHasChanged) -->


  </div>
</template>
<script>
/** Triggers */
const triggersList = require(`@/assets/${process.env.VUE_APP_TRIGGERS_VERSION}`)
/** Controllers */
import CampaignController from '@/services/controllers/Campaign'
import UserController from "@/services/controllers/User";
/** Additional packages */
import moment from 'moment'
import Packery from 'packery';
import _ from 'lodash';

import $ from 'jquery';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/resizable';

/** Additional components */
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { VTextField, VCheckbox } from 'vuetify/lib'
import InfoHover from '@/components/_theme/InfoHover'
// LBC
import ScreenManager from '@/components/artwork/lbc/uploadMedia/ScreenManager.vue'

export default {
  name: 'UploadMediaTabLBC',
  components: {
    VTextField,
    DateRangePicker,
    VCheckbox,
    InfoHover,
    ScreenManager
  },

  filters: {
    momentHuman: function (date) {
      return moment(date).format('DD MMM YYYY hh:mm a')
    },
    momentHuman2: function (date) {
      return moment(date).format('DD MMM YYYY HH:mm a')
    },
  },

  data: () => ({
      searchInputs: [],
      regionsSearchText: { regions: [], antiRegions: []},
      debounceFlag: true,
      initialLoad: false,
      preventReload: false,
      saving: false,
      dateRangeFormat: 'YYYY-MM-DD HH:mm',
      triggersToShow: ['globalLBCnews', 'dateRange', 'region', 'restrictionsCompact'],
      triggersToHide: ['region.regions'],
      selectedArtWorkFilters: null,
      submittingTrigger: false,
      dataHasChanged: false,
      previewLocation: {},
      locationsList: [],
      disableTriggerSaveBtn: false,
      showUploadMediaDialog: false,
      showDraftPlaceholderDialog: false,
      draftPlacholderText: "",
      selectedDate: null,
      campaign: null,
      selectedBurst: null,
      campaignBursts: null,
      selectedCampaignBurstScreen: null,
      campaignBurstScreens: null,
      // Tabs
      tabOptions: ["uploadMedia", "locations", "airport", "preview", "resources", "settings" , "screenSpecs"],
      tab: 0,
      checkedTabOnLoad: false, 
      selectedTab: null,
      // Edit triggers
      disableTriggerSave: false,
      profanityDoubleCheckDialog: false,
      limit: 10,
      pageLimitOptions: [10, 20, 50],
      selectedTriggerFilter: [],
      availableTriggerFilters: [
        {
          text: 'Twitter',
          val: 'tweet',
        },
        {
          text: 'Instagram',
          val: 'instagram',
        },
        {
          text: 'Hero',
          val: 'hero',
        },
        {
          text: 'Highlight',
          val: 'highlight',
        },
      ],
      lastUpdatedTime: null,
      selectedScreen: null,
      uploadedFileCreative: [],
      showUploadCreativeDialog: false,
      mediaUploadingFlag: false,
      placeholderCreatingFlag: false,
      deleteContentDialog: false,
      screenLevelTriggers: null,
      screenContents: null,
      showFilterBar: false,
      screen: null,
      pageNum: 1,
      triggerBuilderDialog: false,
      selectedContentForTriggerEdit: null,
      selectedTriggerType: null,
      triggerJson: triggersList,
      placeholderContentTrigger: {},
      triggerForm: [],
      fullscreenPreviewDialog: false,
      fullsizePreviewImgUrl: null,
      ratio: null,
      breadcrumbItems: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/#/dashboard/campaigns',
        },
        {
          text: 'Dashboard',
          disabled: false,
        },
        {
          text: 'Burst',
          disabled: false,
        },
        {
          text: 'Uploaded Media',
          disabled: true,
        },
      ],
      openOnSequence: false,
      triggerSaveQueue: [],
      cloneSaveQueue: [],
      internationalShowtimeMovies: {},
      nationalRailStations: null,
      nationalRailOperators: null,
      iataAirports: null,
      rugbyTeams: [],
      ladbrokesInfo: {},
      watchesTracker: [],
      regionExpand: false,
      // Burst info
      burstDeliveryType: null,
      // Replace artwork
      artworkReplace: false,
      activeNewsStory: {},
      customSaveList: [],
      selectedArtToRepl: null,
      // Filters
      showAdvancedFilters: false,
      // Advanced Filters
      selectedGoLiveValue: [],
      goLiveItems: ['Archived', 'Draft', 'Preview', 'Published'],
      selectedDayOfWeekValue: [],
      dayOfWeekItems: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      selectedDateRangeValue: null,
      dateRangeItems: ['Today', 'Yesterday', 'Last 7 days', 'Last 30 days', 'This month', 'Last month', 'This year', 'Last year'],
      selectedTimeRangeValue: null,
      timeRangeItems: ['Last 24 hours', 'Last 48 hours', 'Last 7 days', 'Last 30 days', 'Last 60 days', 'Last 90 days'],
      selectedSortValue: {text: 'Group', value: 'group'},
      selectedRegionFilters: [],
      calendarTrigger: false,
      calendarText: '',
      calendarValue: null,
      startTimeValue: null,
      endTimeValue: null,
      // Timeline
      cardOne: {},
      // Size Check
      isArtworkFileBigger: false,
      // Loaders
      loadingScreens: false,
      //Sequence Colors
  
      colours: [["[0] None","#808080"],["[1] Red","#FF0000"],["[2] Orange","#FFA500"],["[3] Yellow","#FFFF00"],["[4] Green","#008000"],["[5] Blue","#0000FF"],["[6] Indigo","#4B0082"],["[7] Violet","#8F00FF"]],
      
      // LBC
      triggerLbcDialog: false,
      previewUrl: null,
      previewWidth: 1080,
      previewHeight: 1920,
      previewScaleEdit: 40,
      previewScale: 25,
      previewTime: moment().format('HH:mm'),
      previewDate: moment().format('YYYY-MM-DD'),
      previewPan: true,
      previewLoading: false,
      goLiveDialog: false,
      goLiveDialogSaving: false,
      openKillAllDialog: false,
      killAllDialogSaving: false,
      isLinkToggled: false,
      selectedScreensToLink: [],
      // LBC Multiple Formats
      multipleFormatsDialog: false,
      linkedFormatsData: [],
      linkedFormatsLoaded: false,
      previewScaleLinked: 30,
      orientation: 'all',
      fit: false,
      viewportScale: 0.25,
      viewScale: 4,

      toggleSelectAll: false,
      toggleLockAll: false,
      toggleIsSolo: false,
      isFullScreen: false,

      iframeLoadCount: 0,
      totalIframes: 0,

      totalLinkedFormats: 0,
      totalLinkedFormatsProgress: 0,

      //LBC MULTI-LINE EDITOR
      multiLineEditor: false,

      // FOCAL POINT
      // FOCAL POINT - 
      minWidth: 50,
      minHeight: 50,
      containerWidth: 0,
      containerHeight: 0,
      cropBoxLeft: 0,
      cropBoxTop: 0,
      cropBoxWidth: 300,
      cropBoxHeight: 400,
      savedFocalX: null,
      savedFocalY: null,
      currentFocalX: null,
      currentFocalY: null,
      aspectRatiosVisible: true,
      isDragging: false,
      isResizing: false,
      dragEvent: false,
      endResizeTime: null,
      resizeHandle: null,
      dragStartX: 0,
      dragStartY: 0,
      aspectRatioBoxes: [],
      resizeHandles: ['nw', 'n', 'ne', 'e', 'se', 's', 'sw', 'w'],
      autoZoom: true,
      imageManualMode: false,
      hoveredFormat: null,
      autoZoomSlider: 0,

      cropBoxMargin: 2,

      currentFocalPointAspectRatio: null,
      updateIframes: false,

      lbcBoundaries: [],
    }),


    async created() {
      //iframe listener
      this.iframeListener();

      // Get all medias in campaign burst screen
      await this.getCampaignById()
      //await this.getAllLocations()
      await this.checkForTabSelection()
    },


    computed: {
      regions() {
          let defaults = []
          let assignedRegions = this.screenContents.map((item) => {
                if(item.triggers.region)
                  return item.triggers.region.regions.split(',')
              }).flat()
          let locationRegions = this.locationsList.map((item) => {
                if(item.region)
                  return item.region.split(',')
              }).flat()
          // return both ordered
          let joinedList =  [...new Set([...assignedRegions, ...locationRegions])].sort()
          let outputList = [...new Set([...defaults, ...joinedList])]
          // sort output list, make distinct and remove blanks/undefined (Set above makes it distinct)
          outputList = outputList.filter((item) => item !== undefined && item !== '')
          return outputList
        },

        linkedFormats() {
          return this.linkedFormatsData;
        },
        bottomBarSelectedFormats() {
          const selected = this.linkedFormatsData
            .filter((format) => format.activeSave)
            .map((format) => {
              const { width, height, aspectRatio } = format.campaignBurstScreenContent.campaignBurstScreen.screen;
              return `${aspectRatio} (${width}x${height})`;
            });

          return {
            selected: selected.join(', '),
            selectedCount: selected.length,
            totalCount: this.linkedFormatsData.length,
          };
        },

  focalContainerStyle() {
    if (this.containerWidth === 0 || this.containerHeight === 0) {
      return {
        display: `none`,
      };
      
    }
      return {
        width: `${this.containerWidth}px`,
        height: `${this.containerHeight}px`,
        position: 'relative',
        overflow: 'hidden',
      };
    },
    cropBoxStyle() {
      return {
        position: 'absolute',
        left: `${this.cropBoxLeft}px`,
        top: `${this.cropBoxTop}px`,
        width: `${this.cropBoxWidth}px`,
        height: `${this.cropBoxHeight}px`,
        border: '1px solid var(--v-primary-base)',
        cursor: 'crosshair',
        boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 0px 9999px',
        zIndex: 15
      };
    },
    savedFocalPointStyle() {
      return {
        position: 'absolute',
        width: '25px',
        height: '25px',
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'none',
        display: this.savedFocalX !== null && this.savedFocalY !== null ? 'block' : 'none',
        left: `${this.savedFocalX}px`,
        top: `${this.savedFocalY}px`,
        zIndex: 20
      };
    },
    sizeInfo() {
      return `${Math.round(this.cropBoxWidth)}x${Math.round(this.cropBoxHeight)}`;
    },
    positionInfo() {
      return `(${Math.round(this.cropBoxLeft)}, ${Math.round(this.cropBoxTop)})`;
    },
    focalInfo() {
      return this.currentFocalX !== null && this.currentFocalY !== null
        ? `(${Math.round(this.currentFocalX)}, ${Math.round(this.currentFocalY)})`
        : 'Not set';
    },
    savedFocalInfo() {
      return this.savedFocalX !== null && this.savedFocalY !== null
        ? `(${Math.round(this.savedFocalX)}, ${Math.round(this.savedFocalY)})`
        : 'Not set';
    },

      focalPointBoundaries() {
        if (!this.activeNewsStory || !this.activeNewsStory.media) {
          return [];
        }


        const boundaries = [];
        const { width: imageWidth, height: imageHeight } = this.activeNewsStory.media;
        const boxWidth = this.containerWidth
        // get image dimenesions aspect ratio then apply from box width to height this.activeNewsStory.media
        const boxHeight = imageHeight * (boxWidth / imageWidth);

        const selectedFormats = this.linkedFormats.filter(item => item.activeSave);

        selectedFormats.forEach(format => {
          const lbcBoundary = this.lbcBoundaries.find(lbc => lbc.width === format.campaignBurstScreenContent.campaignBurstScreen.screen.width && lbc.height === format.campaignBurstScreenContent.campaignBurstScreen.screen.height);
          if (!lbcBoundary) return;

          const { width: containerWidth, height: containerHeight } = format.campaignBurstScreenContent.campaignBurstScreen.screen;

          let resizedWidth, resizedHeight;
          if (containerHeight >= containerWidth) {
            resizedWidth = containerWidth;
            resizedHeight = imageHeight * (containerWidth / imageWidth);
          } else {
            resizedWidth = imageWidth * (containerHeight / imageHeight);
            resizedHeight = containerHeight;
          }

          const viewport = this.calculateSpecificBoxDimensions(lbcBoundary.styleImageAreaCoordinates, containerWidth, containerHeight, format.campaignBurstScreenContent.triggers.globalLBCnews.storyState);


          var triggerX = format.campaignBurstScreenContent.triggers.globalLBCnews.moveX + -(lbcBoundary.initialX);
          var triggerY = format.campaignBurstScreenContent.triggers.globalLBCnews.moveY + -(lbcBoundary.initialY);
          var zoom = format.campaignBurstScreenContent.triggers.globalLBCnews.zoom;
          var zoomMultiplier = (zoom + 100) / 100;


          const scale = boxWidth / ((resizedWidth * lbcBoundary.zoomModifier) * zoomMultiplier);
          const width = viewport.width * scale;
          const height = viewport.height * scale;

          const centreX = boxWidth / 2
          const centreY = boxHeight / 2


          //console.log("triggerX", triggerX, "triggerY", triggerY, "zoom", zoom, "zoomMultiplier", zoomMultiplier);
          const triggerMovement = this.convertCoordinates(triggerX, triggerY, containerWidth, containerHeight);

          boundaries.push({
            aspectRatio: lbcBoundary.aspectRatio,
            containerWidth,
            containerHeight,
            width,
            height,
            top: centreY + (triggerMovement.top * scale),
            left: centreX + (triggerMovement.left * scale),
            scale,
          });
        });
        return boundaries;
      },

      linkedFormatsLoaderProgress() {
        return {
          isloaded: true,
          percentage: 100,
          current: 100,
          max: 100
          }; 
        if (this.totalLinkedFormats === 0) {
          return {
          isloaded: false,
          percentage: 0,
          current: 0,
          max: 100
          }; 
        }
        const isloaded = this.iframeLoadCount >= this.totalLinkedFormats;
        if (isloaded) {
          // Run Iframe update when all iframes are loaded
          this.debounceFlag = false
          this.updateIframes = true
          this.updateTrigger('globalLBCnews', 'updateIframes', true);
          this.updateIframes = false
          this.debounceFlag = true
        }
        return {
          isloaded,
          percentage: Math.round((this.iframeLoadCount / this.totalIframes) * 100),
          current: this.totalLinkedFormatsProgress,
          max: this.totalLinkedFormats
        };
      },
      returnNextGoLiveState() {
        return function (state) {
          if (state === 'Published') {
            return 'Draft'
          } else if (state === 'Draft') {
            return 'Published'
          }
        }
      },

      showUploadSocial() {
        return (this.$store.state.Permissions.uploadSocial) ? true : false
      },
      
      sortedCampaignBurstScreens() {
        let arr = this.campaignBurstScreens
        let clone =  (arr && arr.length > 0) ? [...arr] : []
       
        clone.sort((a, b) => 
          a.screen.mediaOwner.name.localeCompare(b.screen.mediaOwner.friendlyName) ||
          a.screen.name.localeCompare(b.screen.name)
        )
        return clone
      },

      allowTriggersEdit() {
        return this.$store.state.Permissions.triggersEdit
      },

      allowScreenMediaRemove() {
        return this.$store.state.Permissions.screenMediaRemove
      },

      showMediaUpload() {
        return (this.$store.state.Permissions.uploadMedia && this.$store.state.Permissions.uploadSocial) ? true : false
      },
      // Check if trigger is present in screen level triggers
      isGoLive() {
        return this.screenLevelTriggers.find(function(trigger) { return trigger === 'golive' })
      },
      isDayOfWeek() {
        return this.screenLevelTriggers.find(function(trigger) { return trigger === 'dayOfWeek' })
      },
      isRegion() {
        return this.screenLevelTriggers.find(function(trigger) { return trigger === 'region' })
      },
      isGroupSequence() {
        return this.screenLevelTriggers.find(function(trigger) { return trigger === 'sequence' })
      },
      isCalendar() {
        return this.screenLevelTriggers.find(function(trigger) { return trigger === 'calendar' })
      },
      isTime() {
        return this.screenLevelTriggers.find(function(trigger) { return trigger === 'time' })
      },

      // make filteredScreenContents function better and check for correctness
      filteredScreenContents() {

        // Check if originalScreenContents is not empty
        if (!this.screenContents || this.screenContents.length === 0) {
          return [];
        }

        // list all liveStatus in the screenContents
        let filteredScreenContents = this.screenContents;

        // Filter originalScreenContents based on selectedGoLiveValue
        if (this.selectedGoLiveValue && this.selectedGoLiveValue.length > 0 && this.isGoLive !== undefined) {
          filteredScreenContents = filteredScreenContents.filter(function(content) {
            let goLive = this.getGoLivePublishState(content.triggers.golive.islive);
            return this.selectedGoLiveValue.some(function(filter) { return goLive === filter });
          }, this);
        }
        
        // Filter originalScreenContents based on mutiple selectedDayOfWeekValue
        if (this.selectedDayOfWeekValue && this.selectedDayOfWeekValue.length > 0 && this.isDayOfWeek !== undefined) {
          // Bind the isTrue function to the correct this context
          const isTrue = this.isTrue.bind(this);
          filteredScreenContents = filteredScreenContents.filter(function(content) {
            let dayOfWeek = content.triggers.dayOfWeek;
            return this.selectedDayOfWeekValue.some(function(filter) { return isTrue(dayOfWeek[filter.toLowerCase()]) });
          }, this);
        }
        // Sort filteredScreenContents based on selectedSortValue
        if (this.selectedSortValue.value === 'asc') {
          filteredScreenContents = filteredScreenContents.sort(function(a, b) {
            return new Date(a.history.log[a.history.log.length-1].dateTime) - new Date(b.history.log[b.history.log.length-1].dateTime);
          });
        } else if (this.selectedSortValue.value === 'desc') {
          filteredScreenContents = filteredScreenContents.sort(function(a, b) {
            return new Date(b.history.log[b.history.log.length-1].dateTime) - new Date(a.history.log[a.history.log.length-1].dateTime);
          });
        } else if (this.selectedSortValue.value === 'group' && this.isGroupSequence) {
          filteredScreenContents = filteredScreenContents.sort(function(a, b) {
            if(a.triggers.sequence == null || b.triggers.sequence) return 0;
            let aGroup = a.triggers.sequence.group.match(/\[(\d+)\]/)[1]
            let bGroup = b.triggers.sequence.group.match(/\[(\d+)\]/)[1]
            let aSequence = a.triggers.sequence.sequence
            let bSequence = b.triggers.sequence.sequence
            if (aGroup === bGroup) {
              return aSequence - bSequence
            } else {
              return aGroup - bGroup
            }
          })
        }

        // Filter filteredScreenContents based on selectedRegionFilters
        if (this.selectedRegionFilters && this.selectedRegionFilters.length > 0) {
          filteredScreenContents = filteredScreenContents.filter(function(content) {
            let regions = content.triggers.region.regions || [];
            return this.selectedRegionFilters.some(function(filter) { return regions.includes(filter) });
          }.bind(this));
        }
        // Filter filteredScreenContents based on selectedArtWorkFilters
        if (this.selectedArtWorkFilters && this.selectedArtWorkFilters.length > 0) {
          filteredScreenContents = filteredScreenContents.filter(function(content) {
            let styles = content.triggers.style.xml_styles || [];
            return this.selectedArtWorkFilters.some(function(filter) { return styles.includes(filter) });
          }.bind(this));
        }
        // Filter filteredScreenContents based on calendarValue[0] as start date and calendarValue[1] as end date
        if (this.calendarValue && this.calendarValue.length > 0 && this.isCalendar !== undefined) {
          filteredScreenContents = filteredScreenContents.filter(function(content) {
            let calendar = content.triggers.calendar
            let startDate = new Date(calendar.startDate)
            let endDate = new Date(calendar.endDate)
            let calendarValueStart = new Date(this.calendarValue[0])
            let calendarValueEnd = new Date(this.calendarValue[1])
            return (startDate >= calendarValueStart && startDate <= calendarValueEnd) || (endDate >= calendarValueStart && endDate <= calendarValueEnd)
          }.bind(this));
        }
        // Filter filteredScreenContents based on startTimeValue and endTimeValue 
        if (this.startTimeValue && this.endTimeValue && this.isTime !== undefined) {
          filteredScreenContents = filteredScreenContents.filter(function(content) {
            let startTime = content.triggers.time.startTime
            let endTime = content.triggers.time.endTime
            let startTimeValue = this.startTimeValue.split(':')
            let endTimeValue = this.endTimeValue.split(':')
            let startTimeHours = parseInt(startTimeValue[0])
            let startTimeMinutes = parseInt(startTimeValue[1])
            let endTimeHours = parseInt(endTimeValue[0])
            let endTimeMinutes = parseInt(endTimeValue[1])
            let startTimeContentHours = parseInt(startTime.split(':')[0])
            let startTimeContentMinutes = parseInt(startTime.split(':')[1])
            let endTimeContentHours = parseInt(endTime.split(':')[0])
            let endTimeContentMinutes = parseInt(endTime.split(':')[1])
            return ((startTimeHours < startTimeContentHours || (startTimeHours === startTimeContentHours && startTimeMinutes <= startTimeContentMinutes)) && (endTimeHours > endTimeContentHours || (endTimeHours === endTimeContentHours && endTimeMinutes >= endTimeContentMinutes)))
          }.bind(this));
        }
        return filteredScreenContents;
      },
      artworkFilters() {
        if(this.screenContents && this.screenContents.length > 0) {
          let filters = []

          this.screenContents.forEach(screenContent => {
            if(screenContent.triggers.style && screenContent.triggers.style.xml_styles && screenContent.triggers.style.xml_styles.length > 0) {
              filters.push(screenContent.triggers.style.xml_styles)
            }
          })

          return filters
        }
        
        return false
      },
      regionFiltersList(){
        if(this.screenContents && this.screenContents.length > 0) {
          let filters = []

          this.screenContents.forEach(screenContent => {
            if(screenContent.triggers.region && screenContent.triggers.region.regions && screenContent.triggers.region.regions.length > 0) {
              filters.push(screenContent.triggers.region.regions)
            }
          })

          return filters
        }
        
        return false
      },

      // Calendar start date range text raedable
      calendarDateRange() {
        return (date) => {
          return moment(date).format('DD MMM YYYY HH:mm a')
        }
      },
      isIndefinite() {
        return (startEndDateRange) => {
          return startEndDateRange === '|'
        }
      },
  },

  beforeDestroy() {
  },
  watch: {
    // Watch for changes in multipleFormatsDialog
    multipleFormatsDialog: function (newVal, oldVal) {
      if (newVal === false) {
        this.toggleFullScreen(true)
      }
      else {
        this.toggleFullScreen(false)
      }
    },

    // Sport - Rugby Trigger Watches
    'selectedContentForTriggerEdit.triggers.sportRugby.competitionId': function (newVal, oldVal) { if (newVal !== oldVal) { this.getRugbyTeams(); } },
    'selectedContentForTriggerEdit.triggers.sportRugby.seasonId': function (newVal, oldVal) { if (newVal !== oldVal) { this.getRugbyTeams(); } },
    triggerBuilderDialog: function () {

      if (this.triggerBuilderDialog) {
        if (this.screenLevelTriggers.includes('ladbrokes')) {
          this.debounceFlag = false
          this.customTriggerWatchers('ladbrokes', "mode", "", true)
          this.customTriggerWatchers('ladbrokes', "events", "", true)
          this.customTriggerWatchers('ladbrokes', "market", "", true)
          this.customTriggerWatchers('ladbrokes', "selection", "", true)
          this.debounceFlag = true
        }
      }
    },
    // Watch for changes in showAdvancedFilters, if its false, then reset all filters and fix this error Vue warn]: Error in callback for watcher "showAdvancedFilters": "TypeError: Cannot read properties of undefined (reading '0')
    showAdvancedFilters: function (newVal, oldVal) {
      if (newVal === false) {
        this.selectedGoLiveValue = []
        this.selectedDayOfWeekValue = []
        this.selectedSortValue = this.isGroupSequence ? { text: 'Group', value: 'group' } : { text: 'Asc', value: 'asc' }
        this.selectedRegionFilters = []
        this.selectedArtWorkFilters = null
        this.limit = 10
        this.pageNum = 1
        this.$nextTick()
      }
    },
    selectedCampaignBurstScreen: function (newVal) {
      this.getAllLocations()
    },
    selectedContentForTriggerEdit: function (newVal, oldVal) {
      if (this.selectedContentForTriggerEdit !== null) {
        let parsedTriggers
        try {
          parsedTriggers = newVal.triggers
          this.selectedContentForTriggerEdit.triggers = parsedTriggers
        } catch (err) {  // Send error on catch with relevant pop up message
          console.log(err)
        }

        // match up with screen level triggers, and make strings = booleans to avoid bug with fields not populating properly
        for (var i = 0; i < this.screenLevelTriggers.length; i++) {
          for (
            var k = 0;
            k <
            Object.getOwnPropertyNames(
              parsedTriggers[this.screenLevelTriggers[i]],
            ).length;
            k++
          ) {
            if (
              parsedTriggers[this.screenLevelTriggers[i]][
              Object.getOwnPropertyNames(
                parsedTriggers[this.screenLevelTriggers[i]],
              )[k]
              ] === 'false'
            ) {
              parsedTriggers[this.screenLevelTriggers[i]][
                Object.getOwnPropertyNames(
                  parsedTriggers[this.screenLevelTriggers[i]],
                )[k]
              ] = false
            }
            if (
              parsedTriggers[this.screenLevelTriggers[i]][
              Object.getOwnPropertyNames(
                parsedTriggers[this.screenLevelTriggers[i]],
              )[k]
              ] === 'true'
            ) {
              parsedTriggers[this.screenLevelTriggers[i]][
                Object.getOwnPropertyNames(
                  parsedTriggers[this.screenLevelTriggers[i]],
                )[k]
              ] = true
            }
          }
        }
      }
    },

    filteredScreenContents: async function () {
      
      // update the live status of the screen content
      if (this.preventReload) return
      if (this.activeNewsStory.id > 0) {
        var findIndex = this.filteredScreenContents.findIndex(x => x.id === this.activeNewsStory.id)
        if (findIndex !== -1) {
          await this.refreshIFrame(this.activeNewsStory, true)
          this.activeNewsStory = {}
          this.activeNewsStory = this.filteredScreenContents[findIndex]
          await this.$nextTick()
        }
      }
      else {
        if(this.initialLoad) return
        if (this.filteredScreenContents.length > 0) {
          this.previewLoading = true
          await this.reloadAllIFrames()
          this.previewLoading = false
        }
      }
      for (let i = 0; i < this.filteredScreenContents.length; i++) {
          this.filteredScreenContents[i] = await this.applyLiveStatus(this.filteredScreenContents[i])
          await this.$nextTick()
        }
    }
  },
  
  mounted() {
    UserController.getUserSetting('FullScreenCreator').then(res => {
      this.isFullScreen = this.isTrue(res.data.value);
    });

    document.addEventListener('fullscreenchange', this.handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', this.handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', this.handleFullscreenChange);
  },

  methods: {
  handleFullscreenChange(event) {
      const fullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
      if(fullScreen != '{}')
      {
        this.changeUserSetting('FullScreenCreator', this.isFullScreen)
        console.log("Fullscreen setting saved: ", this.isFullScreen)
      }
    },
	changeUserSetting(setting,value) {
      UserController.setUserSetting({
        value: value,
        setting: setting
      })
        .then((res) => {
          this.$root.$emit("snackbarSuccess", setting + " preferences updated.");
        })
        .catch((err) => {
          this.$root.$emit("snackbarError", ''+err.response.data.message);
        });
    },
    
    stopKey(event) {
          if (event && (event.key === 'Enter' || event.key === 'Tab')) {
            event.preventDefault()
            event.stopPropagation()
            event.stopImmediatePropagation()
          }
        },
        
    submitSearch(triggerParam, itemParam , index,event) {
          if (event.key === 'Enter') {
            this.stopKey(event)
            
            // make a copy of regionsSearchText array
            let regionsSearchTextCopy = this.regionsSearchText[itemParam].slice()

            // prevent enter from selecting an item in the dropdown list
            // search through the regions array and if the search term matches case insensitive and trimmed to that search term, use that one
            // if not, push the search term to the regions array
            if(this.searchInputs[index] === null || this.searchInputs[index] === undefined || this.searchInputs[index] === "")
              return;
            // is there an exact match?
            let ind = this.regions.findIndex((item) => item.toLowerCase().trim() === this.searchInputs[index].toLowerCase().trim())
            if(ind !== -1)
            {
              let val = this.regions[ind];
              if(!regionsSearchTextCopy.includes(val))
              {
                // get the value from the regions array and push it to the regionsSearchText array  
                regionsSearchTextCopy.push(val)
              }
            }
            else 
            {
              // does the value contains the search term, filter the original list
              let filtered = this.regions.filter((item) => item.toLowerCase().includes(this.searchInputs[index].toLowerCase()))
              // if there's only 1 item (partial match)
              if(filtered.length === 1)
              {
                // add it if filtered[0] does not exist in the regionsSearchText array
                if(!regionsSearchTextCopy.includes(filtered[0]))
                regionsSearchTextCopy.push(filtered[0])
              }
              else if(filtered.length == 0)
              {
                // add the search term
                regionsSearchTextCopy.push(this.searchInputs[index])
              }
            }
            this.searchInputs[index] = ''
            this.updateTrigger(triggerParam, itemParam, regionsSearchTextCopy.join(','))
            this.$nextTick()
            // finally set the regionsSearchText array to the copy
            this.$set(this.regionsSearchText, itemParam, regionsSearchTextCopy)
          }
        },
    handleImageInput(paramName, itemName, event) {
    if (this.imageManualMode) {
      this.updateTrigger(paramName, itemName, event);
    }
  },
    toggleAutoZoom() {
      this.autoZoom = !this.autoZoom
      this.updateAspectRatioBoxes()
    },
    reverseFocalPointBoundaries(boundaries, boxWidth, boxHeight) {
      const reverseBoundaries = [];
      const { width: imageWidth, height: imageHeight } = this.activeNewsStory.media;
      // Validate that boundaries is an array
      if (!Array.isArray(boundaries)) {
        console.warn("boundaries is not an array:", boundaries);
        return reverseBoundaries; // return an empty array if boundaries is not valid
      }
      boundaries.forEach((boundary) => {
        const { aspectRatio, containerWidth, containerHeight, width, height, top, left } = boundary;

        // Find the corresponding lbcBoundary
        const lbcBoundary = this.lbcBoundaries.find((lbc) => lbc.width === containerWidth && lbc.height === containerHeight);
        if (!lbcBoundary) {
          console.warn(`No matching lbcBoundary found for aspectRatio: ${aspectRatio}`);
          return;
        }

        // Get format which has the same width and height
        const format = this.linkedFormats.find(
          (format) =>
            format.campaignBurstScreenContent.campaignBurstScreen.screen.width === containerWidth && format.campaignBurstScreenContent.campaignBurstScreen.screen.height === containerHeight);
        if (!format) {
          console.warn(`No matching format found for aspectRatio: ${aspectRatio}`);
          return;
        }

        const viewport = this.calculateSpecificBoxDimensions(
          lbcBoundary.styleImageAreaCoordinates,
          containerWidth,
          containerHeight,
          format.campaignBurstScreenContent.triggers.globalLBCnews.storyState
        );
        const scale = width / viewport.width;

        // Calculate the center points with offsets
        const centreX = boxWidth / 2
        const centreY = boxHeight / 2;

        // Calculate the movement
        const moveLeft = (centreX - left) / scale;
        const moveTop = (centreY - top) / scale;

        // Convert back to percentages
        const triggerX = ((moveLeft / containerWidth) * 100) + lbcBoundary.initialX;
        const triggerY = ((moveTop / containerHeight) * 100) + lbcBoundary.initialY;

        let resizedWidth;

        if (containerHeight >= containerWidth) {
          resizedWidth = containerWidth;
        } else {
          resizedWidth = imageWidth * (containerHeight / imageHeight);
        }

        const zoom =  (100 * boxWidth) / ((resizedWidth * lbcBoundary.zoomModifier) * scale) - 100;

        reverseBoundaries.push({
          aspectRatio,
          containerWidth,
          containerHeight,
          moveY: parseFloat(triggerY.toFixed(2)),
          moveX: parseFloat(triggerX.toFixed(2)),
          zoom: parseFloat(zoom.toFixed(2)),
        });
      });
      //console.log("reverseBoundaries", reverseBoundaries);
      return reverseBoundaries;
    },

    calculateSpecificBoxDimensions(styleImageAreaCoordinates, containerWidth, containerHeight, specificKey) {
    // Convert specificKey to lowercase for case-insensitive comparison and remove spaces
      const keyLowerCase = specificKey.toLowerCase().replace(/\s/g, '');
      
      // Check if the key exists in the coordinates object
      if (styleImageAreaCoordinates.hasOwnProperty(keyLowerCase)) {
          const [leftPercent, topPercent, rightPercent, bottomPercent] = styleImageAreaCoordinates[keyLowerCase];

          // Calculate the pixel values
          const left = leftPercent * containerWidth;
          const top = topPercent * containerHeight;
          const right = rightPercent * containerWidth;
          const bottom = bottomPercent * containerHeight;

          // Compute width and height of the box
          const width = right - left;
          const height = bottom - top;

          // Return the dimensions for the specific key
          return {
              width: width,
              height: height
          };
      } else {
          // Key not found, return null
          console.warn(`Key "${specificKey}" not found.`);
          return {
              width: 0,
              height: 0
          };
      }
    },

    resetCropBox() {
    this.cropBoxLeft = this.cropBoxMargin;
    this.cropBoxTop = this.cropBoxMargin;
    this.cropBoxWidth = this.containerWidth - 2 * this.cropBoxMargin;
    this.cropBoxHeight = this.containerHeight - 2 * this.cropBoxMargin;
    this.savedFocalX = this.containerWidth / 2;
    this.savedFocalY = this.containerHeight / 2;
    this.updateAspectRatioBoxes();
  },

  startDrag(e) {
    if (e.target.classList.contains('crop-box') || e.target.classList.contains('resize-handle')) {
      this.isDragging = true;
      this.dragStartX = e.clientX - this.cropBoxLeft;
      this.dragStartY = e.clientY - this.cropBoxTop;
      this.startLeft = this.cropBoxLeft;
      this.startTop = this.cropBoxTop;
      this.startWidth = this.cropBoxWidth;
      this.startHeight = this.cropBoxHeight;
    }
  },

  onMouseMove(e) {
    if (!this.$refs.focalContainer.contains(e.target)) return;

    if (this.isDragging && !this.isResizing) {
      const newLeft = e.clientX - this.dragStartX;
      const newTop = e.clientY - this.dragStartY;
      this.cropBoxLeft = Math.max(this.cropBoxMargin, Math.min(newLeft, this.containerWidth - this.cropBoxWidth - this.cropBoxMargin));
      this.cropBoxTop = Math.max(this.cropBoxMargin, Math.min(newTop, this.containerHeight - this.cropBoxHeight - this.cropBoxMargin));
      this.updateFocalPoint(e);
    } else if (this.isResizing) {
      this.handleResize(e);
      this.updateFocalPoint(e);
    } else {
      this.updateFocalPoint(e);
    }
  },

  stopDrag() {
    if (this.isDragging || this.isResizing) {
      this.isDragging = false;
      this.isResizing = false;
      this.updateAspectRatioBoxes();
    }
  },

  startResize(handle, e) {
    e.preventDefault();
    this.isResizing = true;
    this.resizeHandle = handle;
    this.dragStartX = e.clientX;
    this.dragStartY = e.clientY;
    this.startWidth = this.cropBoxWidth;
    this.startHeight = this.cropBoxHeight;
    this.startLeft = this.cropBoxLeft;
    this.startTop = this.cropBoxTop;
  },

  handleResize(e) {
    if (!this.isResizing) return;

    const dx = e.clientX - this.dragStartX;
    const dy = e.clientY - this.dragStartY;
    
    let newWidth, newHeight, newLeft, newTop;

    const maxWidth = this.containerWidth - 2 * this.cropBoxMargin;
    const maxHeight = this.containerHeight - 2 * this.cropBoxMargin;

    switch (this.resizeHandle) {
      case 'e':
        newWidth = Math.min(maxWidth - this.cropBoxLeft + this.cropBoxMargin, Math.max(this.minWidth, this.startWidth + dx));
        this.cropBoxWidth = newWidth;
        break;
      case 'w':
        newWidth = Math.min(this.startLeft + this.startWidth - this.cropBoxMargin, Math.max(this.minWidth, this.startWidth - dx));
        newLeft = Math.max(this.cropBoxMargin, this.startLeft + this.startWidth - newWidth);
        this.cropBoxLeft = newLeft;
        this.cropBoxWidth = newWidth;
        break;
      case 's':
        newHeight = Math.min(maxHeight - this.cropBoxTop + this.cropBoxMargin, Math.max(this.minHeight, this.startHeight + dy));
        this.cropBoxHeight = newHeight;
        break;
      case 'n':
        newHeight = Math.min(this.startTop + this.startHeight - this.cropBoxMargin, Math.max(this.minHeight, this.startHeight - dy));
        newTop = Math.max(this.cropBoxMargin, this.startTop + this.startHeight - newHeight);
        this.cropBoxTop = newTop;
        this.cropBoxHeight = newHeight;
        break;
      case 'ne':
        newWidth = Math.min(maxWidth - this.cropBoxLeft + this.cropBoxMargin, Math.max(this.minWidth, this.startWidth + dx));
        newHeight = Math.min(this.startTop + this.startHeight - this.cropBoxMargin, Math.max(this.minHeight, this.startHeight - dy));
        newTop = Math.max(this.cropBoxMargin, this.startTop + this.startHeight - newHeight);
        this.cropBoxWidth = newWidth;
        this.cropBoxHeight = newHeight;
        this.cropBoxTop = newTop;
        break;
      case 'nw':
        newWidth = Math.min(this.startLeft + this.startWidth - this.cropBoxMargin, Math.max(this.minWidth, this.startWidth - dx));
        newHeight = Math.min(this.startTop + this.startHeight - this.cropBoxMargin, Math.max(this.minHeight, this.startHeight - dy));
        newLeft = Math.max(this.cropBoxMargin, this.startLeft + this.startWidth - newWidth);
        newTop = Math.max(this.cropBoxMargin, this.startTop + this.startHeight - newHeight);
        this.cropBoxLeft = newLeft;
        this.cropBoxTop = newTop;
        this.cropBoxWidth = newWidth;
        this.cropBoxHeight = newHeight;
        break;
      case 'se':
        newWidth = Math.min(maxWidth - this.cropBoxLeft + this.cropBoxMargin, Math.max(this.minWidth, this.startWidth + dx));
        newHeight = Math.min(maxHeight - this.cropBoxTop + this.cropBoxMargin, Math.max(this.minHeight, this.startHeight + dy));
        this.cropBoxWidth = newWidth;
        this.cropBoxHeight = newHeight;
        break;
      case 'sw':
        newWidth = Math.min(this.startLeft + this.startWidth - this.cropBoxMargin, Math.max(this.minWidth, this.startWidth - dx));
        newHeight = Math.min(maxHeight - this.cropBoxTop + this.cropBoxMargin, Math.max(this.minHeight, this.startHeight + dy));
        newLeft = Math.max(this.cropBoxMargin, this.startLeft + this.startWidth - newWidth);
        this.cropBoxLeft = newLeft;
        this.cropBoxWidth = newWidth;
        this.cropBoxHeight = newHeight;
        break;
    }

    // Ensure crop box stays within container boundaries, respecting the margin
    this.cropBoxLeft = Math.max(this.cropBoxMargin, Math.min(this.cropBoxLeft, this.containerWidth - this.cropBoxWidth - this.cropBoxMargin));
    this.cropBoxTop = Math.max(this.cropBoxMargin, Math.min(this.cropBoxTop, this.containerHeight - this.cropBoxHeight - this.cropBoxMargin));
  },
    updateFocalPoint(e) {
      const focalContainer = this.$refs.focalContainer;
      if (focalContainer) {
        const rect = focalContainer.getBoundingClientRect();
        this.currentFocalX = e.clientX - rect.left;
        this.currentFocalY = e.clientY - rect.top;
      }
    },
    saveFocalPoint(e) {
      const focalContainer = this.$refs.focalContainer;
      if(focalContainer && this.startWidth === this.cropBoxWidth && this.startHeight === this.cropBoxHeight && this.startLeft === this.cropBoxLeft && this.startTop === this.cropBoxTop) {
        const rect = focalContainer.getBoundingClientRect();
        this.savedFocalX = e.clientX - rect.left;
        this.savedFocalY = e.clientY - rect.top;
        this.updateAspectRatioBoxes();
        
      }
    },
  
    aspectRatioBoxesData() {
    const aspectRatioBoxes = [];

    const selectedFormats = this.linkedFormats.filter(item => item.activeSave);

    selectedFormats.forEach(format => {
      const lbcBoundary = this.lbcBoundaries.find(lbc => lbc.width === format.campaignBurstScreenContent.campaignBurstScreen.screen.width && lbc.height === format.campaignBurstScreenContent.campaignBurstScreen.screen.height);
      if (!lbcBoundary) return;

      const { width: containerWidth, height: containerHeight } = format.campaignBurstScreenContent.campaignBurstScreen.screen;
      const viewport = this.calculateSpecificBoxDimensions(lbcBoundary.styleImageAreaCoordinates, containerWidth, containerHeight, format.campaignBurstScreenContent.triggers.globalLBCnews.storyState); 

      aspectRatioBoxes.push({
        aspectRatio: lbcBoundary.aspectRatio,
        containerWidth,
        containerHeight,
        width: viewport.width,
        height: viewport.height,
      });
    });

    return aspectRatioBoxes;
},

updateAspectRatioBoxes() {
    if (this.savedFocalX === null || this.savedFocalY === null) return;

    const aspectRatioBoxes = this.aspectRatioBoxesData();

    this.aspectRatioBoxes = aspectRatioBoxes.map(box => {
        const { width: resizedContainerWidth, height: resizedContainerHeight, containerWidth, containerHeight, aspectRatio } = box;
        let width, height;

        const ratio = resizedContainerWidth / resizedContainerHeight;

        if (this.autoZoom) {
            // Calculate the maximum possible size centered around the focal point
            const maxWidthFromFocal = Math.min(this.savedFocalX - this.cropBoxLeft, this.cropBoxLeft + this.cropBoxWidth - this.savedFocalX) * 2;
            const maxHeightFromFocal = Math.min(this.savedFocalY - this.cropBoxTop, this.cropBoxTop + this.cropBoxHeight - this.savedFocalY) * 2;
            if (ratio > 1) {
                width = maxWidthFromFocal;
                height = width / ratio;
                if (height > maxHeightFromFocal) {
                    height = maxHeightFromFocal;
                    width = height * ratio;
                }
            } else {
                height = maxHeightFromFocal;
                width = height * ratio;
                if (width > maxWidthFromFocal) {
                    width = maxWidthFromFocal;
                    height = width / ratio;
                }
            }

            //Manual zoom
            if (this.autoZoomSlider > 0) {
              var maxWidth, maxHeight;
              if (ratio > 1) {
                maxWidth = this.cropBoxWidth;
                maxHeight = maxWidth / ratio;
                if (maxHeight > this.cropBoxHeight) {
                    maxHeight = this.cropBoxHeight;
                    maxWidth = maxHeight * ratio;
                }
              } 
              else {
                    maxHeight = this.cropBoxHeight;
                    maxWidth = maxHeight * ratio;
                    if (maxWidth > this.cropBoxWidth) {
                        maxWidth = this.cropBoxWidth;
                        maxHeight = maxWidth / ratio;
                    }
                }
                var widthDiff = maxWidth - width;
                    var heightDiff = maxHeight - height;
                    width = width + (widthDiff * (this.autoZoomSlider / 100));
                    height = height + (heightDiff * (this.autoZoomSlider / 100));

            }

            // Ensure minimum size of 50px for the smallest dimension
            if (width < 50 || height < 50) {
                if (width < height) {
                    width = 50;
                    height = width / ratio;
                } else {
                    height = 50;
                    width = height * ratio;
                }
            }
        } else {
            // Original logic for calculating width and height based on crop box constraints
            if (ratio > 1) {
                width = this.cropBoxWidth;
                height = width / ratio;
                if (height > this.cropBoxHeight) {
                    height = this.cropBoxHeight;
                    width = height * ratio;
                }
            } else {
                height = this.cropBoxHeight;
                width = height * ratio;
                if (width > this.cropBoxWidth) {
                    width = this.cropBoxWidth;
                    height = width / ratio;
                }
            }
        }

        // Adjust position to keep as close to focal point as possible while staying within crop box
        // Note: left and top now represent the center of the box
        let left = Math.max(this.cropBoxLeft + width / 2, Math.min(this.savedFocalX, this.cropBoxLeft + this.cropBoxWidth - width / 2));
        let top = Math.max(this.cropBoxTop + height / 2, Math.min(this.savedFocalY, this.cropBoxTop + this.cropBoxHeight - height / 2));

        // If the box is pushed to the edge, try to center it on the focal point in the other dimension
        if (left === this.cropBoxLeft + width / 2 || left === this.cropBoxLeft + this.cropBoxWidth - width / 2) {
            top = Math.max(this.cropBoxTop + height / 2, Math.min(this.savedFocalY, this.cropBoxTop + this.cropBoxHeight - height / 2));
        } else if (top === this.cropBoxTop + height / 2 || top === this.cropBoxTop + this.cropBoxHeight - height / 2) {
            left = Math.max(this.cropBoxLeft + width / 2, Math.min(this.savedFocalX, this.cropBoxLeft + this.cropBoxWidth - width / 2));
        }

        return { width, height, left, top, aspectRatio, containerWidth, containerHeight };
    });

    this.runFocalPointTrigger();
    // this.debounceFlag = false
    // this.updateIframes = true
    // this.updateTrigger('globalLBCnews', 'updateIframes', true);
    // this.updateIframes = false
    // this.debounceFlag = true

},

getAspectRatioBoxStyle(box) {
  const numberOfBoxes = this.focalPointBoundaries.length;
  const targetOpacity = 0.6; // Desired opacity for the combined box shadows
  const singleLayerOpacity = this.calculateSingleLayerOpacity(numberOfBoxes, targetOpacity);
  
  return {
    width: `${box.width}px`,
    height: `${box.height}px`,
    left: `${box.left}px`,
    top: `${box.top}px`,
    boxShadow: `rgba(0, 0, 0, ${singleLayerOpacity}) 0px 0px 0px 9999px`,
  };
},

    calculateSingleLayerOpacity(layers, targetOpacity) {
  let low = 0;
  let high = 1;
  const epsilon = 0.0001;

  while (high - low > epsilon) {
    const mid = (low + high) / 2;
    const stackedOpacity = 1 - Math.pow(1 - mid, layers);

    if (stackedOpacity > targetOpacity) {
      high = mid;
    } else {
      low = mid;
    }
  }

  return (low + high) / 2;
},


    runFocalPointTrigger() {
      //console.log("runFocalPointTrigger", this.aspectRatioBoxes);
      const triggerStore = this.reverseFocalPointBoundaries(this.aspectRatioBoxes, this.containerWidth, this.containerHeight);

      // Add a check to ensure triggerStore is defined and is an array
      if (!Array.isArray(triggerStore)) {
        console.error("triggerStore is not an array:", triggerStore);
        return;
      }

      this.triggerStore = triggerStore; // Assign to instance variable if needed

      this.debounceFlag = false
      this.triggerStore.forEach((store) => {
        // find linkedformat which equals width and height
        const format = this.linkedFormats.find((format) =>format.campaignBurstScreenContent.campaignBurstScreen.screen.width === store.containerWidth && format.campaignBurstScreenContent.campaignBurstScreen.screen.height === store.containerHeight);

        if (format) {
          this.currentFocalPointAspectRatio = store.containerWidth + "x" + store.containerHeight;
          this.updateTrigger('globalLBCnews', 'moveX', store.moveX);
          this.updateTrigger('globalLBCnews', 'moveY', store.moveY);
          this.updateTrigger('globalLBCnews', 'zoom', store.zoom);
        }
      });

      this.currentFocalPointAspectRatio = null;
      this.debounceFlag = true
    },
    toggleAspectRatios() {
      this.aspectRatiosVisible = !this.aspectRatiosVisible;
    },
    updateContainerSize() {
      this.containerWidth = parseInt(this.containerWidth) || 300;
      this.containerHeight = parseInt(this.containerHeight) || 400;
      this.resetCropBox();
    },

    convertCoordinates(leftVal, topVal, containerWidth, containerHeight, toPixels = true) {
    if (toPixels) {
        // Convert percentages to pixels and invert left value
        return {
            left: -((leftVal / 100) * containerWidth),
            top: -((topVal / 100) * containerHeight)
        };
    } 
    else {
        // Convert pixels to percentages and invert left value
        return {
            left: -((leftVal / containerWidth) * 100),
            top: -((topVal / containerHeight) * 100)
        };
    }
},

    addQuotations(triggerName, paramName) {
  const refElement = this.$refs[paramName];
  if (!refElement) {
    console.warn(`Ref for ${paramName} not found`);
    return;
  }

  let inputElement;
  if (refElement.$el) {
    // For Vuetify components
    inputElement = refElement.$el.querySelector('textarea') || refElement.$el.querySelector('input');
  } else if (Array.isArray(refElement)) {
    // For cases where ref might return an array
    const firstElement = refElement[0];
    inputElement = firstElement.$el 
      ? (firstElement.$el.querySelector('textarea') || firstElement.$el.querySelector('input'))
      : firstElement;
  } else {
    // For native elements
    inputElement = refElement;
  }

  if (!inputElement || !(inputElement instanceof HTMLTextAreaElement || inputElement instanceof HTMLInputElement)) {
    console.warn(`Textarea or Input element not found for ${paramName}`);
    return;
  }

      const start = inputElement.selectionStart;
      const end = inputElement.selectionEnd;
      const text = inputElement.value;
      const selection = text.substring(start, end);

      if (start === end || selection.trim() === '') {
        var startQuotation = true;
        let content = this.selectedContentForTriggerEdit.triggers[triggerName][paramName];
        let contentArray = content.split('');

        for (let i = 0; i < contentArray.length; i++) {
          let currentChar = contentArray[i];
          // handle double quotes
          if (currentChar === '"' || currentChar === '“' || currentChar === '”') {
            contentArray[i] = startQuotation ? '“' : '”';
            startQuotation = !startQuotation;
          }
          // handle single quotes
          else if (currentChar === "'" || currentChar === '‘' || currentChar === '’') {
            contentArray[i] = startQuotation ? '‘' : '’';
            startQuotation = !startQuotation;
          }
        }

  this.selectedContentForTriggerEdit.triggers[triggerName][paramName] = contentArray.join('');
  return;
}

      // Check if the selection is already wrapped in quotes
      // Find the starting index of the selection within the text
      const selectionStartIndex = text.indexOf(selection);

      // If selection is not found within text, return
      if (selectionStartIndex === -1) {
        console.warn('Selection not found within text');
        return;
      }

      // Find the ending index of the selection within the text
      const selectionEndIndex = selectionStartIndex + selection.length;

      // Check if the character before the startIndex is a quotation mark
      const before = text[selectionStartIndex - 1];
      const after = text[selectionEndIndex];

      // Check if both before and after characters are quotation marks
      if ((before === '"' || before === "'") && (after === '"' || after === "'") || (before === '“' || before === '‘') && (after === '”' || after === '’')) {
        // remove the quotes
        const newText = text.substring(0, selectionStartIndex - 1) + selection + text.substring(selectionEndIndex + 1);
        this.selectedContentForTriggerEdit.triggers[triggerName][paramName] = newText;
        this.updateTrigger(triggerName, paramName, newText + " ");
        this.updateTrigger(triggerName, paramName, newText);
        this.$nextTick(() => {
          inputElement.value = newText;
          inputElement.setSelectionRange(selectionStartIndex - 1, selectionEndIndex - 1);
          inputElement.focus();
        });
        return;
      }

      // If not wrapped in quotes, add them
      const newText = text.substring(0, start) + '‘' + selection + '’' + text.substring(end);

      // Update the model
      this.selectedContentForTriggerEdit.triggers[triggerName][paramName] = newText;

      // Trigger the updateTrigger method
      this.updateTrigger(triggerName, paramName, newText + " ");
      this.updateTrigger(triggerName, paramName, newText);

      // Set the updated text and restore the selection
      this.$nextTick(() => {
        inputElement.value = newText;
        inputElement.setSelectionRange(start + 1, end + 1);
        inputElement.focus();
      });
    },

    iframeListener() {
      window.addEventListener('message', async (e) => {
        if (e.data && e.data.event) {
          if (e.data.event.includes("LBC_IMAGE_NOT_FITTING") || e.data.event.includes("LBC_IMAGE_FITTING")) {
            // Run toggleFlag asynchronously without waiting for each call to finish
            this.toggleFlag(e.data.event);
          } else if (e.data.event === 'LBC_INFO') {
            this.handleLBCInfo(e.data);
          }
        }
      });
    },

    handleLBCInfo(data) {
      // Data is already a JavaScript object, no need to parse
      let jsonData = JSON.parse(data.json);
      let aspectRatio = null;
      var containerWidth = null;
      var containerHeight = null;

      this.linkedFormats.forEach(linkedFormat => {
        if (this.extractContentIdFromUrl(linkedFormat.campaignBurstScreenContent.iFrameURL) === this.extractContentIdFromUrl(jsonData.URL)) {
          containerWidth = linkedFormat.campaignBurstScreenContent.campaignBurstScreen.screen.width;
          containerHeight = linkedFormat.campaignBurstScreenContent.campaignBurstScreen.screen.height;
          aspectRatio = linkedFormat.campaignBurstScreenContent.campaignBurstScreen.screen.aspectRatio;
        }
      });
      
      if (containerWidth && containerHeight && jsonData.initialX && jsonData.initialY) {
        const format = this.linkedFormats.find(
          (format) => format.campaignBurstScreenContent.campaignBurstScreen.screen.width === containerWidth && format.campaignBurstScreenContent.campaignBurstScreen.screen.height === containerHeight
        );

        if (format) {
          //console.log("xytest for " + aspectRatio, jsonData.initialX, jsonData.initialY);
          const extractPercentage = (str, forPercentage) => {
              str = str.toString();
              
              // If the string contains "%", handle it as a percentage directly
              if (str.includes("%")) {
                  let number = parseFloat(str.replace(/[%\s]/g, ''));
                  return number;
              } else {
                  // Handle it as a fraction of forPercentage
                  let number = parseFloat(str.replace(/[%\s]/g, ''));
                  return (number / forPercentage) * 100;
              }
          };


          var zoomModifier = 1
          if (jsonData.zoomModifier) {
            zoomModifier = jsonData.zoomModifier
          }
          const initialX = extractPercentage(jsonData.initialX, containerWidth);
          const initialY = extractPercentage(jsonData.initialY, containerHeight);
          const styleImageAreaCoordinates = JSON.parse(jsonData.styleImageAreaCoordinates);        

          const iframeFormat = this.calculateIframeFormat(styleImageAreaCoordinates, containerWidth, containerHeight, initialX, initialY, "breaking");

          const existingBoundaryIndex = this.lbcBoundaries.findIndex(boundary => boundary.width === containerWidth && boundary.height === containerHeight);
          
          if (existingBoundaryIndex !== -1) {
            this.lbcBoundaries[existingBoundaryIndex] = {
              ...this.lbcBoundaries[existingBoundaryIndex],
              styleImageAreaCoordinates,
              initialX: iframeFormat.deltaXPercent,
              initialY: iframeFormat.deltaYPercent,
            };
          } else {
            this.lbcBoundaries.push({
              aspectRatio: aspectRatio,
              width: containerWidth,
              height: containerHeight,
              styleImageAreaCoordinates,
              initialX: iframeFormat.deltaXPercent,
              initialY: iframeFormat.deltaYPercent,
              zoomModifier
            });
          }
        }
      }
    },

    calculateIframeFormat(styleImageAreaCoordinates, containerWidth, containerHeight, initialX, initialY, specificKey) {
    // Convert specificKey to lowercase for case-insensitive comparison and remove spaces
    const keyLowerCase = specificKey.toLowerCase().replace(/\s/g, '');

    // Check if the key exists in the coordinates object
    if (styleImageAreaCoordinates.hasOwnProperty(keyLowerCase)) {
        const [leftPercent, topPercent, rightPercent, bottomPercent] = styleImageAreaCoordinates[keyLowerCase];

        // Calculate the pixel values
        const left = leftPercent * containerWidth;
        const top = topPercent * containerHeight;
        const right = rightPercent * containerWidth;
        const bottom = bottomPercent * containerHeight;

        // Compute width and height of the box
        const width = right - left;
        const height = bottom - top;

        // Calculate the center point of the specific box
        const boxCentreX = (left + right) / 2;
        const boxCentreY = (top + bottom) / 2;

        // Get the container centre point percentages
        const containerCentreXPercent = initialX;
        const containerCentreYPercent = initialY;

        // Calculate the pixel values of the container centre point
        const containerCentreX = containerCentreXPercent / 100 * containerWidth;
        const containerCentreY = containerCentreYPercent / 100 * containerHeight;

        // Calculate the differences in percentages relative to the container
        const deltaXPercent = ((boxCentreX - containerCentreX) / containerWidth) * 100;
        const deltaYPercent = ((boxCentreY - containerCentreY) / containerHeight) * 100;

        // Return the dimensions and centre point differences for the specific key
        return {
            width: width,
            height: height,
            deltaXPercent: deltaXPercent,
            deltaYPercent: deltaYPercent
        };
    } else {
        // Key not found, return default values
        return {
            width: 0,
            height: 0,
            deltaXPercent: 0,
            deltaYPercent: 0
        };
    }
},

    extractContentIdFromUrl(url) {
      // Regular expression to match the numeric sequence in the URL
      var regex = /content-(\d+)index\.html/;
      // Executing the regular expression on the URL
      var match = regex.exec(url);
      // If a match is found, return the captured number
      if (match && match[1]) {
          return match[1] + 'index.html';
      } else {
          // Return null if no match is found
          return null;
      }
    },
async setOrientation(orientation, orientationMode = 'free-form', singleAspectRatio = null) {
      this.orientation = orientation.toLowerCase();
      const viewport = this.$refs.lbcViewport;
      const divs = viewport.getElementsByClassName('box');

      if (orientationMode === 'single') {
        this.fit = true;
        for (let i = 0; i < divs.length; i++) {
          const div = divs[i];
          const divWidth = this.metricToNumber(div.getAttribute('w'));
          const divHeight = this.metricToNumber(div.getAttribute('h'));

          div.style.display = singleAspectRatio.width === divWidth && singleAspectRatio.height === divHeight ? 'block' : 'none';
        }

      }
      else{
        this.fit = orientationMode === 'fit' ? !this.fit : this.fit;

        for (let i = 0; i < divs.length; i++) {
          const div = divs[i];
          div.style.display = this.orientation === 'all' || div.getAttribute('orientation').toLowerCase() === this.orientation ? 'block' : 'none';
        }

        const soloIndex = this.linkedFormats.findIndex(format => format.isSolo);

        this.linkedFormats.forEach(format => {
          if (!(soloIndex !== -1 && this.linkedFormats[soloIndex].id !== format.campaignBurstScreenContent.id)) {
            this.$set(format, 'activeSave', false);
            this.$set(format, 'isSolo', false);
          }
          if ((this.orientation === 'all' || format.campaignBurstScreenContent.campaignBurstScreen.screen.orientation.toLowerCase() === this.orientation) && !format.isLocked && soloIndex === -1) {
            this.$set(format, 'activeSave', true);
          }
        });
      }

      this.fit ? this.frameFormatterPacked(false) : this.frameFormatterColumn();
      // needs to run twice to fit properly
      setTimeout(() => {
        this.fit ? this.frameFormatterPacked() : this.frameFormatterColumn();
      }, 1);
      
    },

    frameFormatterColumn() {
      this.fit = false;
      const barOffset = 22;
      const containerPadding = 24;
      const framePadding = 10;
      const container = document.getElementsByClassName('containerLBC')[0];
      const viewport = this.$refs.lbcViewport;
      const divs = Array.from(viewport.getElementsByClassName('box')).filter(div => div.style.display !== 'none');
      
      const col = this.viewScale;
      const containerWidth = this.metricToNumber(container.offsetWidth) - containerPadding - ((col - 1) * framePadding);
      const frameWidth = containerWidth / col;

      divs.forEach(div => {
        const divWidth = this.metricToNumber(div.getAttribute('w'));
        const divHeight = this.metricToNumber(div.getAttribute('h'));
        const scale = frameWidth / divWidth;
        const newHeight = divHeight * scale;

        const iframe = div.querySelector('iframe');
        div.style.width = `${frameWidth}px`;
        div.style.height = `${newHeight + barOffset}px`;
        iframe.style.transform = `scale(${scale})`;
      });

      new Packery(viewport, {
        itemSelector: '.box',
        percentPosition: true,
        gutter: 8,
        fitWidth: true
      });
    },

    getViewportHeight() {
      return window.innerHeight || 
            document.documentElement.clientHeight || 
            document.body.clientHeight || 
            0;
    },

    getViewportWidth() {
      return window.innerWidth || 
            document.documentElement.clientWidth || 
            document.body.clientWidth || 
            0;
    },

    getVisibleDimensions(divId) {
        const div = document.getElementById(divId);
        const rect = div.getBoundingClientRect();
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        const visibleWidth = Math.min(rect.right, viewportWidth) - Math.max(rect.left, 0);
        const visibleHeight = Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0);

        return {
          width: Math.max(0, visibleWidth),
          height: Math.max(0, visibleHeight)
        };
      },

    async frameFormatterPacked(force = false) {
      const container = document.getElementById('masterContainer');
      const viewport = this.$refs.lbcViewport;
      const divs = Array.from(viewport.getElementsByClassName('box'));

      const divElements = divs.filter(format => 
        format.getAttribute('orientation').toLowerCase() === this.orientation || 
        this.orientation === 'all' || 
        this.orientationMode === 'fit'
      ).sort((a, b) => (b.scrollWidth * b.scrollHeight) - (a.scrollWidth * a.scrollHeight));

      const rect = this.getVisibleDimensions('masterContainer');  

      const menuHeight = 55; // the space taken by the menu bar on top
      const rightHandMenu = document.getElementById('lbcViewport2');
      const rightHandMenuWidth = rightHandMenu ? rightHandMenu.offsetWidth : 0;

      const barOffset = 22;
      const containerWidth = rect.width //: this.metricToNumber(container.parentElement.scrollWidth);
      const containerHeight = rect.height //: this.metricToNumber(container.parentElement.scrollHeight); 
          
      //console.log("container offset width", viewport.offsetWidth);
      //console.log("container offset height", viewport.offsetHeight);
      //console.log("container bounding width", rect.width);
      //console.log("container bounding height", rect.height);

      // Precompute dimensions
      divElements.forEach(div => {
        const iframe = div.querySelector('iframe');
        div.dimensions = {
          width: iframe.scrollWidth,
          height: iframe.scrollHeight
        };
        div.style.width = `${div.dimensions.width}px`;
        div.style.height = `${div.dimensions.height}px`;
      });

      let resizer = force ? 10000 : 1;
      let velocity = 0;

      const updateDivDimensions = (div, thirdOfContainer) => {
        const { width: boxWidth, height: boxHeight } = div.dimensions;
        let newWidth, newHeight;

        if (boxWidth > boxHeight) {
          newWidth = thirdOfContainer;
          newHeight = thirdOfContainer * (boxHeight / boxWidth);
        } else {
          newWidth = thirdOfContainer * (boxWidth / boxHeight);
          newHeight = thirdOfContainer;
        }

        div.style.width = `${newWidth}px`;
        div.style.height = `${newHeight + barOffset}px`;
        div.querySelector('iframe').style.transform = `scale(${newWidth / boxWidth})`;
      };

      let circuitBreaker = 0;
      while (true) {
        circuitBreaker++;
        if(circuitBreaker > 100) {
          console.error("Circuit breaker triggered");
          break;
        }
        const thirdOfContainer = Math.max(containerWidth, containerHeight) / resizer;
        
        divElements.forEach(div => updateDivDimensions(div, thirdOfContainer));

        const pckry = new Packery(container, {
          itemSelector: '.box',
          percentPosition: true,
          gutter: 8
        });

        const currentContainerWidth = this.metricToNumber(container.scrollWidth);
        const currentContainerHeight = this.metricToNumber(container.scrollHeight);

        if (currentContainerWidth <= containerWidth && currentContainerHeight <= containerHeight) {
          //console.log("container dimensions", currentContainerWidth + " <= "  + containerWidth, currentContainerHeight + " <= " + containerHeight, circuitBreaker);
          break;
        }

        resizer += 0.05 + velocity;
        velocity *= 1.2;
      }
    },

  toggleFlag(data) {
    const dataEvent = data.split(",");
    const isFlagged = dataEvent[0].startsWith("LBC_IMAGE_NOT_FITTING");
    const screenName = dataEvent[1];

    if (screenName) {
      this.linkedFormats.forEach(linkedFormat => {
        if (this.extractContentIdFromUrl(linkedFormat.campaignBurstScreenContent.iFrameURL) === this.extractContentIdFromUrl(screenName)) {
          if (isFlagged) {
            this.$set(linkedFormat, 'isLocked', false);
            this.$set(linkedFormat, 'isFlagged', true);
          } else {
            this.$set(linkedFormat, 'isFlagged', false);
          }
        }
      });
    }
  },

  toggleSelected(format) {
    const linkedFormat = this.linkedFormats.find(linkedFormat => linkedFormat.campaignBurstScreenContent.id === format.campaignBurstScreenContent.id);

    if (linkedFormat) {
      this.$set(linkedFormat, 'isSolo', false);
      this.$set(linkedFormat, 'activeSave', !(linkedFormat.activeSave || linkedFormat.isLocked));
    }

    const isAnyActiveSave = this.linkedFormats.some(linkedFormat => linkedFormat.activeSave);
    this.toggleSelectAll = isAnyActiveSave;

    this.linkedFormatsData = [...this.linkedFormatsData]; // Ensure reactivity
  },

  toggleLock(format) {
    const linkedFormat = this.linkedFormats.find(linkedFormat => linkedFormat.campaignBurstScreenContent.id === format.campaignBurstScreenContent.id);

    if (linkedFormat && !linkedFormat.isFlagged) {
      this.$set(linkedFormat, 'isLocked', !linkedFormat.isLocked);
      if (linkedFormat.isLocked) {
        this.$set(linkedFormat, 'activeSave', false);
        this.$set(linkedFormat, 'isSolo', false);
      }
    }

    const isAnyLocked = this.linkedFormats.some(linkedFormat => linkedFormat.isLocked);
    this.toggleLockAll = isAnyLocked;

    this.linkedFormatsData = [...this.linkedFormatsData]; // Ensure reactivity
  },

  toggleSolo(format) {
    this.toggleIsSolo = !this.toggleIsSolo

    this.linkedFormatsData.forEach(linkedFormat => {
        if (!linkedFormat.isLocked) {
          if (linkedFormat.campaignBurstScreenContent.campaignBurstScreen.screen.width === format.campaignBurstScreenContent.campaignBurstScreen.screen.width && linkedFormat.campaignBurstScreenContent.campaignBurstScreen.screen.height === format.campaignBurstScreenContent.campaignBurstScreen.screen.height) {
            this.$set(linkedFormat, 'isSolo', this.toggleIsSolo);
            this.$set(linkedFormat, 'activeSave', true);
            if (this.toggleIsSolo){
              this.selectedContentForTriggerEdit = JSON.parse(JSON.stringify(linkedFormat.campaignBurstScreenContent));
              this.setOrientation(this.orientation, 'single', linkedFormat.campaignBurstScreenContent.campaignBurstScreen.screen)
            }
          } 
          else if (this.toggleIsSolo) {
            this.$set(linkedFormat, 'isSolo', false);
            this.$set(linkedFormat, 'activeSave', false);
          }
        }
      });

    if (!this.toggleIsSolo) {
      this.setOrientation(this.orientation, this.fit)
    }

    this.linkedFormatsData = [...this.linkedFormatsData]; // Ensure reactivity
  },

  toggleSelectAllButton() {
    this.linkedFormats.forEach(linkedFormat => {
      if (!linkedFormat.isLocked) {
        if (this.orientation === 'all' || linkedFormat.campaignBurstScreenContent.campaignBurstScreen.screen.orientation.toLowerCase() === this.orientation) {
          this.$set(linkedFormat, 'activeSave', !this.toggleSelectAll);
        }
      }
    });

    this.toggleSelectAll = !this.toggleSelectAll;

    this.linkedFormatsData = [...this.linkedFormatsData]; // Ensure reactivity
  },

  toggleLockAllButton() {
    this.linkedFormats.forEach(linkedFormat => {
      if (!linkedFormat.isFlagged) {
        if (this.orientation === 'all' || linkedFormat.campaignBurstScreenContent.campaignBurstScreen.screen.orientation.toLowerCase() === this.orientation) {
          this.$set(linkedFormat, 'isLocked', !this.toggleLockAll);
          this.$set(linkedFormat, 'activeSave', false);
          this.$set(linkedFormat, 'isSolo', false);
        }
      }
    });

    this.toggleLockAll = !this.toggleLockAll;

    this.linkedFormatsData = [...this.linkedFormatsData]; // Ensure reactivity
  },


    toggleFullScreen(forceWindowed = false, userTriggered = false) {
      if (!this.isFullScreen || forceWindowed) {
        document.exitFullscreen();

        this.$nextTick(() => {
          this.$refs.lbcViewport.classList.remove('lbc-viewport-fullscreen');
          this.$refs.lbcViewport.classList.add('lbc-viewport-windowed');
          this.$refs.lbcViewport2.classList.remove('lbc-viewport-2-fullscreen');
          this.$refs.lbcViewport2.classList.add('lbc-viewport-2-windowed');
        });
      } 
      else {
        // If the browser supports requestFullscreen, go fullscreen
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } 
        // For other browsers, try other methods
        else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
          document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
          document.documentElement.msRequestFullscreen();
        }

        this.$nextTick(() => {
          this.$refs.lbcViewport.classList.remove('lbc-viewport-windowed');
          this.$refs.lbcViewport.classList.add('lbc-viewport-fullscreen');
          this.$refs.lbcViewport2.classList.remove('lbc-viewport-2-windowed');
          this.$refs.lbcViewport2.classList.add('lbc-viewport-2-fullscreen');
        });
      }
      if (userTriggered) {
        setTimeout(() => {
          this.fit ? this.frameFormatterPacked() : this.frameFormatterColumn();
        }, 1);
        
      }
    },

    metricToNumber(metric) {
      return Number(metric.toString().replace(/[^0-9.-]+/g,""))
    },
    
    async openMultipleFormatsDialog(screenContent) {
      this.fit = false
      this.multipleFormatsDialog = true
      this.activeNewsStory = screenContent;
      this.selectedContentForTriggerEdit = JSON.parse(JSON.stringify(screenContent))

      // assign regions and antiRegions to regionsSearchText
      if(this.selectedContentForTriggerEdit.triggers.region)
        {
          this.regionsSearchText['regions'] = this.selectedContentForTriggerEdit.triggers.region.regions ? this.selectedContentForTriggerEdit.triggers.region.regions.split(',') : []
          this.regionsSearchText['antiRegions'] = this.selectedContentForTriggerEdit.triggers.region.antiRegions ? this.selectedContentForTriggerEdit.triggers.region.antiRegions.split(',') : []
        }

      //if this.selectedContentForTriggerEdit.triggers.globalLBCnews.headline exists replace '\\n' with javasript breaklines
      // if (this.selectedContentForTriggerEdit.triggers.globalLBCnews.headline) {
      //   this.selectedContentForTriggerEdit.triggers.globalLBCnews.headline = this.selectedContentForTriggerEdit.triggers.globalLBCnews.headline.replace(/\\n/g, "\n");
      // }
      this.disableTriggerSave = true
      await this.getLinkedFormats(screenContent.id)
      this.disableTriggerSave = false
    },

    async getLinkedFormats(screenContentId) {
    try {
      this.resetLinkedFormatsState();

      const res = await CampaignController.getLinkedFormats(screenContentId);
      let localLinkedFormats = res.data.filter(screen => 
        screen.campaignBurstScreenContent.campaignBurstScreen !== null
      );

      localLinkedFormats = this.filterUniqueById(localLinkedFormats);

      const activeNewsStoryIndex = localLinkedFormats.findIndex(screen => screen.campaignBurstScreenContent.id === screenContentId);
      if (activeNewsStoryIndex !== -1) {
        const activeNewsStory = localLinkedFormats.splice(activeNewsStoryIndex, 1)[0];
        localLinkedFormats.unshift(activeNewsStory);
      }

      localLinkedFormats = this.filterUniqueBySize(localLinkedFormats);

      localLinkedFormats.sort(this.compareByAspectRatio);

      this.totalLinkedFormats = localLinkedFormats.length;
      this.totalLinkedFormatsProgress = 0;
      this.totalIframes = localLinkedFormats.length;

      const screenContentPromises = localLinkedFormats.map(screen => this.processScreenContent(screen));
      await Promise.all(screenContentPromises);

      this.sortLinkedFormatsData();
      this.markFormatsInactive();
      
    } catch (err) {
      console.error(err);
      this.linkedFormatsLoaded = true;  // Ensure loader is removed even if there's an error
    }

    if (!this.activeNewsStory || !this.activeNewsStory.media) {
        console.log("activeNewsStory.media");
      }
    else {
      const focalContainer = this.$refs.focalContainerWrapper;
      var w = 0;
      var h = 0;
      // // Ensure the element is found
      // if (focalContainer) {
      //   // Get the width and height
      //   w = focalContainer.offsetWidth;
      // }
      // else{
      //   w = 376; // manually measured
      // }

      w = 376;

      h = this.activeNewsStory.media.height * (w / this.activeNewsStory.media.width);

      this.containerWidth = w;
      this.containerHeight = h;
      this.updateContainerSize();
    }
  },

  async processScreenContent(screen) {
    screen.campaignBurstScreenContent.triggers = JSON.parse(screen.campaignBurstScreenContent.triggers);
    if(!this.killAllDialogSaving && !this.goLiveDialogSaving)
      await this.getScreenContentIFrame(screen.campaignBurstScreenContent).catch(err => {});

    this.addToLinkedFormatsData(screen);
    // mod 4 of each iframe
    if (this.iframeLoadCount % 4 === 0) {
      await this.$nextTick();
    }
  },

  resetLinkedFormatsState() {
    this.linkedFormatsData = [];
    this.toggleLockAll = false;
    this.toggleSelectAll = true;
    this.linkedFormatsLoaded = false;
    this.iframeLoadCount = 0;
  },

  filterUniqueById(localLinkedFormats) {
    return localLinkedFormats.filter((screen, index, self) => 
      index === self.findIndex(t => (
        t.campaignBurstScreenContent.campaignBurstScreen.id === screen.campaignBurstScreenContent.campaignBurstScreen.id
      ))
    );
  },

  filterUniqueBySize(localLinkedFormats) {
    return localLinkedFormats.filter((screen, index, self) => 
      index === self.findIndex(t => (
        t.campaignBurstScreenContent.campaignBurstScreen.screen.width === screen.campaignBurstScreenContent.campaignBurstScreen.screen.width &&
        t.campaignBurstScreenContent.campaignBurstScreen.screen.height === screen.campaignBurstScreenContent.campaignBurstScreen.screen.height
      ))
    );
  },

  compareByAspectRatio(b, a) {
    return (b.campaignBurstScreenContent.campaignBurstScreen.screen.width / b.campaignBurstScreenContent.campaignBurstScreen.screen.height) - 
           (a.campaignBurstScreenContent.campaignBurstScreen.screen.width / a.campaignBurstScreenContent.campaignBurstScreen.screen.height);
  },

  sortLinkedFormatsData() {
    this.linkedFormatsData.sort(this.compareByAspectRatio);
  },

  markFormatsInactive() {
    this.linkedFormatsData = this.linkedFormatsData.map(format => ({
      ...format,
      activeSave: true,
      isLocked: false,
      isSolo: false,
      isFlagged: false
    }));
  },

  addToLinkedFormatsData(screen) {
    // Avoid direct push to reactive array
    const tempFormats = [...this.linkedFormatsData];
    tempFormats.push(screen);
    this.linkedFormatsData = tempFormats;

    this.totalLinkedFormatsProgress++;
    this.updateLoaderProgress();
  },

    resetCalendarView() {
      let dateRange = this.toDateRange(this.selectedContentForTriggerEdit.triggers.dateRange.startEndDateRange)
      const pickerObj = this.$refs.picker[0]
      if(pickerObj)
      {
        // date - 1 month
        pickerObj.monthDate = moment(dateRange.startDate).subtract(1, 'month').toDate()
        // current date
        pickerObj.nextMonthDate = moment(dateRange.startDate).toDate()
      }
      
    },

    getDateRangeFromPickerRef() {
      const pickerObj = this.$refs.picker[0]
      if(pickerObj)
      {
        const dateRange = {startDate: pickerObj.start, endDate: pickerObj.end}
        return dateRange
      }
      return this.toDateRange('')
    },

    setIndefinite(picker) {
      this.selectedContentForTriggerEdit.dirtyDateRange = true
      this.selectedContentForTriggerEdit.dateRangeIndefinite = !this.selectedContentForTriggerEdit.dateRangeIndefinite
      let isIndefinite = this.selectedContentForTriggerEdit.dateRangeIndefinite
        if(isIndefinite){ // Checkbox true
          this.selectedContentForTriggerEdit.preIndefinite = this.toTriggerDateRange(this.getDateRangeFromPickerRef()) // Save the previous date range
          this.updateTrigger('dateRange', 'startEndDateRange', '|')
        } else {
          let unsetDate = this.selectedContentForTriggerEdit.preIndefinite || this.selectedContentForTriggerEdit.previousDateRange// Set the date to the previous selection
          if (!unsetDate || unsetDate === '|')
            unsetDate = this.toTriggerDateRange(this.toDateRange('')) // Set the date to today and tomorrow
          this.updateTrigger('dateRange', 'startEndDateRange', unsetDate)
          this.selectedContentForTriggerEdit.preIndefinite = unsetDate
          this.resetCalendarView()
        }
        this.calendarShowHide(isIndefinite)
        this.applyIndefinite()
    },

    calendarShowHide(hide) {
      //return;
      const pickerObj = this.$refs.picker[0]
        if(pickerObj)
        {
          // hide each child of pickerObj
          pickerObj.$children.forEach(child => {
            child.$el.style.display = hide ? 'none' : 'block'
          })
        }
    },

    applyIndefinite() {
      let isIndefinite = this.isIndefinite(this.selectedContentForTriggerEdit.triggers.dateRange.startEndDateRange)
      this.$set(this.selectedContentForTriggerEdit, 'dateRangeIndefinite', isIndefinite)
    },

    handleCalendarCancel(picker){
      this.updateTrigger('dateRange', 'startEndDateRange', this.selectedContentForTriggerEdit.previousDateRange)
      this.applyIndefinite()
      picker.clickCancel()
    },

    handleCalendarApply(picker){
      this.selectedContentForTriggerEdit.dirtyDateRange = false
      picker.clickApply()
    },

    handleCalendarSelect(dateRange) {
      this.selectedContentForTriggerEdit.dirtyDateRange = true
    },

    handleCalendarToggle(event) {
      if(event) // open
      {
        const dateRange = this.selectedContentForTriggerEdit.triggers.dateRange.startEndDateRange
        this.selectedContentForTriggerEdit.dirtyDateRange = false
        this.selectedContentForTriggerEdit.previousDateRange = dateRange
        this.selectedContentForTriggerEdit.preIndefinite = dateRange
        this.$nextTick(() => {
          // Code to execute after the DOM has been updated
          this.calendarShowHide(this.selectedContentForTriggerEdit.dateRangeIndefinite)
        });
      }
      else if (this.selectedContentForTriggerEdit.dirtyDateRange) // close
      {
        // closed without applying
        this.updateTrigger('dateRange', 'startEndDateRange', this.selectedContentForTriggerEdit.previousDateRange) 
        this.applyIndefinite()
      }
    },

    async reloadAllIFrames()
    {
      this.initialLoad = false
      if (this.filteredScreenContents.length > 0) {
        for (let i = 0; i < this.filteredScreenContents.length; i++) {
          await this.refreshIFrame(this.filteredScreenContents[i],true)
        }
      }
      await this.$nextTick()
      this.initialLoad = true;
    },

    async refreshIFrame(screenContent, force = false)
    {
      if(this.preventReload) return
      var findIndex = this.filteredScreenContents.findIndex(x => x.id === screenContent.id)
      if (findIndex !== -1) {
        if (force || this.filteredScreenContents[findIndex]?.iFrameURL == null) {
          if(!this.filteredScreenContents[findIndex].campaignBurstScreen)
          {
            // if the campaignBurstScreen assign it to the screenContent
            this.filteredScreenContents[findIndex].campaignBurstScreen = this.selectedCampaignBurstScreen
          }
          screenContent = await this.getScreenContentIFrame(this.filteredScreenContents[findIndex])
        }
        if(this.filteredScreenContents[findIndex].iFrameURL)
          screenContent.iFrameURL += "?" + Date.now()
        this.filteredScreenContents[findIndex] = screenContent
      }
      return screenContent
    },
    async replaceArtwork(type, screenContent, event)
    {
      this.cancelUploadMedia()
      this.artworkReplace = true
      this.activeNewsStory = screenContent;
      this.selectedContentForTriggerEdit = JSON.parse(JSON.stringify(screenContent));
      this.selectedArtToRepl = screenContent.id
      if(type == 'click')
        await this.clickFileUpload()
      else if(type == 'drop')
        await this.dragFile(event)
    },

    async uploadMedia(type, event)
    {
      this.cancelUploadMedia()
      if(type == 'click')
        await this.clickFileUpload()
      else if(type == 'drop')
        await this.dragFile(event)
    },

    async cancelUploadMedia() {
      this.$refs.mediaUploadLBC.value = ''
      this.artworkReplace = false
      this.activeNewsStory = {}
      this.selectedContentForTriggerEdit = null
      this.selectedArtToRepl = null
      // Empty array
      this.uploadedFileCreative = []
      // Reset file size check
      this.isArtworkFileBigger = false
      // Close upload modal
      this.showUploadMediaDialog = false
    },

    async togglePublishState()
    {
      this.goLiveDialogSaving = true
      let publishedState = this.returnNextGoLiveState(this.selectedContentForTriggerEdit.triggers.golive.islive);
      await this.setPublishState(publishedState)
      this.goLiveDialog = false;
      await this.submitTriggers(publishedState == 'Published')
      this.goLiveDialogSaving = false
    },

    // Kill All
    async killAllNewsStories()
    {
      // Iteratethrough every screen content and set the golive to draft
      this.killAllDialogSaving = true
      this.openKillAllDialog = true
      this.preventReload = true
      let publishedState = "Draft";
      const killAllNewsStoriesPromises = this.filteredScreenContents.map(screenContent => this.killAllNewsStoriesSave(screenContent,publishedState));
      await Promise.all(killAllNewsStoriesPromises);
      this.openKillAllDialog = false;
      this.preventReload = false
      // submit all the changes
      this.killAllDialogSaving = false
      await this.reloadAllIFrames()
    },

    async killAllNewsStoriesSave(screenContent,publishedState) {
      // Simulating an async operation using a promise
      this.selectedContentForTriggerEdit = JSON.parse(JSON.stringify(screenContent));
      await this.setPublishState(publishedState)
      await this.submitTriggers(false)
    },

    async setPublishState(publishedState)
    {
      const waitForLinkedFormats = [this.getLinkedFormats(this.selectedContentForTriggerEdit.id)];
      await Promise.all(waitForLinkedFormats);
      
      // loop through all linked formats setting their activeSave to true
      this.linkedFormats.forEach((format) => {
        this.$set(format, 'activeSave', true);
      })
      // loop through all the linked formats and set the golive to the same state
      this.updateTrigger('golive', 'islive', publishedState, null) // null to update edit list
    },

    async applyLiveStatus(screenContent)
    {
      // assign live status
      this.$set(screenContent, 'dateRange', this.toDateRange(screenContent.triggers.dateRange.startEndDateRange))
      this.$set(screenContent, 'liveStatus', this.liveStatus(screenContent.dateRange,screenContent.triggers.golive.islive))
      this.$set(screenContent, 'liveStatusColor', this.liveStatusColor(screenContent.liveStatus))
      this.$set(screenContent, 'liveStatusText', this.liveStatusText(screenContent.liveStatus))
      this.$set(screenContent, 'liveStatusTooltip', this.liveStatusTooltip(screenContent.liveStatus, screenContent.dateRange))
      this.$set(screenContent, 'dateRangeIndefinite', this.isIndefinite(screenContent.triggers.dateRange.startEndDateRange))
      return screenContent
    },

    liveStatus(dateRange, isLive) {
      if(this.getGoLivePublishState(isLive) != "Published")
        return 0;
      var sd = moment(dateRange.startDate)
      var ed = moment(dateRange.endDate)
      var now = moment()
      if(now.isBetween(sd, ed)) {
        return 1;
      }
      else if(sd.isAfter(now)) {
        return 2;
      }
      else {
        return 3;
      }
    },

    liveStatusText: function( status ) {
      if(status == 1)
      {
        return "Live";
      } 
      else if(status == 2)
      {
        return "Scheduled";
      } 
      else if(status == 3)
      {
        return "Finished";
      }
      return "Draft";
    },

    liveStatusColor: function( status ) {
      if(status == 1)
      {
        return "#4CAF50"; // Live
      } 
      else if(status == 2)
      {
        return "#5389A9"; // Scheduled
      } 
      else if(status == 3)
      {
        return "#D9B349"; // Finished
      } 
      return "#F44336"; // Draft
    },

    liveStatusTooltip: function( status, dateRange ) {
      var color = this.liveStatusColor(status);
      if(status == 1) // Live
      {
        return "Live (Click to toggle)";
      } 
      else if(status == 2) // Scheduled
      {
        return "Scheduled: " + moment(dateRange.startDate).format('DD MMM YYYY HH:mm a') ; 
      } 
      else if(status == 3) // Finished
      {
        return "Finished: " + moment(dateRange.endDate).format('DD MMM YYYY HH:mm a'); 
      } 
      return "Draft (Click to toggle)"; // Draft
    },


    toDateRange(dateRange) {    
      if(dateRange === "|") {
        // Set the start date to 1900-01-01 00:00 and endDate to 2100-01-01 00:00
        return { startDate: moment('1900-01-01 00:00', this.dateRangeFormat), endDate: moment('2100-01-01 00:00', this.dateRangeFormat) }
      } else {
        var sd = moment().startOf('day');
        var ed = moment().startOf('day').add(1, 'day');
        try {
          var dateRangeParts = dateRange.split('|')
          if (dateRangeParts.length == 2) {
            sd = moment(dateRangeParts[0], this.dateRangeFormat)
            ed = moment(dateRangeParts[1], this.dateRangeFormat)
          }
        } catch (e) {

        }
        return { startDate: sd, endDate: ed }
      }
    },

    toTriggerDateRange(dateRange) {
      return moment(dateRange.startDate).format(this.dateRangeFormat) + "|" + moment(dateRange.endDate).format(this.dateRangeFormat)
    },

    // Open LBC Modal
    async openLbcEditModal(screenContent) {
      this.activeNewsStory = screenContent; 
      this.selectedContentForTriggerEdit = JSON.parse(JSON.stringify(screenContent));

      // assign regions and antiRegions to regionsSearchText
      await this.refreshIFrame(screenContent, true)
      this.preventReload = true
      this.triggerLbcDialog = true
      this.applyIndefinite()

        this.$nextTick()
    },

    async indicatorClick(screenContent)
    {
      this.selectedContentForTriggerEdit = JSON.parse(JSON.stringify(screenContent));
      this.activeNewsStory = screenContent;
      if(screenContent.liveStatus == 1)
      {
        this.goLiveDialog = true
      }
      else if(screenContent.liveStatus == 0)
      {
        this.goLiveDialog = true
      }
      else
      {
        this.openLbcEditModal(screenContent)
      }
    },

    async getScreenContentIFrame(screenContent) {
      let screen = screenContent;
      var mode = screen.triggers.golive;
      mode = mode ? screen.triggers.golive.islive : "draft";
      mode = mode === "Published" ? "live" : mode.toLowerCase();
      this.lastPublishState = mode;
    
      try {
        const res = await CampaignController.getPreview({
          campaignBurstScreenId: screen.campaignBurstScreenId,
          frameId: this.previewLocation.idA,
          date: this.toDateRange(screen.triggers.dateRange.startEndDateRange).startDate.add(1, 'second').format('YYYY-MM-DD'),
          time: this.toDateRange(screen.triggers.dateRange.startEndDateRange).startDate.add(1, 'second').format('HH:mm:ss'),
          live: mode,
          width: screen.campaignBurstScreen.screen.width,
          height: screen.campaignBurstScreen.screen.height,
          campaignBurstScreenContentId: screen.id
        });

        if (res.data && res.data.url) {
          screen.iFrameURL = res.data.url;
        }
      } catch (err) {
        console.error(err);
      }

      return screen;
    },

    updateLoaderProgress() {
    // Implementation for updating the loader progress
  },

    async onIframeLoaded() {
      this.iframeLoadCount++;
      if (this.iframeLoadCount === this.totalIframes) {
        this.linkedFormatsLoaded = true;
        // All iframes are loaded, you can update your UI here
        this.$emit('all-iframes-loaded');
        if (this.multipleFormatsDialog) {
          this.setOrientation("all", "fit");
        }
      }
    },

    parseTriggers(screenContent) {
      let screen = screenContent
      let parsedTriggers
      try {
        parsedTriggers = screen.triggers
        screen.triggers = parsedTriggers
      } catch (err) {  // Send error on catch with relevant pop up message
        console.log(err)
      }

      // match up with screen level triggers, and make strings = booleans to avoid bug with fields not populating properly
      for (var i = 0; i < this.screenLevelTriggers.length; i++) {
        for (
          var k = 0;
          k <
          Object.getOwnPropertyNames(
            parsedTriggers[this.screenLevelTriggers[i]],
          ).length;
          k++
        ) {
          if (
            parsedTriggers[this.screenLevelTriggers[i]][
            Object.getOwnPropertyNames(
              parsedTriggers[this.screenLevelTriggers[i]],
            )[k]
            ] === 'false'
          ) {
            parsedTriggers[this.screenLevelTriggers[i]][
              Object.getOwnPropertyNames(
                parsedTriggers[this.screenLevelTriggers[i]],
              )[k]
            ] = false
          }
          if (
            parsedTriggers[this.screenLevelTriggers[i]][
            Object.getOwnPropertyNames(
              parsedTriggers[this.screenLevelTriggers[i]],
            )[k]
            ] === 'true'
          ) {
            parsedTriggers[this.screenLevelTriggers[i]][
              Object.getOwnPropertyNames(
                parsedTriggers[this.screenLevelTriggers[i]],
              )[k]
            ] = true
          }
        }
      }
    },

    // Ladbrokes change handler
    ladbrokesChangeHandler(triggerName, triggerContent, affectOnly) {
      if (triggerName == 'ladbrokes') {
        var mode = this.selectedContentForTriggerEdit.triggers.ladbrokes.mode;
        var assignTo = "";
        mode = mode.toLowerCase();
        if (mode) {
          // get the relevant ID
          var id = "";
          var fetchWhich = "";
          if (triggerContent == 'market') {
            fetchWhich = 'market';
            assignTo = 'selection';
            mode = 'selection';
            id = this.selectedContentForTriggerEdit.triggers.ladbrokes?.market;
          }
          else if (triggerContent == 'events') {
            fetchWhich = 'event';
            assignTo = 'market';
            mode = "market";
            id = this.selectedContentForTriggerEdit.triggers.ladbrokes?.events;
          }
          else if (triggerContent == mode || triggerContent == 'mode') {
            if (mode == 'class') {
              fetchWhich = 'events';
              id = this.selectedContentForTriggerEdit.triggers.ladbrokes?.class;
            }
            else if (mode == 'category') {
              fetchWhich = 'events';
              id = this.selectedContentForTriggerEdit.triggers.ladbrokes?.category;
            }
            else if (mode == 'type') {
              fetchWhich = 'events';
              id = this.selectedContentForTriggerEdit.triggers.ladbrokes?.type;
            }
            assignTo = 'events';
          }
          else
            return;

          // clear existing values
          if (fetchWhich == "events") {
            this.$set(this.ladbrokesInfo, "events", []);
            if (!affectOnly) {
              this.$set(this.ladbrokesInfo, "market", []);
              this.$set(this.ladbrokesInfo, "selection", []);
              this.selectedContentForTriggerEdit.triggers.ladbrokes.events = ""
              this.selectedContentForTriggerEdit.triggers.ladbrokes.market = ""
              this.selectedContentForTriggerEdit.triggers.ladbrokes.selection = ""
            }
          }
          else if (fetchWhich == 'event') {
            this.$set(this.ladbrokesInfo, "market", []);
            if (!affectOnly) {
              this.$set(this.ladbrokesInfo, 'selection', []);
              this.selectedContentForTriggerEdit.triggers.ladbrokes.market = ""
              this.selectedContentForTriggerEdit.triggers.ladbrokes.selection = ""
            }
          }
          else if (triggerContent == 'mode') {
            this.$set(this.ladbrokesInfo, "events", []);
            this.$set(this.ladbrokesInfo, "market", []);
            this.$set(this.ladbrokesInfo, "selection", []);
            this.selectedContentForTriggerEdit.triggers.ladbrokes.events = ""
            this.selectedContentForTriggerEdit.triggers.ladbrokes.market = ""
            this.selectedContentForTriggerEdit.triggers.ladbrokes.selection = ""
          }

          const numberRegex = /\((\d+)\)/;
          const match = id.match(numberRegex);
          if (match) {
            const number = match[1];
            id = Number(number); // Output: 40

          } else {
            id = 0
          }

          if (id > 0 && mode)
            this.getLadbrokesInfo(fetchWhich, assignTo, mode, id);
        }
      }
    },

    // safe list split
    safeListSplit(list, separator = ",") {
      if (list) {
        return list.split(separator).map(item => item.trim()) // map to remove whitespace

      }
      return []
    },

    async copyText(txt) {
      try {
        await navigator.clipboard.writeText(txt);
        this.$root.$emit('snackbarSuccess', 'Copied to clipboard')
      } catch (error) {
        console.error('Failed to copy text: ', error);
      }
    },
    // Update artwork object 
    async updateArtwork(newData) {
      let screenContent = newData.data
      screenContent.triggers = JSON.parse(newData.data.triggers)
      const oldVal = this.screenContents.find(item => item.id === screenContent.id)
      const screenContentIndex = this.screenContents.indexOf(oldVal)
      if(screenContentIndex > -1)
      {
        let scfte = this.screenContents[screenContentIndex];
        //if(this.activeNewsStory.id == screenContent.id)
        {
          let sc = await this.refreshIFrame(scfte, true)
          if(sc != null)
            screenContent.iFrameURL = sc.iFrameURL + "?" + Date.now()
        }

        if(!scfte.campaignBurstScreen)
          scfte.campaignBurstScreen = this.selectedCampaignBurstScreen
        let oldCBS = JSON.parse(JSON.stringify(scfte.campaignBurstScreen))
        screenContent.campaignBurstScreen = oldCBS
    
    //    if (this.preventReload)
    //      screenContent.iFrameURL = scfte.iFrameURL
      
        // assign live status
        screenContent = await this.applyLiveStatus(screenContent)
        
        this.screenContents.splice(screenContentIndex, 1, screenContent)
      }
    },
    // Show/Hide timeline
    toggleTimeline(id) {
      if (this.cardOne[id] == 'start') {
        this.$set(this.cardOne, id, 'flipped')
      } else {
        this.$set(this.cardOne, id, 'start')
      }
    },
    // Artwork(Creatives) drag and drop functions
    dragFileUpload(id, bgColor) {
      if (document.getElementById(id) != null) {
        document.getElementById(id).style.backgroundColor = bgColor
      }
    },
    dragFilePlusUpload(id, show) {
      const fileUpload = document.getElementById(id)
      if (fileUpload != null) {
        fileUpload.style.opacity = (show === 'hide') ? 0 : 1
      }
    },
    clickFileUpload() {
      this.$refs.mediaUploadLBC.value = ''
      localStorage.setItem("upload_media_reload", true);
      const fileUpload = document.getElementById('mediaUploadLBC')
      if (fileUpload != null) {
        fileUpload.click()
      }
    },
    // Copy/paste triggers global storage
    addSelectedList(id) {
      this.$store.dispatch('Clipboard/addSelected', id)
    },
    checkSelectedList(id) {
      return this.$store.state.Clipboard.selected.includes(id)
    },

    //Sequence Color
    isSelectedColor(paramName, itemParamName, currentColor) {
      // Add logic to check if the current color is selected
      return (
        this.selectedContentForTriggerEdit.triggers[paramName][itemParamName] === currentColor
      );
    },
    // Copy/paste triggers function
    selectedTriggersCopy(data) {
      data.triggers = Object.fromEntries(Object.entries(data.triggers).filter(([key]) => this.screenLevelTriggers.includes(key)));
      this.$store.dispatch('Clipboard/setClipboard', data)
    },

    async getCampaignById() {
      this.lastUpdatedTime = moment().format('YYYY-MMM-DD HH:MM:SS')
      await CampaignController.getCampaign(this.$route.query.cid).then(async (res) => {
        this.campaign = res.data
        // Get all the bursts for the campaign
        this.campaignBursts = res.data.campaignBursts
        // Populate selected burst
        this.populateCampaignBurst(parseInt(this.$route.query.bid))
        // Populate selected screen
        this.populateCampaignBurstScreen(parseInt(this.$route.query.sid))
        this.updateBreadcrumbs()
        this.refreshScreens()
      })
    },

    publishStateConvert(publishState) {
      // required for legacy true(live)/false(preview) conversion
      const pState = publishState.toString().toLowerCase().trim()
      if (pState == "true")
        return "Published";
      else if (pState == "false")
        return "Preview";
      return publishState;
    },

    setPreviewLocation({ newLocation }) {
      if (newLocation) {
        if (newLocation.name == '') // Greg: not quite sure at this point, why the VUE breaks when this .name is empty (when clicking on a location row it would jump to the preview tab)
          newLocation.name = '-'
        this.previewLocation = newLocation
      } else {
        this.previewLocation = {}
      }
    },
    // Function that verifies the tabs for correctness
    checkForTabSelection() {
      if (this.$route.query.tab) {
        this.tabOptions.forEach((item, index) => {
          if (item === this.$route.query.tab) {
            this.tab = index
          }
        })
      }
      this.checkedTabOnLoad = true
    },
    // Function that updates the router based on tab selection  by user
    async updateTabSelection() {
      if (this.checkedTabOnLoad) {
        for (let index = 0; index < this.tabOptions.length; index++) {
          const item = this.tabOptions[index]
          if (this.tab === index) {
            // Avoid redundant navigation replaces
            const curr = JSON.stringify({ ...this.$route.query })
            const replace = JSON.stringify({ ...this.$route.query, tab: item.toString() })
            if (curr !== replace) {
              this.$router.replace({ query: { ...this.$route.query, tab: item.toString() } })
            }
          }
        }
      }
    },

    regionTitle(regions) {
      const arr = regions.split(',')
      if (arr.length > 1)
        return 'regions:'
      else
        return 'region:'
    },

    isActiveTransitIcon(string, search) {
      string = string.toLowerCase()
      string = string.split(",")
      let found = false

      string.forEach(element => {
        if (element === search)
          found = true
      });

      return found
    },
    // Duisplay locaton data 
    prettifyLocationMetadata(location) {
      var output = ""
      if (location != undefined) {
        output += "<v-row cols=6>"
        output += (location.idA) ? "<b>Frame ID:</b> " + location.idA + ", " : ""
        output += (location.name) ? "<b>Location:</b> " + location.name + ", " : ""
        output += (location.latitude) ? "<b>Lat/Long:</b> " + location.latitude + "," + location.longitude + ", " : ""
        output += (location.region) ? "<b>Region:</b> " + location.region + ", " : ""
        output += (location.staticOnly) ? "<b>Static Only:</b> " + location.staticOnly + ", " : ""
        output += (location.hfssRestriction) ? "<b>HFSS Restriction:</b> " + location.hfssRestriction + ", " : ""
        output += (location.alcoholRestriction) ? "<b>Alcohol Restriction:</b> " + location.alcoholRestriction + ", " : ""
        output += (location.modestyRestriction) ? "<b>Cultural Restriction:</b> " + location.modestyRestriction + ", " : ""
        output += (location.regulatoryRestriction) ? "<b>Regulatory Restriction:</b> " + location.regulatoryRestriction + ", " : ""
        output += (location.weather) ? "<b>Weather:</b> " + location.weather + ", " : ""
        output += (location.customCallouts) ? "<b>Custom Callouts:</b> " + location.customCallouts + ", " : ""
        if (output.length > 2)
          output = output.slice(0, -2).trim()
        output += "</v-row>"
      }
      return output
    },

    triggerErrorMessage(errors, param) {
      if (errors && errors.length > 0) {
        return errors
      }
      else {
        return ""
      }
    },

    checkTriggerRules(event, item) {
      // Don't allow typing of numbers
      if (item.type === 'number') {
        // keypress
        if ((event.which < 48 && event.which !== 45) || event.which > 57)
          event.preventDefault()
        // Paste
        if (event.type === 'paste') {
          const input = event.clipboardData.getData('text')
          const number = new RegExp(/\d/)
          if (!number.test(input))
            event.preventDefault()
        }
      }
    },
    disableSaveTriggerBtn(errors, param) {
      // Disable the save button
      this.disableTriggerSaveBtn = (errors && errors.length > 0 || this.$refs[param][0].badInput) ? true : false
    },
    // 
    async getAllLocations() {
      if (this.selectedCampaignBurstScreen)
        await CampaignController.getLocationList(this.selectedCampaignBurstScreen.id)
          .then((res) => {
            this.locationsList = res.data.locationList
            this.locationsList = this.expandLocationExtraInfo(this.locationsList)
            this.locationsList = this.locationsList.sort(function (a, b) { return a.name.localeCompare(b.name) }) // sort
          })
    },
    expandLocationExtraInfo(locationtableData) {
      // expand extra location info to columns
      for (const ld in locationtableData) {
        const json = (locationtableData[ld].extraInfo) ? JSON.parse(locationtableData[ld].extraInfo) : {}

        for (const prop in json) {
          locationtableData[ld][prop] = json[prop]
        }
        delete locationtableData[ld].extraInfo
      }
      return locationtableData;
    },
    setAllLocations(allLocations) {
      if (allLocations)
        this.locationsList = allLocations
    },
    uploadFile(e) { 
      this.uploadedFileCreative = Object.values(e.target.files)
      // Check if files or file is too large
      if (this.uploadedFileCreative.length > 0) {
        this.showUploadMediaDialog = true
        // Loop through files
        this.uploadedFileCreative.forEach(file => {
          // Check if file is too large
          if (file.size > this.selectedCampaignBurstScreen.screen.maxFileSizeKB * 1024) {
            // Set error flag
            this.isArtworkFileBigger = true
            // Append property error with value true to object file
            file.err = true
            // Show error message
            this.$root.$emit('snackbarWarning', 'Artwork file size is bigger than format max supported file size.')
          } else {
            file.err = false
            localStorage.setItem("upload_media_reload", "true");
          }
        })
      }
      else
        cancelUploadMedia()
    },
    dragFile(e) {
      // Accept the file
      this.uploadedFileCreative = Object.values(e.dataTransfer.files)
      // Change background colour
      this.$refs.dragDiv.style.backgroundColor = 'transparent'
      // Check if files or file is too large
      if (this.uploadedFileCreative.length > 0) {
        // Loop through files
        this.uploadedFileCreative.forEach(file => {
          // Check if file is too large
          if (file.size > this.selectedCampaignBurstScreen.screen.maxFileSizeKB * 1024) {
            // Set error flag
            this.isArtworkFileBigger = true
            file.err = true
            // Show error message
            this.$root.$emit('snackbarError', 'Artwork file size is bigger than format max supported file size.')
          }
        })
      } else {
        file.err = false
        localStorage.setItem("upload_media_reload", true);
      }
      // Shorthand for if a screen is selected
      this.showUploadMediaDialog = true
    },

    cancelCreatePlaceholder() {
      // Empty placeholder text
      this.draftPlacholderText = ""
      // Close draft placeholder modal
      this.showDraftPlaceholderDialog = false
    },
    removeUploadMedia(selectedItem) {
      // Filter out removed file
      this.uploadedFileCreative = [...this.uploadedFileCreative].filter(item => {
        if (item.name !== selectedItem.name)
          return item
      })
      // If list empty -> close upload modal
      if (this.uploadedFileCreative.length === 0)
        this.cancelUploadMedia()
    },
    imageDimensionCheck(media) {
      // defaults
      let result = false
      let icon = false
      let color = ''
      let message = ''
      // if w or h is incorrect
      if (media.width !== this.selectedScreen.width || media.height !== this.selectedScreen.height) {
        result = true
        icon = "mdi-close"
        color = 'red'
        message = 'Incorrect media dimensions'
        // If aspect ratio is correct
        if ((this.selectedScreen.height / this.selectedScreen.width) === (media.height / media.width)) {
          // up scale
          if (this.selectedScreen.height < media.height && this.selectedScreen.width < media.width) {
            result = true
            icon = "mdi-check"
            color = 'orange'
            message = 'Correct aspect ratio and will up scale media losing quality'
          }
          // down scale
          else {
            result = true
            icon = "mdi-close"
            color = 'orange'
            message = 'Correct aspect ratio and will up scale media losing quality'
          }
        }
      }
      // Else correct
      else {
        result = true
        icon = "mdi-check"
        color = 'green'
        message = 'Correct media dimensions'
      }
      // return the object
      return { result, icon, color, message }
    },
    limitText(str, numChars, fallbackStr) {
      if (str === null || str.length == 0)
        str = fallbackStr
      else if (str && str.length > numChars)
        str = str.substring(0, numChars) + '...'
      return str
    },
    getGoLivePublishState(publishState) {
      const pState = publishState.toString().toLowerCase().trim()
      switch (pState) {
        case "archived":
          return "Archived";
        case "draft":
          return "Draft"
        case "preview":
        case "false": // legacy preview
          return "Preview"
        case "true": // legacy live
        case "live":
        case "published":
          return "Published"
      }
      return "Unknown"
    },
    updateSelectedDate(date) {
      this.selectedDate = date
    },
    sortScreenContents() {
      if (this.screenContents && this.screenContents.length > 0) {
        // Contains sequence trigger
        if (this.screenContents[0].triggers.sequence) {
          // Sort based on group + sequence
          this.screenContents = this.screenContents.sort((a, b) =>
            a.triggers.sequence.group.localeCompare(b.triggers.sequence.group) ||
            a.triggers.sequence.sequence - b.triggers.sequence.sequence
          )
        }
      }
    },
    populateCampaignBurst(bid) {
      const searchBurst = (what) => this.campaign.campaignBursts.find((element) => element.id === what)
      this.selectedBurst = searchBurst(bid)
      if (this.selectedBurst == null)
        this.selectedBurst = this.campaignBursts[0]
      // Get all the screens for the selected burst
      const indexBurst = this.campaignBursts.findIndex(el => el.id === parseInt(this.selectedBurst.id))
      this.campaignBurstScreens = []
      // Get burst delivery type -> show/hide tabs based on it
      this.burstDeliveryType = this.campaignBursts[indexBurst].deliveryType.shortName
      // Append screen 
      this.campaignBursts[indexBurst].campaignBurstScreens.forEach(item => {
        this.campaignBurstScreens.push(item)
      })
    },

    populateCampaignBurstScreen(sid) {
      // find the screen by id and assign to respective variable
      this.selectedCampaignBurstScreen = this.campaignBurstScreens.find(el => el.screen.id === sid)
      if (this.selectedCampaignBurstScreen == null)
        this.selectedCampaignBurstScreen = this.sortedCampaignBurstScreens[0]
      this.selectedScreen = this.selectedCampaignBurstScreen.screen
      this.assignSizes(this.selectedScreen)
      const searchScreenLevelTriggers = (what) => this.campaignBurstScreens.find(element => element.id === what)
      // Get triggers added to screen level.
      this.screenLevelTriggers = searchScreenLevelTriggers(parseInt(this.selectedCampaignBurstScreen.id)).triggerList
      // Check if cinema API trigger exists
      if (this.screenLevelTriggers.includes('cinemaApi'))
        this.fetchInternationalShowtimeMovies()
      // Check if national Rail trigger exists
      if (this.screenLevelTriggers.includes('nationalRail')) {
        this.fetchNationalRailStations()
        this.fetchNationalRailOperators()
      }
      // Check if Heathrow Flight Arrivals trigger exists
      if (this.screenLevelTriggers.includes('heathrowFlightArrivals')) {
        this.fetchIataAirports()
      }
      if (this.screenLevelTriggers.includes('ladbrokes')) {
        this.getLadbrokesInfo('class', 'class');
        this.getLadbrokesInfo('category', 'category');
        this.getLadbrokesInfo('type', 'type');
      }

      // Check if UEFA trigger exists
      if (this.screenLevelTriggers.includes('uefaTriggers') || this.screenLevelTriggers.includes('uefaTikTok')) {
        this.showFilterBar = true
        this.selectedTriggerFilter = ['tweet', 'instagram']
      }
      // Add tik tok filters if uefa TikTok is in the list
      if (this.screenLevelTriggers.includes('uefaTikTok')) {
        this.availableTriggerFilters.push({
          text: 'Tik Tok',
          val: 'tiktok',
        })
      }
    },

    // Fetch Rugby Teams
    getRugbyTeams() {
      // get rugby teams based on competitionId & seasonId
      try {
        const competitionId = parseInt(this.selectedContentForTriggerEdit.triggers.sportRugby.competitionId);
        const seasonId = parseInt(this.selectedContentForTriggerEdit.triggers.sportRugby.seasonId);
        if (competitionId && seasonId)
          this.fetchRugbyTeams(competitionId, seasonId);
      }
      catch (err) {
        // do nothing
      }
    },

    // Fetch Ladbrokes Info
    getLadbrokesInfo(mode, assignTo, expandMode, id) {
      // get ladbrokes info
      try {
        this.fetchLadbrokesInfo(mode, assignTo, expandMode, id);
      }
      catch (err) {
        // do nothing
      }
    },

    // Function to change burst data (review)
    async changeBurst() {
      this.populateCampaignBurst(this.selectedBurst.id)
      this.populateCampaignBurstScreen(this.selectedCampaignBurstScreen.screen.id)
      await this.$router.replace({
        query: {
          ...this.$route.query,
          cbsid: this.selectedCampaignBurstScreen.id,
          sid: this.selectedCampaignBurstScreen.screen.id,
          bid: this.selectedBurst.id,
          bName: this.selectedBurst.name
        }
      }).then(async () => {
        this.updateBreadcrumbs()
        await this.refreshScreens()
      })
    },
    // Functions to chage screen data (review)
    changeScreen() {
      this.loadingScreens = true
      this.populateCampaignBurstScreen(this.selectedCampaignBurstScreen.screen.id)
      this.$router.replace({
        query: {
          ...this.$route.query,
          sid: this.selectedCampaignBurstScreen.screen.id,
          cbsid: this.selectedCampaignBurstScreen.id
        }
      }).then(async () => {
        this.selectedScreen = this.selectedCampaignBurstScreen.screen
        this.assignSizes(this.selectedScreen)
        this.updateBreadcrumbs()
        await this.refreshScreens()
        this.loadingScreens = false
      })
    },
    // Function to update breadcrubms
    updateBreadcrumbs() {
      this.breadcrumbItems[1].text = this.campaign.name
      this.breadcrumbItems[1].href = '/#/dashboard/campaigns/campaign?cid=' + this.$route.query.cid
      if(this.breadcrumbItems[2])
      {
        this.breadcrumbItems[2].text = this.selectedBurst.name
        this.breadcrumbItems[2].href = '/#/dashboard/campaigns/campaign/creatives?cbsid=' + this.selectedCampaignBurstScreen.id + '&bid=' + this.selectedBurst.id + '&cid=' + this.$route.query.cid
      }
      if(this.breadcrumbItems[3])
        this.breadcrumbItems[3].text = this.selectedScreen.name
    },

    // Function to assign sizes
    assignSizes(screen) {
      // assign width and height
      this.previewWidth = screen.width
      this.previewHeight = screen.height
      // get the browser window size
      const browserWidth = window.innerWidth
      const browserHeight = window.innerHeight
      // assign scale factor for preview with a min aspect ratio to dimensions scale
      this.previewScale = this.calculateScale(screen.width, screen.height, browserWidth / 5)
      this.previewScaleEdit = this.calculateScale(screen.width, screen.height, browserWidth / 4)
    },

    calculateScale(width, height, longestSide) {
        const aspectRatio = width / height;
        
        if (width > height) {
            return (longestSide / width) * 100;
        } else {
            return (longestSide / height) * 100;
        }
    },

    sanitizeGroupFab(item, get) {
      let split = item.split(' ')
      if (get === 'index') {
        return (split[0]) ? split[0].replace(/[\[\]]+/g, '') : '0'
      }
      else if (get === 'color') {
        var color = 'grey'
        if (split[1]) {
          if (split[1].toLowerCase() === 'none')
            return '#333'
          else if (split[1].toLowerCase() === 'violet')
            return 'purple'
          else if (split[1].toLowerCase() === 'yellow')
            return '#FDD835'
          color = split[1].toLowerCase()
        }
        return color
      }
    },

    // Function to open image in a new tab
    openImage(url) {
      // screenContent.media.url
      window.open(
        url,
        '_blank', // <- This is what makes it open in a new window.
      )
    },
    // Function to downloadImage
    downloadImage() {
      function forceDownload(blob, filename) {
        var a = document.createElement('a')
        a.download = filename
        a.href = blob
        // For Firefox https://stackoverflow.com/a/32226068
        document.body.appendChild(a)
        a.click()
        a.remove()
      }

      // Current blob size limit is around 500MB for browsers
      function downloadResource(url, filename) {
        if (!filename) filename = url.split('\\').pop().split('/').pop()
        fetch(url, {
          headers: new Headers({
            Origin: location.origin,
          }),
          mode: 'cors',
        })
          .then((response) => response.blob())
          .then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob)
            forceDownload(blobUrl, filename)
          })
          .catch((e) => { })
      }
      downloadResource(this.fullsizePreviewImgUrl)
    },

    async refreshScreens() {
      this.lastUpdatedTime = moment().format('YYYY-MMM-DD HH:mm:ss')
      await CampaignController.getCampaignBurstScreenContents(parseInt(this.$route.query.cbsid))
        .then((res) => {
          // Set each card to start 
          res.data.forEach((artwork) => {
            this.$set(this.cardOne, artwork.id, 'start')
          })
          this.selectedContentForTriggerEdit = null
          // update array of artworks after screen refresh
          this.screenContents = res.data

          // attach campaign burst screen to each screen content
          this.screenContents.forEach((sc) => {
            sc.campaignBurstScreen = this.selectedCampaignBurstScreen
          })

          // magic function to update the data after it has been changed
          this.$nextTick()
          // sort out functions below later
          this.populateTriggerForm()
          this.populateScreenContentTriggers()
          this.sortScreenContents()
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        })
    },

    getDefaultTriggers() {
      // Get the default triggers
      var defaultTriggers = JSON.parse(this.placeholderContentTrigger)
      // set LBC defaults
      if (this.screenLevelTriggers.includes("globalLBCnews")) {
        defaultTriggers["globalLBCnews"]["storyState"] = "Latest News";
        defaultTriggers["globalLBCnews"]["headline"] = "Enter Headline";
        defaultTriggers["globalLBCnews"]["subHeadline"] = "Enter Sub-Headline";
        defaultTriggers["region"]["regions"] = "All Regions";

        defaultTriggers["locationLite"]["copyOn"] = "true";
        defaultTriggers["locationLite"]["maxLineChars"] = 1000;
        defaultTriggers["locationLite"]["minLineChars"] = 0;
        defaultTriggers["locationLite"]["minLines"] = 1;
        defaultTriggers["locationLite"]["maxLines"] = 1;
        defaultTriggers["locationLite"]["textDecoration"] = "{LBC_HEADLINE|AllowEmpty|SkipApostropheFix}¬{LBC_SUB_HEADLINE|AllowEmpty|SkipApostropheFix}¬{LBC_STORY_STATE}";

        defaultTriggers["style"]["xml_styles"] = "{LBC_STORY_STATE}"

        if (this.screenLevelTriggers.includes("dateRange")) {
          defaultTriggers["dateRange"]["startEndDateRange"] = "|"; // indefinite
        }
        defaultTriggers
      }

      // output as string
      return JSON.stringify(defaultTriggers)
    },

    // Upload btn function
    // standard upload (from drag/drop or click)
    async uploadContentQueue() {

      if (this.uploadedFileCreative !== null) {
        const item = this.uploadedFileCreative.shift()
        if (item) {
          // Uploading has started flag (use for a spinner etc)
          this.mediaUploadingFlag = true
          // If uploading from file input instead.
          const formData = new FormData()
          formData.append('file', item)
          formData.append('campaignId', parseInt(this.$route.query.cid))

          await this.saveNextContent(formData)
        }
        else {
          this.mediaUploadingFlag = false
          this.uploadedFileCreative = []
          this.showUploadMediaDialog = false
          this.selectedArtToRepl = null
          this.artworkReplace = false
        }
      }
    },

    // Placeholder confirm function
    confirmCreatePlaceholder() {
      this.placeholderCreatingFlag = true
      CampaignController.addDraftPlaceholder(this.selectedCampaignBurstScreen.id, this.draftPlacholderText)
        .then(() => {
          this.$root.$emit(
            'snackbarSuccess',
            'Successfully created placeholder "' + this.draftPlacholderText + '',
          )
          this.placeholderCreatingFlag = false
          this.draftPlacholderText = ""
          this.showDraftPlaceholderDialog = false
          this.refreshScreens()
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
          this.placeholderCreatingFlag = false
        })
    },

    deleteContentFromScreen() {
      CampaignController.deleteBurstContent({
        campaignBurstScreenId: this.activeNewsStory.campaignBurstScreenId,
        campaignBurstScreenContentId: this.activeNewsStory.id,
      })
        .then(() => {
          //this.refreshScreens()
          // remove the screenContent from the array
          this.screenContents = this.screenContents.filter(item => item.id !== this.activeNewsStory.id)

          this.deleteContentDialog = false
          this.$root.$emit(
            'snackbarSuccess',
            'Successfully deleted news story.',
          )
          this.$nextTick()
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
          this.$nextTick()
        })
    },

    updateCoord(triggerName, triggerContent, val) {
      this.selectedContentForTriggerEdit.triggers[triggerName][triggerContent] =
        val.x * this.ratio + ':' + val.y * this.ratio
    },

    getRule(val, rules) {
      const rulePipes = rules.split("|")
      for (let i = 0; i < rulePipes.length; i++) {
        if (rulePipes[i].startsWith(val)) {
          const pipeParts = rulePipes[i].split(":")
          if (pipeParts.length > 1)
            return parseInt(pipeParts[1])
        }
      }
      return 0
    },

    getEditList(scfte) {
        if(scfte)
          return [scfte]
        if(this.multipleFormatsDialog || this.goLiveDialogSaving || this.killAllDialogSaving){
          if(this.currentFocalPointAspectRatio !== null) {
             const wh = this.currentFocalPointAspectRatio.split("x").map((num) => parseInt(num))
            return this.linkedFormats.filter((lf) => lf.campaignBurstScreenContent.campaignBurstScreen.screen.width === wh[0] && lf.campaignBurstScreenContent.campaignBurstScreen.screen.height === wh[1]).map((lf) => lf.campaignBurstScreenContent)
          } 
          else if(this.updateIframes) {
            return this.linkedFormats.map((lf) => {
              return lf.campaignBurstScreenContent
            } )
          }
          else {
            return this.linkedFormats.filter((lf) => lf.activeSave === true).map((lf) => {
              return lf.campaignBurstScreenContent
            } )
          }

        }
        return[this.activeNewsStory]
    },

    getSaveList() {
        if(this.multipleFormatsDialog || this.goLiveDialogSaving || this.killAllDialogSaving)
          return this.linkedFormats.map((lf) => lf.campaignBurstScreenContent)
        else if(this.customSaveList.length > 0)
          return this.customSaveList
        return[this.activeNewsStory]
    },

    customTriggerWatchersActual(triggerName, triggerContent, content, affectOnly = false) {
      if(this.saving) return; // when closing the dialog, some change events are still triggered
      if (triggerName == "ladbrokes") {
        this.ladbrokesChangeHandler(triggerName, triggerContent, affectOnly);
      }
      if (triggerName == "globalLBCnews") {
        // only target the view that is active
        let scfteEditList = this.getEditList()
        //var iframes = div.getElementsByTagName('iframe');
        scfteEditList.forEach(item => {
          var functionToCall = null;
          var valToPass = content;
          switch (triggerContent) {
            case "moveX":
              functionToCall = "setBGImageX";
              break;
            case "moveY":
              functionToCall = "setBGImageY";
              break;
            case "zoom":
              functionToCall = "setBGZoom";
              break;
            case "storyState":
              functionToCall = "setOverlay";
              valToPass = "'" + content.toLowerCase() + "'";
              break;
            case "headline":
              functionToCall = "setHeadline";
              break;
            case "subHeadline":
              functionToCall = "setSubHeadline";
              break;
            case "updateIframes":
              functionToCall = "updateIframes";
              break;
            default: 
              return; // Don't do anything
          }
          
          if (functionToCall) {
            const iframe = document.getElementById(`iframe-${item.campaignBurstScreen.screen.id}`);
            // Access contentWindow
            let contentWindow = iframe.contentWindow;
            // if(!this.multipleFormatsDialog)
            // {
            //   contentWindow = this.$refs.iFrameView.contentWindow;
            // }
            // else
            //   contentWindow = iframe.contentWindow;

            // Check if contentWindow is accessible
            if (contentWindow) {
              // Now you can safely use postMessage
                  contentWindow.postMessage({ event: JSON.stringify({ iframe_id: "12345", function: functionToCall, parameter: valToPass }) }, '*');
          } else {
              console.error("Unable to access contentWindow of the iframe:", iframe);
          }

          }
        });
        }
    },

    customTriggerWatchersDebounce: _.debounce(function(triggerName, triggerContent, content, affectOnly) {
          this.customTriggerWatchersActual(triggerName, triggerContent, content, affectOnly)
    }, 100),

    customTriggerWatchers(triggerName, triggerContent, content, affectOnly = false) {
      if(this.debounceFlag)
      {
        this.customTriggerWatchersDebounce(triggerName, triggerContent, content, affectOnly)
      }
      else
      {
        // skipping debounce
        this.customTriggerWatchersActual(triggerName, triggerContent, content, affectOnly)
      }
    },

    updateTrigger(triggerName, triggerContent, content, scfte) {
      if(this.saving) return; // when closing the dialog, some change events are still triggered
        // use passed through value if not null
      let scfteEditList = this.getEditList(scfte)
      // Make initial CLONED trigger
      scfteEditList.forEach((sc) => {
          if (!sc.originalTriggerValues)
            sc.originalTriggerValues = JSON.parse(JSON.stringify(sc.triggers))

          let editedContent = JSON.parse(JSON.stringify(sc.triggers))
          editedContent[triggerName][triggerContent] = content
          sc.triggers = editedContent
          this.selectedContentForTriggerEdit.triggers = editedContent

          // Loop through to see if any fields are different
          this.dataHasChanged = false

          // Compare 2 arrays by looping through each object + each object property and compare their value (2 level deep)
          Object.keys(sc.originalTriggerValues).forEach(key => {
            Object.keys(sc.originalTriggerValues[key]).forEach(k => {
              if (sc.originalTriggerValues[key][k] !== sc.triggers[key][k]) {
                this.dataHasChanged = true
              }
            })
          })
        });
        this.customTriggerWatchers(triggerName, triggerContent, content, false)
        this.$nextTick()
    },

    updateTriggerStrArr(triggerName, triggerContent, content, pos, scfte) {
      if(this.saving) return; // when closing the dialog, some change events are still triggered
        // use passed through value if not null
      let scfteEditList = this.getEditList(scfte)
      scfteEditList.forEach((scfte) => {
          // Make initial CLONED trigger
          if (scfte.originalTriggerValues === null)
            scfte.originalTriggerValues = JSON.parse(JSON.stringify(scfte))

          let editedContent = scfte.triggers
          let val = scfte.triggers[triggerName][triggerContent].split(',').map(Number)
          if (val.length > pos)
            val[pos] = content
          editedContent[triggerName][triggerContent] = val.join(",")
          scfte.triggers = editedContent
          this.selectedContentForTriggerEdit = JSON.parse(JSON.stringify(scfte))
          
          this.dataHasChanged = true
        });

      this.customTriggerWatchers(triggerName, triggerContent, content, false)

      this.$nextTick()
    },

    resetTriggers() {
      if (this.activeNewsStory.originalTriggerValues) {
        // Deep clone
        const originalData = JSON.parse(JSON.stringify(this.activeNewsStory.originalTriggerValues));

        // Update the selected content with the original data
        this.selectedContentForTriggerEdit.triggers = originalData;

        // Reset triggers and flags
        this.originalTriggerValues = null;
        this.dataHasChanged = false;
      }
    },

    // save the single screen triggers OR the multiple screen triggers
    async submitTriggers(goLive) {
      // save either the single item or the multiple items
      let scfteSaveList = this.getSaveList()
      // before setting to saving mode, submit the goLive mode
      scfteSaveList.forEach((scfte) => {
        this.selectedContentForTriggerEdit = JSON.parse(JSON.stringify(scfte))
        this.submitGoLive(goLive, scfte)
      })

      // now assign the saving flag
      this.saving = true
      let anyChanges = false
      scfteSaveList.forEach((scfte) => {
          // if the scfte exists in the filteredScreenContents array, update the .triggers
          let scfteIndex = this.filteredScreenContents.findIndex(x => x.id === scfte.id)
          if (scfteIndex !== -1) {
            this.filteredScreenContents[scfteIndex].triggers = scfte.triggers
          }

        // prepare the triggers for save
          anyChanges = this.saveTriggerQueue(scfte) || anyChanges
          scfte.originalTriggerValues = null
      })
      if(!anyChanges)
      {
        // we set this to false because it won't get into afterSave
        this.saving = false
        this.customSaveList = []
      }
      
      // if there is profanity and user didn't check that they checked show dialo
      await this.saveNextTrigger()
      this.sortScreenContents()
      this.dataHasChanged = false
      //this.refreshScreens()
    },

    async submitGoLive(goLive, scfte)
    {
      // Check the goLive flag
      if (goLive) 
        this.updateTrigger('golive', 'islive', 'Published',scfte)
      else 
        this.updateTrigger('golive', 'islive', 'Draft',scfte)
    },

    saveTriggerQueue(scfte) {
      let anyChanges = false
      const searchTriggersJson = (what) => this.triggerJson.triggers.find((element) => element.paramName === what)
      for (var x = 0; x < this.screenLevelTriggers.length; x++) {
        const screenLevelTrigger = this.screenLevelTriggers[x]
          if((Object.keys(scfte.triggers).includes(screenLevelTrigger))) {
            const propName = searchTriggersJson(screenLevelTrigger).parameters.map(x => x.paramName)
            const totalTriggers = propName.length
            for (var k = 0; k < totalTriggers; k++)
            {
              let paramPath = screenLevelTrigger + '.' + propName[k];
              let paramValue = scfte.triggers[screenLevelTrigger][propName[k]];
              // check to see if the originalTriggers has been set and if it's different (only then save)
              if (scfte.originalTriggerValues && scfte.originalTriggerValues[screenLevelTrigger] && scfte.originalTriggerValues[screenLevelTrigger][propName[k]] !== paramValue)
              {
                //convert param value convert any new lines to plain text lines breaks
                //paramValue = paramValue.replace(/(\r\n|\n|\r)/gm, "\\n")

                this.pushTriggerToQueue(scfte,paramPath,paramValue)
                anyChanges = true
              }
            }
          }
      }
      return anyChanges
    },

    pushTriggerToQueue(scfte, paramPath, paramValue) {
      const triggerToSave = {
        id: scfte.id,
        paramPath: paramPath,
        paramValue: paramValue 
      }
      const triggerObj = {
        scfte: scfte,
        triggerToSave: triggerToSave
      }
      this.triggerSaveQueue.push(triggerObj)
    },

    async saveNextTrigger() {
      const nextTrigger = this.triggerSaveQueue.shift()
      let endSaveLog = null
      if(nextTrigger)
      {
        let triggerToSave = nextTrigger.triggerToSave
        let scfte = nextTrigger.scfte

        // check if we should save a log after this
        let lenLeft = this.triggerSaveQueue.filter(x => x.scfte.id === scfte.id).length
        if(lenLeft == 0)
          endSaveLog = scfte
        
        const triggerPath = triggerToSave.paramPath.split(".")
        await CampaignController.setTriggerOnContent(triggerToSave)
          .then(async () => {
            if(this.triggerSaveQueue.length < 1)
              this.$root.$emit('snackbarSuccess','Triggers updated successfully!',)
            else
              this.$root.$emit('snackbarSuccess','Trigger '+JSON.stringify(triggerPath[1])+' updated successfully!',)
            await this.afterSave(endSaveLog)
            await this.saveNextTrigger()
          })
          .catch(async (err) => {
            await this.afterSave(endSaveLog)
            await this.saveNextTrigger()
            this.$root.$emit('snackbarError',''+err.response.data.message,)
          })
      }
    },

    async afterSave(endSaveLog) {
      // save log if required
      if(endSaveLog != null)
      {
        // printout each id of filteredScreenContents
        await this.saveTriggerLog(endSaveLog)
      }
      if(this.triggerSaveQueue.length == 0)
      {
        this.saving = false
        this.customSaveList = []
        this.selectedContentForTriggerEdit = null
      }
    },

    // after all the triggers are saved for a content piece, save a log
    async saveTriggerLog(scfte) {
        CampaignController.logTriggerUpdate({
              id: scfte.id,
              action: 'UpdatedTriggers'
            }).then(async (res) => {
              this.activeNewsStory = {}
              await this.updateArtwork(res)
              this.disableTriggerSave = false
              // Reset store
              this.$store.dispatch('Clipboard/emptyClipboard')
            })
      },

    async saveNextContent(formData) {
      await CampaignController.uploadMedia(formData).then(async (res) => {
        if (!this.artworkReplace) {
          await CampaignController.addContent({
            mediaId: res.data.id,
            triggers: this.getDefaultTriggers(),
            campaignBurstScreenId: this.selectedCampaignBurstScreen.id,
          })
            .then(async (sc) => {
              this.$root.$emit('snackbarSuccess', 'Successfully uploaded artwork.')

              var newData = sc.data
              newData.triggers = JSON.parse(newData.triggers)

              this.screensToLink = this.campaignBurstScreens.filter(item => item.id !== this.selectedCampaignBurstScreen.id)
              for (const destinationCbs of this.screensToLink) {
                await this.addCloneQueue(this.selectedCampaignBurstScreen, destinationCbs, newData) // must be awaited
              }

              await this.saveNextClone()

              // add the resulting data to screenContents to the front
              this.activeNewsStory = newData
              this.screenContents.unshift(newData)
              this.$nextTick()
              this.populateScreenContentTriggers()
              this.sortScreenContents()

              await this.uploadContentQueue()
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', '' + err.response.data.message)
              this.mediaUploadingFlag = false
            })
        } else {
          // Replace Upload
          this.preventReload = true
          await this.submitTriggers(false) // this needs to be awaited before the next step
          this.preventReload = false
          // secondly replace the artwork
          await CampaignController.replaceContentArtwork({
            campaignBurstScreenContentId: this.selectedArtToRepl,
            mediaId: res.data.id
          }).then(async (sc) => {
            this.$root.$emit('snackbarSuccess', 'Successfully replaced artwork',)
            // replace the resulting data in screenContents
            await this.updateArtwork(sc)

            await this.uploadContentQueue()
          })
            .catch((err) => {
              console.log(err)
              this.$root.$emit('snackbarError', '' + err.response.data.message)
            })
        }
      })
    },

    async addCloneQueue(sourcrCbs, destCbs, cbsContent) {
      this.cloneSaveQueue.push({
        sourceCampaignBurstScreen: sourcrCbs,
        destinationCampaignBurstScreen: destCbs,
        campaignBurstScreenContent: cbsContent
      })
    },

    async saveNextClone() {
      const nextClone = this.cloneSaveQueue.shift()
      if (nextClone) {
        await CampaignController.cloneContent({
          sourceCampaignBurstScreenId: nextClone.sourceCampaignBurstScreen.id,
          destinationCampaignBurstScreenId: nextClone.destinationCampaignBurstScreen.id,
          campaignBurstScreenContentId: nextClone.campaignBurstScreenContent.id,
          cloneType: 0 // // 0 = let backend decide, 1 = hard link, 2 = soft link
        })
          .then(async (res) => {
            await this.saveNextClone()
          })
          .catch((err) => {
          })
      }
    },

    getReadableFileSizeString(fileSizeInBytes) {
      var i = -1
      var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
      do {
        fileSizeInBytes = fileSizeInBytes / 1024
        i++
      } while (fileSizeInBytes > 1024)
      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
    },
    // Function to check if the screenContent.media.fileSize is greater than this.selectedCampaignBurstScreen.screen.maxFileSizeKB
    isFileSizeBigger(fileSizeInBytes) {
      // convert bytes in kb
      fileSizeInBytes = fileSizeInBytes / 1024
      if (fileSizeInBytes > this.selectedCampaignBurstScreen.screen.maxFileSizeKB) {
        return true
      }
      return false
    },
    populateTriggerForm() {
      // Empty trigger form to avoid duplication bug.
      this.triggerForm = []
      // Loop through all the triggers on the screen Level and match them with the json doc
      for (var i = 0; i < this.screenLevelTriggers.length; i++) {
        // If trigger in json matches the screen level trigger, add vuetify components to each entry.
        for (var j = 0; j < this.triggerJson.triggers.length; j++) {
          if (
            this.triggerJson.triggers[j].paramName ===
            this.screenLevelTriggers[i]
          ) {
            for (
              var x = 0;
              x < this.triggerJson.triggers[j].parameters.length;
              x++
            ) {
              if (
                this.triggerJson.triggers[j].parameters[x].type === 'boolean'
              ) {
                this.triggerJson.triggers[j].parameters[x].component = 'v-checkbox'
              } else if (
                this.triggerJson.triggers[j].parameters[x].type === 'cropper'
              ) {
                this.triggerJson.triggers[j].parameters[x].component = 'FocalPoint'
              } else {
                this.triggerJson.triggers[j].parameters[x].component = 'v-text-field'
              }
            }
            // After matching the json to the screen level triggers and adding the vuetify components
            if (this.triggerForm[this.triggerJson.triggers[j]] === undefined) {
              this.triggerForm.push(this.triggerJson.triggers[j])
            }
          }
        }
      }
    },

    populateScreenContentTriggers() {
      this.placeholderContentTrigger = new Object()
      // Loop through screen contents, if they have the screen level triggers leave it alone, else make object with defaultValues
      // Search the triggers json for screen level triggers
      const searchTriggersJson = (what) =>
        this.triggerJson.triggers.find((element) => element.paramName === what)
      for (var j = 0; j < this.screenLevelTriggers.length; j++) {
        this.placeholderContentTrigger[this.screenLevelTriggers[j]] = new Object()
        for (var k = 0; k < searchTriggersJson(this.screenLevelTriggers[j]).parameters.length; k++)
          this.placeholderContentTrigger[this.screenLevelTriggers[j]][searchTriggersJson(this.screenLevelTriggers[j]).parameters[k].paramName] = searchTriggersJson(this.screenLevelTriggers[j]).parameters[k].defaultValue
      }
      this.placeholderContentTrigger = JSON.stringify(this.placeholderContentTrigger)
      this.addPlaceholderTriggersToContent()
    },

    addPlaceholderTriggersToContent() {
      // Loop through all content if it does not have the screen level triggers add the placeholderContentTrigger
      // Only loop through if there are screen level triggers else don't do nothing.

      if (this.screenLevelTriggers.length > 0) {
        for (var j = 0; j < this.screenLevelTriggers.length; j++) {
          for (var x = 0; x < this.screenContents.length; x++) {
            if (this.screenContents[x].triggers === {}) {
              this.screenContents[x].triggers = this.placeholderContentTrigger
            } else {
              if (typeof this.screenContents[x].triggers === 'string')
                this.screenContents[x].triggers = JSON.parse(this.screenContents[x].triggers,)
              const searchTriggersJson = (what) => this.triggerJson.triggers.find((element) => element.paramName === what)
              // Check that all screen level triggers are there with their default vals..
              if (this.screenContents[x].triggers[this.screenLevelTriggers[j]] === undefined) {
                this.screenContents[x].triggers[this.screenLevelTriggers[j]] = {}
                let trigger = searchTriggersJson(this.screenLevelTriggers[j])
                for (var k = 0; k < trigger.parameters.length; k++)
                  this.screenContents[x].triggers[this.screenLevelTriggers[j]][searchTriggersJson(this.screenLevelTriggers[j],).parameters[k].paramName] = searchTriggersJson(this.screenLevelTriggers[j],).parameters[k].defaultValue
              }
            }
          }
        }
      }
    },

    fetchInternationalShowtimeMovies() {
      BespokeController.getInternationalShowtimeMovies()
        .then((res) => {
          this.internationalShowtimeMovies = res.data
        }).catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        })
    },

    fetchNationalRailStations() {
      BespokeController.getNationalRailStations()
        .then((res) => {
          this.nationalRailStations = res.data
        }).catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        })
    },

    fetchNationalRailOperators() {
      BespokeController.getNationalRailOperators()
        .then((res) => {
          this.nationalRailOperators = res.data
        }).catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        })
    },

    fetchIataAirports() {
      BespokeController.getIataAirports()
        .then((res) => {
          this.iataAirports = res.data
        }).catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        })
    },

    fetchRugbyTeams(competitionId, seasonId) {
      BespokeController.getRugbyTeams(competitionId, seasonId)
        .then((res) => {
          this.rugbyTeams = res.data
        }).catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        })
    },

    fetchLadbrokesInfo(mode, assignTo, expandMode, id) {
      if (!id)
        id = 0;
      if (!expandMode)
        expandMode = "";

      BespokeController.getLadbrokesInfo(mode, id, expandMode)
        .then((res) => {
          this.$set(this.ladbrokesInfo, assignTo, res.data);
          this.$nextTick()
        }).catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        })
    },

    async downloadItem({ media }) {
      let downloadUrl
      let downloadFileName
      let mimeType
      downloadUrl = media.url
      downloadFileName = media.originalFileName
      mimeType = media.mimeType
      let cachebuster = Math.round(new Date().getTime() / 1000);
      const response = await axios.get(downloadUrl + "?rn=" + cachebuster, { responseType: "blob" });
      const blob = new Blob([response.data], { type: mimeType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = downloadFileName;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    // helper function to determine if boolean or string value is true or false
    isTrue(value) {
      if (value === true || value === 'true') {
        return true
      } else {
        return false
      }
    },

    shouldShowOn(triggerShowOn, triggers, root) {

      let showsOn = [];
      let notShowsOn = [];

      const tokens = triggerShowOn.split(',');

      tokens.forEach(token => {
        const isNotCondition = token.startsWith('!');
        const cleanedToken = isNotCondition ? token.slice(1) : token;
        var [key, value] = cleanedToken.split('[');
        value = value ? value.slice(0, -1) : null; // remove ] from value
        if (isNotCondition)
          notShowsOn.push([key, value]);
        else
          showsOn.push([key, value]);
      });

      // if both lists are empty, shows on is *
      if (showsOn.length === 0 && notShowsOn.length === 0)
        showsOn.push(['*', null]);

      // does triggers have the key. If the r[1] is null it's true, if r[1] is not null, it must match the value
      // key == * is always true
      // notShowOn is all the opposite
      return (showsOn.some((r) => {
        var val = triggers[root] ? triggers[root][r[0]] : triggers[r[0]]; // triggers[r[0]] is legacy for "instagram" and "tweet"      
        return (r[0] === '*' || (val && (r[1] === null || val === r[1])))
      }))
        && !(notShowsOn.some((r) => {
          var val = triggers[root] ? triggers[root][r[0]] : triggers[r[0]]; // triggers[r[0]] is legacy for "instagram" and "tweet"      
          return (r[0] === '*' || (val && (r[1] === null || val === r[1])))
        }))

    }
  }
}
</script>
<style scoped>
.aspect-ratio-packer {
  width: 500px;position: absolute;top: 100px;right: 12px;z-index: 99;background:white;color:black
}

.focal-container {
  width: 100%;
  height: min-content;
  border-radius: 4px;
}


.resize-handle {
  width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    z-index: 25;
    border-radius: 100%;
    border: 1px solid #b3b3b3;
}
.resize-handle.nw { top: -5px; left: -5px; cursor: nw-resize; }
.resize-handle.n { top: -5px; left: 50%; margin-left: -5px; cursor: n-resize; }
.resize-handle.ne { top: -5px; right: -5px; cursor: ne-resize; }
.resize-handle.e { top: 50%; right: -5px; margin-top: -5px; cursor: e-resize; }
.resize-handle.se { bottom: -5px; right: -5px; cursor: se-resize; }
.resize-handle.s { bottom: -5px; left: 50%; margin-left: -5px; cursor: s-resize; }
.resize-handle.sw { bottom: -5px; left: -5px; cursor: sw-resize; }
.resize-handle.w { top: 50%; left: -5px; margin-top: -5px; cursor: w-resize; }

.saved-focal-point {
  transition: top 200ms, left 200ms;
}

.aspect-ratio-box {
  position: absolute;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  pointer-events: none;
  z-index: 10;
  transition: opacity 150ms, width 150ms, height 150ms, left 150ms, top 150ms;
}

.aspect-ratio-box-disabled {
  opacity: 0 !important;
}

.aspect-ratio-box-selected {
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 1);
  z-index: 20 !important;
}

.aspect-ratio-box-unselected {
  opacity: 0.4;
}

.info {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
  z-index: 30;
  background: transparent !important;
  pointer-events: none;
}

#aspect-ratio-output {
  width: 100%;
  height: 150px;
  margin-top: 20px;
  font-family: monospace;
  font-size: 12px;
}

#dynamic-form {
  margin-bottom: 20px;
}

#dynamic-form input[type="number"],
#dynamic-form input[type="text"] {
  width: 80px;
  margin-right: 10px;
}

#dynamic-form button {
  margin-top: 10px;
}

#reset-container,
#toggle-container {
  margin-top: 10px;
}
</style>
<style>
.smaller-hit-area {
  width: 22px !important;
  height: 22px !important;
}

.reportrange-text[data-v-1ebd09d2] {
  background: #1e1e1e;
  border-radius: 5px;
  border-color: #545454;
  color: white;
  font-size: 15px;
  padding-top: 7px;
  transition: border-color 150ms;
}

.reportrange-text[data-v-1ebd09d2]:hover {
  border-color: white;
}

  /* Dark theme styling */
  .daterangepicker {
    font-family: 'Roboto', sans-serif !important;
  }

  .vue-daterange-picker[data-v-1ebd09d2] {
    width: 100% !important;
  }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms ease 1250ms;
  /* Added delay */
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.containerLBC {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px !important;
}

figure {
  border: 1px solid red;
  margin: 0;
}

figure:hover {
  cursor: pointer;
}

    #container {
        position: relative;
        border: 2px solid black;
        width: 100%;
        height: 100%;
    }
    .box {
        box-sizing: border-box;
        display: inline-block;
        margin: 0px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background: var(--v-sidebarColorLight-lighten1);
        transition: opacity 250ms, scale 250ms, background-color 200ms;
    }

    .box-bar{
      width: 100%;
      height: 22px;
      padding: 1px 2px;
    }

    .box-iframe{
      border: none;
      transform-origin: 0px 0px;
      transition: filter 125ms;
    }

    .lbc-viewport{
      position: relative;
      width: 100%;
      padding: 75px 10px 10px;
      overflow: auto;
      background-color: #2c2c2c;
      border-radius: 4px 4px 0px 0px;
      height: 85vh;
    }

    .lbc-viewport-fullscreen{
      height: 100% !important;
    }

    .lbc-viewport-windowed{
      height: 100% !important;
    }

    .lbc-viewport-2 {
      height:calc(85vh - 65px);
      max-width: 100%;
      overflow: hidden auto; 
      border-radius: 4px; 
      border-bottom-left-radius: 0px;
      border-bottom-right-radius:0px
    }

    .lbc-viewport-2-fullscreen{
      height: calc(100vh - 90px) !important;
    }

    .lbc-viewport-2-windowed{
      height: calc(85vh - 65px) !important;
    }

    /* EXPERIMENTAL IMAGE BLOCK */
    .image-viewport {
  width:100%; 
  height:100%;
  background: #0c0c0c;
  overflow:hidden;
  position:relative;
}

.boundary-obj {
  object-fit:cover;
  position:absolute;
  top:0px;
  left:0px;
  transform: translate(-50%, -50%);
}

.screen-constraint {
  background-color: rgba(255, 0, 0, 0.1);
  z-index: 99;
  pointer-events: none;
}

.screen-constraint-label {
  font-family: "Roboto";
    margin: 0px;
    pointer-events: none;
    padding-left: 5px;
    font-weight: 600;
}

.crop-box{
  transition: width 100ms, height 100ms, left 100ms, top 100ms
}

.focalPointDot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #000;
}
.outer {
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: top;
}

.outer>* {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.outer .below {
  z-index: 1;
}

.outer .top {
  z-index: 2;
}


.scroll::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    background-color: transparent;
}

.scroll::-webkit-scrollbar-corner,
.scroll::-webkit-scrollbar-track {
    background-color: transparent;
}

.scroll::-webkit-scrollbar-thumb {
    background-color: grey;
    background-clip: padding-box;
    border: 5px solid transparent;
  border-radius: 100px;
  transition: background-color 200ms;
}

.scroll::-webkit-scrollbar-thumb:hover {
    filter: brightness(110%);
}

.scroll::-webkit-scrollbar-thumb:active {
    background-color: var(--v-primary-base);
}


</style>


<style lang="scss" scoped>
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}
</style>