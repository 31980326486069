<template>
  <div class="outer containerLBC">
    <v-row class="top screen-manager-bar">
      <!-- All Orientation Button with Tooltip -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab x-small elevation="0" @click="filterScreens('all')"
            :color="(orientation == 'all' || orientation == 'both') ? 'var(--v-primary-base)' : ''" class="mr-2">
            <v-icon>mdi-checkbox-multiple-blank-outline</v-icon>
          </v-btn>
        </template>
        <span>View All Screens</span>
      </v-tooltip>
      <!-- Portrait Orientation Button with Tooltip -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab x-small elevation="0" @click="filterScreens('portrait')"
            :color="(orientation == 'portrait' || orientation == 'both') ? 'var(--v-primary-base)' : ''" class="mr-2">
            <v-icon>mdi-crop-portrait</v-icon>
          </v-btn>
        </template>
        <span>View Portrait Screens</span>
      </v-tooltip>
      <!-- Landscape Orientation Button with Tooltip -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab x-small elevation="0" @click="filterScreens('landscape')"
            :color="(orientation == 'landscape' || orientation == 'both') ? 'var(--v-primary-base)' : ''" class="mr-2">
            <v-icon>mdi-crop-landscape</v-icon>
          </v-btn>
        </template>
        <span>View Landscape Screens</span>
      </v-tooltip>
            <!-- Divider -->
            <v-divider vertical style="border-color: #ffffff33;margin-left: 10px;margin-right: 10px;"></v-divider>
                            <!-- Toggle Lock All Button with Tooltip -->
                            <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-btn fab x-small elevation="0" @click="toggleLockAllButton()" :color="(toggleLockAll) ? '#7f6520' : ''" class="mr-2">
                                  <v-icon>mdi-lock-open</v-icon>
                              </v-btn>
                          </template>
                          <span>Lock All Toggle</span>
                      </v-tooltip>

                      <!-- Toggle Select All Button with Tooltip -->
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <v-btn fab x-small elevation="0" @click="toggleSelectAllButton()" :color="(toggleSelectAll) ? 'var(--v-primary-base)' : ''" class="mr-2">
                                  <v-icon>mdi-select-all</v-icon>
                              </v-btn>
                          </template>
                          <span>Select All Toggle</span>
                      </v-tooltip>
      <!-- Divider -->
      <v-divider vertical style="border-color: #ffffff33;margin-left: 10px;margin-right: 10px;"></v-divider>
      <!-- Slider with Tooltip -->
      <v-slider v-model="columns" :max="10" :min="1" :step="1" thumb-label style="padding-right: 5px;"
        @change="updateColumns" :disabled="autoColumns"></v-slider>
      <!-- Fit Button with Tooltip -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab x-small elevation="0" @click="toggleAutoColumns"
            :color="(autoColumns) ? 'var(--v-primary-base)' : ''" class="mr-2">
            <v-icon>mdi-package-variant-closed</v-icon>
          </v-btn>
        </template>
        <span>Pack All Screens into View</span>
      </v-tooltip>
    </v-row>
    <div class="infodebug">
      <div>{{ loadedIframesCount }} out of {{ linkedFormats.length }}</div>
      <div>
        <label for="margin">Margin: </label>
        <input type="number" id="margin" v-model.number="margin" min="0" max="20" step="1" />
        <button @click="applyMargin">Apply Margin</button>
      </div>
      <div>{{ layoutInfo }}</div>
    </div>
    <div ref="screenContainer" class="screen-container" :style="{ overflow: soloItem ? 'hidden' : '' }"
      :class="{ 'maximized-mode': soloItem }">
      <div ref="output" class="output" :style="{ height: `${maxHeight}px` }">
        <div v-for="format in linkedFormats" 
        :key="format.campaignBurstScreenContent.campaignBurstScreen.screen.id" 
        :id="`item-${format.campaignBurstScreenContent.campaignBurstScreen.screen.id}`" 
        class="item" 
        :class="{
          maximized: soloItem === format,
          hidden: !isVisible(format),
          'no-pointer-events': soloItem && soloItem !== format,
        }" 
        :style="getItemStyle(format)">
          <v-card v-if="!loadedIframes[format.campaignBurstScreenContent.campaignBurstScreen.screen.id]" height="100%"
            style="width:100%; position: absolute; top: 22px; z-index: 50; border-radius: 0px;">
            <v-skeleton-loader width="100%" height="100%" type="image"></v-skeleton-loader>
          </v-card>
          <v-btn style="position: absolute;width: 100%;height: 100%;z-index: 1;background: transparent;" @click="toggleSelected(format)"></v-btn>
          <div>
            <span style="font-weight: 500; font-size: x-small; position: absolute;left: 4px;top: -2px; z-index: 1;height:22px;overflow:hidden;padding-right: 50px">{{ format.campaignBurstScreenContent.campaignBurstScreen.screen.aspectRatio + ' (' + format.campaignBurstScreenContent.campaignBurstScreen.screen.width + 'x' + format.campaignBurstScreenContent.campaignBurstScreen.screen.height + ')' }}</span>
            <!-- Solo Button -->
            <div style="position: absolute;right: 22px;top: -2px;z-index: 2;">
              <v-btn class="smaller-hit-area" fab x-small elevation="0" style="margin-top: -3px;background: transparent;" @click="toggleSolo(format)">
                <v-icon small v-if="format.isSolo">mdi-circle</v-icon>
                <v-icon small v-else>mdi-circle-outline</v-icon>
              </v-btn>
            </div>
            <!-- Lock/Unlock button -->
            <div style="position: absolute;right: 2px;top: -2px;z-index: 2;">
              <v-btn class="smaller-hit-area" fab x-small elevation="0" style="margin-top: -3px;background: transparent;" @click="toggleLock(format)" :disabled="format.isFlagged">
                <v-icon small v-if="format.isLocked">mdi-lock</v-icon>
                <v-icon small v-else>mdi-lock-open-variant</v-icon>
              </v-btn>
            </div>
          </div>
          <iframe 
            :id="`iframe-${format.campaignBurstScreenContent.campaignBurstScreen.screen.id}`"
            style="border: none;transition: all 200ms;margin-top:22px"
            @load="() => handleIframeLoad(format.campaignBurstScreenContent.campaignBurstScreen.screen.id)"
            :src="format.campaignBurstScreenContent.iFrameURL"
            :style="{
              width: format.campaignBurstScreenContent.campaignBurstScreen.screen.width + 'px',
              height: format.campaignBurstScreenContent.campaignBurstScreen.screen.height + 'px',
              transform: `scale(${parseFloat(getItemStyle(format).width) / format.campaignBurstScreenContent.campaignBurstScreen.screen.width})`,
              transformOrigin: 'top left',
              filter: format.activeSave ? 'brightness(100%)' : 'brightness(50%)' // Set brightness based on format.activeSave
            }"
            allow="autoplay"
          ></iframe>
        </div>
      </div>
      <div class="overlay" :style="getOverlayStyle()"></div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
const MAXIMIZED_MARGIN = 10;
const TOPBAR_HEIGHT = 22;

export default {
  name: "ScreenManager",
  props: {
    linkedFormats: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      margin: 4,
      autoColumns: true,
      columns: 7,
      soloItem: null,
      orientation: "all",
      containerWidth: 0,
      containerHeight: 0,
      maxHeight: 0,
      itemLayouts: {},
      loadedIframes: {},
      scrollbarWidth: 10,
      activeSaveBuffer: [],
      toggleLockAll: false,
      toggleSelectAll: true
    };
  },
  computed: {
    layoutInfo() {
      const visibleItems = this.linkedFormats.filter((format) =>
        this.isVisible(format)
      ).length;
      return `Viewport: ${this.containerWidth}x${this.containerHeight}, Columns: ${this.columns}, Visible Items: ${visibleItems}`;
    },
    loadedIframesCount() {
      return Object.values(this.loadedIframes).filter(Boolean).length;
    },
  },
  watch: {
    linkedFormats: {
      handler(newFormats) {
        this.updateLoadedIframes(newFormats);
        this.updateLayout(true);
      },
      deep: true
    }
  },
  created() {
    this.debouncedUpdateLayout = _.debounce(() => {
      this.updateLayout(true);
    }, 250);
  },
  mounted() {
    this.initializeFromLocalStorage();
    this.$nextTick(() => {
      this.initializeLayout();
    });
    window.addEventListener("resize", this.debouncedUpdateLayout);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedUpdateLayout);
  },
  methods: {
    toggleLockAllButton() {
    this.toggleLockAll = !this.toggleLockAll;
    const visibleFormats = this.getFilteredFormats();
    visibleFormats.forEach(format => {
      this.$set(format, 'isLocked', this.toggleLockAll);
      if (this.toggleLockAll) {
        this.$set(format, 'activeSave', false);
      }
    });
    this.updateLayout(true);
  },

  toggleSelectAllButton() {
    this.toggleSelectAll = !this.toggleSelectAll;
    const visibleFormats = this.getFilteredFormats();
    this.soloItem = null;
    visibleFormats.forEach(format => {
      if (!format.isLocked) {
        this.$set(format, 'activeSave', this.toggleSelectAll);
        this.$set(format, 'isSolo', false);
      }
    });
    this.updateLayout(true);
  },
    updateLoadedIframes(formats) {
      const newLoadedIframes = {};
      formats.forEach(format => {
        const id = format.campaignBurstScreenContent.campaignBurstScreen.screen.id;
        newLoadedIframes[id] = this.loadedIframes[id] || false;
      });
      this.loadedIframes = newLoadedIframes;
    },
    initializeLayout() {
      if (this.$refs.screenContainer && this.linkedFormats.length > 0) {
        const { width, height } = this.$refs.screenContainer.getBoundingClientRect();
        if (width > 0 && height > 0) {
          this.updateLayout(true);
        } else {
          setTimeout(() => this.initializeLayout(), 100);
        }
      } else {
        setTimeout(() => this.initializeLayout(), 100);
      }
    },
    getOverlayStyle() {
      if (!this.soloItem) {
        return { opacity: 0 };
      }
      const screenContainer = this.$refs.screenContainer;
      return {
        opacity: 1,
        transform: `translateY(${screenContainer.scrollTop}px)`,
      };
    },
    handleIframeLoad(formatId) {
      this.$set(this.loadedIframes, formatId, true);
    },
    initializeFromLocalStorage() {
      const savedMargin = localStorage.getItem("margin");
      if (savedMargin) {
        this.margin = parseInt(savedMargin);
      }
    },
    calculateAspectRatio(width, height) {
      return width / height;
    },
    calculateItemHeight(aspectRatio, columnWidth) {
      return columnWidth / aspectRatio + TOPBAR_HEIGHT;
    },
    binPack(items, numBins, containerWidth, containerHeight) {
      const availableWidth = containerWidth - this.scrollbarWidth;
      const columnWidth = availableWidth / numBins - this.margin * 2;
      const bins = Array.from({ length: numBins }, () => []);
      const binHeights = new Array(numBins).fill(53);

      const sortedItems = items
        .map((format) => ({
          format,
          height: this.calculateItemHeight(
            this.calculateAspectRatio(
              format.campaignBurstScreenContent.campaignBurstScreen.screen.width,
              format.campaignBurstScreenContent.campaignBurstScreen.screen.height
            ),
            columnWidth
          ),
        }))
        .sort((a, b) => b.height - a.height);

      sortedItems.forEach((item) => {
        const minHeightBinIndex = binHeights.indexOf(Math.min(...binHeights));
        if (
          this.autoColumns &&
          binHeights[minHeightBinIndex] + item.height + this.margin * 2 >
          containerHeight
        ) {
          return;
        }
        bins[minHeightBinIndex].push({
          format: item.format,
          height: item.height,
          width: columnWidth,
          x: minHeightBinIndex * (columnWidth + this.margin * 2) + this.margin,
          y: binHeights[minHeightBinIndex] + this.margin,
        });
        binHeights[minHeightBinIndex] += item.height + this.margin * 2;
      });

      return { items: bins.flat(), maxHeight: Math.max(...binHeights) };
    },
    updateLayout(forceFullUpdate = false) {
      console.log('Updating layout, force full update:', forceFullUpdate);
      const screenContainer = this.$refs.screenContainer;
      if (!screenContainer) {
        console.warn('Screen container not found');
        return;
      }
      this.containerWidth = screenContainer.offsetWidth;
      this.containerHeight = screenContainer.clientHeight;
      console.log('Container dimensions:', this.containerWidth, 'x', this.containerHeight);

      if (this.soloItem) {
        this.updateSoloItemLayout();
      } else if (forceFullUpdate) {
        this.updateNormalLayout();
      } else {
        this.updateChangedItems();
      }
    },
    updateNormalLayout() {
      if (this.autoColumns) {
        this.columns = this.findOptimalColumns();
      }

      const filteredFormats = this.getFilteredFormats();
      let { items, maxHeight } = this.binPack(
        filteredFormats,
        this.columns,
        this.containerWidth,
        this.containerHeight
      );

      if (!this.autoColumns && maxHeight > this.containerHeight) {
        ({ items, maxHeight } = this.binPack(
          filteredFormats,
          this.columns,
          this.containerWidth,
          this.containerHeight
        ));
      }

      this.itemLayouts = {};
      items.forEach((item) => {
        const id = item.format.campaignBurstScreenContent.campaignBurstScreen.screen.id;
        this.$set(this.itemLayouts, id, item);
      });

      this.maxHeight = maxHeight;
    },
    updateChangedItems() {
      const filteredFormats = this.getFilteredFormats();
      filteredFormats.forEach(format => {
        const id = format.campaignBurstScreenContent.campaignBurstScreen.screen.id;
        const layout = this.itemLayouts[id];
        if (layout) {
          this.$set(this.itemLayouts, id, {
            ...layout,
            activeSave: format.activeSave,
            isLocked: format.isLocked,
          });
        }
      });
    },
    updateSoloItemLayout() {
      const format = this.soloItem;
      const screen = format.campaignBurstScreenContent.campaignBurstScreen.screen;
      const ratio = this.calculateAspectRatio(screen.width, screen.height);
      const maxWidth = this.containerWidth - 2 * MAXIMIZED_MARGIN;
      const maxHeight = this.containerHeight - 2 * MAXIMIZED_MARGIN - TOPBAR_HEIGHT;
      let newWidth, newHeight;

      if (maxWidth / maxHeight > ratio) {
        newHeight = maxHeight;
        newWidth = newHeight * ratio;
      } else {
        newWidth = maxWidth;
        newHeight = newWidth / ratio;
      }

      const id = format.campaignBurstScreenContent.campaignBurstScreen.screen.id;
      this.itemLayouts = {
        [id]: {
          width: newWidth,
          height: newHeight + TOPBAR_HEIGHT,
          x: (this.containerWidth - newWidth) / 2,
          y: (this.containerHeight - (newHeight + TOPBAR_HEIGHT)) / 2,
        }
      };
    },
    findOptimalColumns() {
      let optimalColumns = 1;
      let maxPackedItems = 0;

      for (let cols = 1; cols <= 100; cols++) {
        const { items } = this.binPack(
          this.getFilteredFormats(),
          cols,
          this.containerWidth,
          this.containerHeight
        );
        if (items.length > maxPackedItems) {
          maxPackedItems = items.length;
          optimalColumns = cols;
        }
      }

      return optimalColumns;
    },
    applyMargin() {
      if (this.margin >= 0 && this.margin <= 20) {
        localStorage.setItem("margin", this.margin.toString());
        this.updateLayout(true);
      } else {
        alert("Please enter a valid margin between 0 and 20.");
      }
    },
    updateColumns() {
      if (!this.autoColumns) {
        this.updateLayout(true);
      }
    },
    toggleAutoColumns() {
      this.autoColumns = !this.autoColumns;
      this.updateLayout(true);
    },
    getFilteredFormats() {
      return this.linkedFormats.filter((format) => {
        const screen = format.campaignBurstScreenContent.campaignBurstScreen.screen;
        const ratio = this.calculateAspectRatio(screen.width, screen.height);
        if (this.orientation === "all") return true;
        if (this.orientation === "landscape") return ratio > 1;
        if (this.orientation === "portrait") return ratio < 1;
      });
    },
    updateActiveSaveBasedOnOrientation() {
      this.linkedFormats.forEach((format) => {
        const screen = format.campaignBurstScreenContent.campaignBurstScreen.screen;
        const ratio = this.calculateAspectRatio(screen.width, screen.height);
        let shouldBeActive = false;

        if (this.orientation === "all") {
          shouldBeActive = true;
        } else if (this.orientation === "landscape") {
          shouldBeActive = ratio > 1;
        } else if (this.orientation === "portrait") {
          shouldBeActive = ratio < 1;
        }

        if (!format.isLocked) {
          this.$set(format, 'activeSave', shouldBeActive);
        }
      });
    },
    filterScreens(filter) {
      if (["all", "landscape", "portrait"].includes(filter)) {
        this.orientation = filter;
        this.updateActiveSaveBasedOnOrientation();
        this.updateLayout(true);
      } else {
        console.error("Invalid filter:", filter);
      }
    },
    toggleSelected(format) {
      if (!format.isLocked) {
        const screen = format.campaignBurstScreenContent.campaignBurstScreen.screen;
        const ratio = this.calculateAspectRatio(screen.width, screen.height);
        let canToggle = false;

        this.$set(format, 'activeSave', !format.activeSave);
        if(!format.activeSave) {
          if (format.isSolo) {
            this.linkedFormats.forEach((linkedFormat) => {
            const savedState = this.activeSaveBuffer.find(state => state.id === linkedFormat.campaignBurstScreenContent.campaignBurstScreen.screen.id);
            if (savedState) {
              if(linkedFormat != format){
                this.$set(linkedFormat, 'activeSave', savedState.state);
              }
              this.$set(linkedFormat, 'isSolo', false);
            }
          });
          this.activeSaveBuffer = [];
          }
        this.$set(format, 'isSolo', false);
        this.soloItem = null;
        }
      this.updateChangedItems();
      }
    },
    toggleLock(format) {
      this.$set(format, 'isLocked', !format.isLocked);
      if (format.isLocked) {
        this.$set(format, 'activeSave', false);
      }
      this.updateChangedItems();
    },
    toggleSolo(format) {
      this.soloItem = this.soloItem === format ? null : format;
      if (!format.isLocked) {
        if (this.soloItem) {
          this.activeSaveBuffer = this.linkedFormats.map(linkedFormat => ({
            id: linkedFormat.campaignBurstScreenContent.campaignBurstScreen.screen.id,
            state: linkedFormat.activeSave
          }));
          this.linkedFormats.forEach((linkedFormat) => {
            if (linkedFormat.campaignBurstScreenContent.campaignBurstScreen.screen.id !== this.soloItem.campaignBurstScreenContent.campaignBurstScreen.screen.id) {
              this.$set(linkedFormat, 'activeSave', false);
              this.$set(linkedFormat, 'isSolo', false);
            } else {
              this.$set(linkedFormat, 'isSolo', true);
              this.$set(linkedFormat, 'activeSave', true);
            }
          });
        } else {
          this.$set(format, 'isSolo', false);
          this.linkedFormats.forEach((linkedFormat) => {
            const savedState = this.activeSaveBuffer.find(state => state.id === linkedFormat.campaignBurstScreenContent.campaignBurstScreen.screen.id);
            if (savedState) {
              this.$set(linkedFormat, 'activeSave', savedState.state);
              this.$set(linkedFormat, 'isSolo', false);
            }
          });
          this.activeSaveBuffer = [];
        }
      }
      this.updateLayout(true);
    },
    getItemStyle(format) {
      const layout = this.itemLayouts[format.campaignBurstScreenContent.campaignBurstScreen.screen.id];
      if (!layout) return { display: "none" };

      const style = {
        width: `${layout.width}px`,
        height: `${layout.height}px`,
        transform: `translate(${layout.x}px, ${layout.y}px)`,
        transition: "all 200ms",
        backgroundColor: format.isFlagged && format.activeSave ? 'red' : (format.isFlagged ? 'darkred' : (format.isLocked ? '#7f6520' : (format.activeSave ? 'var(--v-primary-base)' : ''))),
      };

      if (this.soloItem === format) {
        style.zIndex = 1000;
      }

      return style;
    },
    isVisible(format) {
      const layout = this.itemLayouts[format.campaignBurstScreenContent.campaignBurstScreen.screen.id];
      return !!layout;
    },
  },
};
</script>

<style scoped>
.screen-manager-bar {
  width: 450px;
  height: 45px;
  backdrop-filter: blur(10px) brightness(0.9) contrast(0.9) saturate(0.65);
  box-shadow: rgb(0 0 0 / 38%) 0px 0px 15px !important;
  border: 1px solid rgb(75 75 75 / 42%) !important;
  border-radius: 7px;
  margin-top: 4px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 10px;
  background: #1e1e1ed1;
}

.screen-container {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #2c2c2c;
  position: relative;
  transition: overflow 200ms;
  box-sizing: border-box;
  padding-top: 53px;
}

.infodebug {
  background-color: green;
  padding: 10px;
  font-size: 14px;
  z-index: 1001;
  position: absolute;
  top: 0;
  right: 0;
}

.output {
  width: 100%;
  position: relative;
  pointer-events: none;
  margin-top: -53px;
}

.item {
  position: absolute;
  font-size: 12px;
  transition: all 200ms;
  pointer-events: auto;
  overflow: hidden;
  background-color: var(--v-sidebarColorLight-lighten1);
  border-radius: 5px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: #2c2c2c;
  transition: opacity 200ms;
  pointer-events: none;
  z-index: 50;
}

.outer {
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: top;
}

.outer > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.outer .below {
  z-index: 1;
}

.outer .top {
  z-index: 2;
}
</style>