<!-- OpenMapSelector.vue -->
/**
* OpenMapSelector Component
* 
* A versatile map selection component that allows users to search for and select locations
* using either a search interface or direct map interaction - powered with a web worker and hosted CSV.
* 
* @component
* 
* @example
* import OpenMapSelector from '@/components/map/selector/OpenMapSelector.vue';
* <template>
*   <OpenMapSelector
*     :maxResults="10"
*     :minPopulation="0"
*     :countries="['United Kingdom']"
      :locationId="24073000"
*     @location-selected="onLocationSelected"
*     @error="handleError"
*   />
* </template>
*
* @props {Number} maxResults - Maximum number of results to show in search (default: 10)
* @props {Number} minPopulation - Minimum population filter for cities (default: 0)
* @props {Array} countries - Array of countries to filter results, if empty all countries are shown (default: [])
* @props {Number} locationId - Instantly bring up the desired location with the corresponding ID (default: null)
*
* @emits {Object} location-selected - Emitted when location is confirmed via button
* @emits {String} error - Emitted when an error occurs
*
* 
* @selectedLocation Output Format:
* {
*   id: Number,           // Unique city ID
*   city: String,        // City name
*   city_ascii: String,  // ASCII version of city name
*   lat: Number,         // Latitude
*   lng: Number,         // Longitude
*   country: String,     // Country name
*   iso2: String,        // ISO2 country code
*   iso3: String,        // ISO3 country code
*   admin_name: String,  // Administrative region
*   capital: String,     // Capital status
*   population: Number,  // City population
*   distance: Number     // Distance from search point (if applicable)
* }
* 
* Example selectedLocation:
* {
*   "id": 1826835950,
*   "city": "Larne",
*   "city_ascii": "Larne",
*   "lat": 54.8517,
*   "lng": -5.8133,
*   "country": "United Kingdom",
*   "iso2": "GB",
*   "iso3": "GBR",
*   "admin_name": "Mid and East Antrim",
*   "capital": "",
*   "population": 18853,
*   "distance": 27.159133679376325
* }
* 
* Features:
* - Search for locations by city name or coordinates
* - Click on map to select custom location
* - Draggable marker for fine-tuning location
* - Population filtering
* - Country filtering
* - Nearby location discovery
* - Distance calculations
* - Dark theme map with CARTO basemap
* 
* Note: selectedLocation starts as null and is updated when the user selects a location from search results
*/
<template>
  <v-card class="map-selector">
    <v-row dense style="height: 400px">
      <v-col cols="6" class="search-panel">
        <v-text-field
          v-model="searchQuery"
          label="Search Location"
          append-icon="mdi-magnify"
          outlined
          @input="debounceSearch"
          :loading="isSearching"
          dense
          hide-details
          hint="Enter a town/city or coordinates (lat,lng)"
        ></v-text-field>

        <v-list v-if="selectedLocation" dense>
          <v-list-item
            class="primary"
            style="border-radius: 4px !important; overflow: hidden;height: 78px !important;"
          >
            <v-list-item-content>
              <v-list-item-title>{{ selectedLocation.city }}</v-list-item-title>
              <v-list-item-subtitle v-if="countries.length === 0">
                {{ selectedLocation.country }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Population: {{ formatPopulation(selectedLocation.population) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ formatCoordinates(selectedLocation.lat, selectedLocation.lng) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        
        <v-list v-else dense>
          <v-list-item
            style="border-radius: 4px !important; overflow: hidden;height: 78px !important;background-color: #2b2b2b;"
          >
            <v-list-item-content>
              <v-list-item-subtitle style="white-space: pre-line; text-align: center;">Search for towns/cities, coordinates (lat,long) or click on the map, then select a location.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list dense class="search-results">
          <v-list-item
            v-for="result in searchResults" v-if="!selectedLocation || result.id !== selectedLocation.id"
            :key="result.id || `${result.lat}-${result.lng}`"
            @click="selectLocation(result)"
            style="height: 78px !important;"
          >
            <v-list-item-content>
              <v-list-item-title>{{ result.city }}</v-list-item-title>
              <v-list-item-subtitle v-if="countries.length === 0">
                {{ result.country }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Population: {{ formatPopulation(result.population) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ formatCoordinates(result.lat, result.lng) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-chip
                x-small
                v-if="result.distance !== undefined"
              >
                {{ formatDistance(result.distance) }} mi away
              </v-chip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col cols="6" class="map-container">
        <l-map
          ref="map"
          :zoom="zoom"
          :center="mapCenter"
          :options="mapOptions"
          @click="onMapClick"
        >
          <l-tile-layer
            :url="tileUrl"
            :attribution="attribution"
            :options="tileOptions"
          />
          <l-marker
            v-if="markerPosition"
            :lat-lng="markerPosition"
            :draggable="true"
            @dragend="onMarkerDrag"
          >
            <l-popup>
              <div>
                {{
                  (selectedLocation && selectedLocation.city) ||
                  "Custom Location"
                }}
              </div>
              <div>
                {{ formatCoordinates(markerPosition[0], markerPosition[1]) }}
              </div>
            </l-popup>
          </l-marker>
        </l-map>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { debounce } from "lodash";
import LocationWorker from "worker-loader!./locationWorker.js";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

// Fix for default marker icons
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "OpenMapSelector",

  props: {
    maxResults: {
      type: Number,
      default: 10,
    },
    minPopulation: {
      type: Number,
      default: 0,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    locationId: {
      type: Number,
      default: null
    }
  },

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },

  data: () => ({
    worker: null,
    searchQuery: "",
    searchResults: [],
    selectedLocation: null,
    markerLocation: null,
    isSearching: false,
    zoom: 5,
    mapCenter: [54.5, -4.4],

    mapOptions: {
      zoomControl: true,
      scrollWheelZoom: true,
      zoomSnap: 0.5,
      zoomDelta: 0.5,
    },

    tileUrl: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
    tileOptions: {
      maxZoom: 19,
      minZoom: 2,
    },
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
  }),

  computed: {
    markerPosition() {
      if (this.selectedLocation) {
        return [this.selectedLocation.lat, this.selectedLocation.lng];
      }
      return this.markerLocation
        ? [this.markerLocation.lat, this.markerLocation.lng]
        : null;
    },
    workerConfig() {
      const config = {
        maxResults: this.maxResults,
        minPopulation: this.minPopulation,
        countries: this.countries,
      };
      return config;
    },
  },

  created() {
    this.initializeWorker();
    this.debounceSearch = debounce(this.searchLocation, 300);
  },

  watch: {
    maxResults(newValue) {
      this.updateWorkerConfig();
    },
    minPopulation(newValue) {
      this.updateWorkerConfig();
    },
    countries: {
      handler() {
        this.updateWorkerConfig();
      },
      deep: true,
    },
    locationId: {
      immediate: true,
      handler(newId) {
        if (newId && this.worker) {
          this.worker.postMessage({
            action: 'findById',
            id: newId
          });
        }
      }
    }
  },

  methods: {
    initializeWorker() {
      this.worker = new LocationWorker();

      this.worker.onmessage = ({ data }) => {
        switch (data.action) {
          case "searchResults":
            this.searchResults = data.results;
            this.isSearching = false;
            break;
          case "error":
            this.$emit("error", data.message);
            this.isSearching = false;
            break;
          case "initialized":
            console.log("Worker initialized successfully");
            if (this.locationId) {
              this.worker.postMessage({
                action: 'findById',
                id: this.locationId
              });
            }
            break;
          case "locationFound":
            this.selectLocation(data.location);
            break;
        }
      };

      this.worker.onerror = (error) => {
        this.$emit("error", "Worker error: " + error.message);
        this.isSearching = false;
      };

      this.worker.postMessage({
        action: "initialize",
        config: this.workerConfig,
      });
    },

    updateWorkerConfig() {
      this.worker.postMessage({
        action: "updateConfig",
        config: this.workerConfig,
      });
    },

    searchLocation() {
      if (!this.searchQuery) {
        this.searchResults = [];
        return;
      }

      this.isSearching = true;
      this.worker.postMessage({
        action: "search",
        query: this.searchQuery,
      });
    },

    selectLocation(location) {
      const isReselecting = this.isSelected(location);

      this.selectedLocation = location;
      this.markerLocation = null;
      this.mapCenter = [location.lat, location.lng];
      this.zoom = 10;

      if (this.$refs.map) {
        this.$refs.map.mapObject.setView(
          [location.lat, location.lng],
          this.zoom
        );
      }

      // Always fetch nearby locations and include the selected location
      this.worker.postMessage({
        action: "findNearby",
        coordinates: { lat: location.lat, lng: location.lng },
        selectedLocation: location
      });

      if (this.selectedLocation) {
        this.$emit("location-selected", this.selectedLocation);
      }
    },

    onMapClick(event) {
      const { lat, lng } = event.latlng;
      this.markerLocation = {
        lat: lat,
        lng: lng,
        city: "Custom Location",
        country: "",
        population: 0,
        distance: 0,
      };
      if(this.selectedLocation !== null) {
        this.$emit("location-selected", null);
      }
      this.selectedLocation = null;      
      this.searchQuery = "";

      this.worker.postMessage({
        action: "mapClick",
        coordinates: { lat, lng },
      });

      if (this.$refs.map) {
        this.$refs.map.mapObject.setView([lat, lng], this.zoom);
      }
    },

    onMarkerDrag(event) {
      const { lat, lng } = event.target.getLatLng();

      if (this.selectedLocation) {
        this.selectedLocation = {
          ...this.selectedLocation,
          lat: lat,
          lng: lng,
        };
      } else {
        this.markerLocation = {
          ...this.markerLocation,
          lat: lat,
          lng: lng,
        };
      }

      this.searchQuery = "";

      this.worker.postMessage({
        action: "findNearby",
        coordinates: { lat, lng },
        selectedLocation: this.selectedLocation
      });

      if (this.$refs.map) {
        this.$refs.map.mapObject.setView([lat, lng], this.zoom);
      }
    },

    formatPopulation(pop) {
      return pop ? pop.toLocaleString() : "N/A";
    },

    formatCoordinates(lat, lng) {
      return `${parseFloat(lat).toFixed(4)}, ${parseFloat(lng).toFixed(4)}`;
    },

    formatDistance(distance) {
      if (distance === undefined || distance === null) return 0;
      const miles = distance * 0.621371;
      return miles.toFixed(1);
    },

    isSelected(result) {
      if (!this.selectedLocation || !result) return false;
      if (result.id && this.selectedLocation.id) {
        return result.id === this.selectedLocation.id;
      }
      return (
        result.lat === this.selectedLocation.lat &&
        result.lng === this.selectedLocation.lng
      );
    },
  },

  beforeDestroy() {
    if (this.worker) {
      this.worker.terminate();
    }
    if (this.debounceSearch && this.debounceSearch.cancel) {
      this.debounceSearch.cancel();
    }
  },
};
</script>

<style scoped>
.map-selector {
  height: 100%;
  padding: 10px;
}

.map-container {
  height: 100%;
}

.search-panel {
  height: 100%;
}

.search-results {
  overflow-y: auto;
  height: calc(100% - 134px);
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 0px;
  background-color: #2b2b2b;
}

.selected {
  color: white !important;
}

.selected :deep(.v-list-item__subtitle) {
  color: rgba(255, 255, 255, 0.7) !important;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

:deep(.leaflet-marker-icon) {
  width: 25px !important;
  height: 41px !important;
}

:deep(.leaflet-marker-shadow) {
  width: 41px !important;
  height: 41px !important;
}

:deep(.leaflet-popup-content-wrapper) {
  background-color: #1e1e1e;
  color: white;
}

:deep(.leaflet-popup-tip) {
  background-color: #1e1e1e;
}
</style>
