<template>
  <v-container>
    <v-row class="d-flex justify-start">

      <v-col 
        cols="2" 
        class="pa-0 pr-2 my-2"
      >
      
      <!-- Upload Area -->
        <div 
          @dragover.prevent 
          @drop.prevent 
          @click="$refs.fallbackUpload.click()"
        >
          <input 
            ref="fallbackUpload" 
            type="file" 
            multiple 
            hidden 
            @click="$refs.fallbackUpload.value = ''"
            @change="dragFallback" 
          />
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <div 
                  :ref="'dragDiv1'" 
                  v-bind="attrs"
                  v-on="on"
                  @drop="dragFallback" 
                  @dragleave="$refs.dragDiv1.style.backgroundColor = 'transparent'"
                  @dragover="$refs.dragDiv1.style.backgroundColor = 'grey'"
                  style="
                    background-color: transparent;
                    padding: 0.66em 0em;
                    transition: 0.1s;
                  "
                  :style="hover ? {border: 'solid 1px rgba(255,255,255, .8)'} : {border: 'solid 1px rgba(255,255,255, .3)'}"
                  class="rounded"
                >
                  <div 
                    v-if="sizeMismatchWarning" 
                    style="width:100%; text-align:center; color:yellow;"
                  >
                    <v-icon style="color:yellow">
                      mdi-alert
                    </v-icon>
                    {{ sizeMismatchWarning }}
                  </div>
      
                <div class="mt-4" style="width: 100%; text-align: center;">
                    <v-icon large v-on="on">
                      mdi-cloud-upload
                    </v-icon> 
                  </div> 

                  <div style="width:100%; text-align:center;">
                    Upload Fallback
                  </div>
                  <br>
                  <div v-if="fallback.length">
                    <ul v-for="fallback in fallback" :key="fallback.name">
                      <li>{{ fallback.name }}</li>
                    </ul>
                  </div>
                </div>
              </v-hover>
            </template>
            <span>Upload Creatives</span>
          </v-tooltip>
        </div>
        <v-row>
          <v-col class="pa-0 px-3 ma-0">
            <v-btn v-if="fallback.length > 0" color="primary" block small @click="uploadFallback">Save</v-btn>
          </v-col>
        </v-row>
      </v-col>


<!-- *************************** -->


      <v-spacer />
        <v-col cols="4" class="d-flex justify-end">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn @click="cloneFlight()" color="primary" v-on="on">
              clone latest flight
            </v-btn>
          </template>
          <span>clone latest flight</span> 
        </v-tooltip>
      </v-col>
     
    </v-row>

    <v-row class="d-flex justify-center" style="width: fit-content;">
      <div v-for="(flight, itemIndex) in flights" :key="itemIndex" class="mx-3">
        <v-card elevation="2" width="500" height="90%">

          <v-card-title class="primary white--text">

            <div class="dot mr-2"
              :style="flight.active === true ? 'background:#6aa842;' : 'background:red;cursor:pointer;'"
              @click="confirmActivePackageChangeDialog = true; selectedFlight = flight" />

            {{ flight.majorVersion }}.{{ flight.minorVersion }}.{{ flight.patchVersion }}

          </v-card-title>

          <!-- Fallback & package upload -->
          <v-row class="px-4" style="margin-top:10px">
            <v-col cols="3">
              <v-img :src="flight.fallbackMedia.urlThumb" class="ml-4" width="200" />

            </v-col>

            <v-col cols="8" class="ml-4">
              <v-row>
                <v-col cols="12">
                  <div @dragover.prevent @drop.prevent style="height: 110px">
                    <input :ref="'previewPackageUpload'" type="file" hidden
                      @change="uploadHtmlPackage($event, 'preview', flight.id)">
                    <div :ref="'dragDiv2'" :style="{
                      height: '100%',
                      backgroundColor: 'transparent',
                      borderRadius: '20px',
                      border: 'grey 1px dashed',
                      padding: '10px',

                    }"
                      @drop="dragPreview($event, flight.id); $refs.dragDiv2[itemIndex].style.backgroundColor = 'transparent';"
                      @dragleave="$refs.dragDiv2[itemIndex].style.backgroundColor = 'transparent'"
                      @dragover="$refs.dragDiv2[itemIndex].style.backgroundColor = 'grey'">
                      <div style="width:100%;">


                        <div style="display:inline-block;text-align:right;width:55%">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn icon @click="$refs.previewPackageUpload[itemIndex].click()" v-on="on">
                                <v-icon large>
                                  mdi-cloud-upload
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Upload the Package</span>
                          </v-tooltip>
                        </div>


                        <div class="d-flex justify-end">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip color="secondary" class="d-flex justify-center"
                                style="width: 64px; margin-right: 0px; margin-top: -40px" v-on="on">
                                <span>PREVIEW</span>
                              </v-chip>
                            </template>
                            <span>Preview</span> 
                          </v-tooltip>
                        </div>

                        <v-col v-if="flight.previewPackage" cols="12">
                          <div style="text-align:center">
                            <v-chip v-if="flight.previewPackage" style="text-align:center" color="primary"
                              @click.prevent="getPackage(flight.id, 'preview')">
                              {{ flight.previewPackage.originalFileName }}
                            </v-chip>
                          </div>
                        </v-col>

                        <v-col v-else cols="12">

                          <div style="text-align:center">
                            <v-chip style="text-align:center" color="gray">
                              No Package Uploaded
                            </v-chip>
                          </div>
                        </v-col>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <div @dragover.prevent @drop.prevent style="height: 110px">
                    <input :ref="'livePackageUpload'" type="file" hidden
                      @change="uploadHtmlPackage($event, 'live', flight.id)">
                    <div :ref="'dragDiv3'" :style="{
                      height: '100%',
                      backgroundColor: 'transparent',
                      borderRadius: '20px',
                      border: 'grey 1px dashed',
                      padding: '10px',
                    }"
                      @drop="dragLive($event, flight.id); $refs.dragDiv3[itemIndex].style.backgroundColor = 'transparent';"
                      @dragleave="$refs.dragDiv3[itemIndex].style.backgroundColor = 'transparent'"
                      @dragover="$refs.dragDiv3[itemIndex].style.backgroundColor = 'grey'">
                      <div style="width:100%;">
                        <div style="display:inline-block;text-align:right;width:55%">
                          <!-- <div style="display:inline-block;text-align:right;width:55%">
                          <v-btn
                            icon
                            @click="$refs.livePackageUpload[itemIndex].click()"
                          >
                            <v-icon large>
                              mdi-cloud-upload
                            </v-icon>
                          </v-btn>
                        </div> -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn icon @click="$refs.livePackageUpload[itemIndex].click()" v-on="on">
                                <v-icon large>
                                  mdi-cloud-upload
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Upload the Package</span>
                          </v-tooltip>
                        </div>

                        <!-- <div class="d-flex justify-end">
                          <v-chip
                            color="secondary"
                            class="d-flex justify-center"
                            style="width: 56px;margin-right:0px;margin-top:-40px"
                          >
                            <span>LIVE</span>
                          </v-chip>
                        </div> -->


                        <div class="d-flex justify-end">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip color="secondary" class="d-flex justify-center"
                                style="width: 56px; margin-right: 0px; margin-top: -40px" v-on="on">
                                <span>LIVE</span>
                              </v-chip>
                            </template>
                            <span>Live</span> 
                          </v-tooltip>
                        </div>

                        <v-col v-if="flight.mediaOwnerPackage" cols="12">
                          <div style="text-align:center">
                            <v-chip v-if="flight.mediaOwnerPackage" style="text-align:center" color="primary"
                              @click.prevent="getPackage(flight.id, 'live')">
                              {{ flight.mediaOwnerPackage.originalFileName }}
                            </v-chip>
                          </div>
                        </v-col>

                        <v-col v-else cols="12">
                          <div style="text-align:center">
                            <v-chip style="text-align:center" color="gray">
                              No Package Uploaded
                            </v-chip>
                          </div>
                        </v-col>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0 ma-0">
                  <v-btn :ref="'livePackageSave'" style="display: none;" color="primary" block small
                    @click="uploadHtmlPackage($event, 'live', flight.id)">
                    Save
                  </v-btn>
                </v-col>

              </v-row>
            </v-col>
          </v-row>

          <!-- Actions -->
          <v-row class="px-4">
            <v-col cols="16">

              <!-- <v-btn
                color="secondary"
                @click="
                  (flight.mediaOwnerPackage || flight.previewPackage) 
                  ? generateFormatDialog(flight)
                  : generateFormat(flight)
                "
                :disabled="generatingFormat"
                :loading="generatingFormat && generatingFormatFlightId === flight.id"
                class='mr-4'
              >
               <v-img src='Deliver-Logo-08.png' width="30" contain />
                
              </v-btn> -->
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="secondary" @click="
                    (flight.mediaOwnerPackage || flight.previewPackage)
                      ? generateFormatDialog(flight)
                      : generateFormat(flight)
                    " :disabled="generatingFormat" :loading="generatingFormat && generatingFormatFlightId === flight.id" class="mr-4"
                    v-on="on">
                    <v-img src="Deliver-Logo-08.png" width="30" contain />
                  </v-btn>
                </template>
                <span>generatingFormat </span>
              </v-tooltip>

              <!-- <v-btn
                color="primary"
                @click="generateAdvancedConfig(flight)"
                class='mr-4'
              >
               <v-icon>mdi-file-code</v-icon>
              </v-btn> -->
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" @click="generateAdvancedConfig(flight)" class="mr-4" v-on="on">
                    <v-icon>mdi-file-code</v-icon>
                  </v-btn>
                </template>
                <span>generateAdvancedConfig</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="tertiary" @click="runFlightCheck(flight)" class='mr-4'>
                    <v-icon>
                      mdi-test-tube
                    </v-icon>
                  </v-btn>
                </template>
                <div v-html="prettifyFlightCheckData(flight)">
                </div>
                <span>prettifyFlightCheckData</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="white" @click="
                    (flight.mediaOwnerPackage || flight.previewPackage)
                      ? broadsignScheduleDialog(flight)
                      : scheduleBroadsign(flight)
                    " :disabled="generatingFormat" :loading="generatingFormat && generatingFormatFlightId === flight.id"
                    class='mr-4' v-if="$store.state.Permissions.broadsignContentSchedule">
                    <v-img src="broadsignIcon.svg" width="40"></v-img>
                  </v-btn>
                </template>

                <span>broadsignScheduleDialog</span>
              </v-tooltip>

            </v-col>
          </v-row>
          <!-- Assets -->
          <v-row class="px-4 pt-auto" style="height: 100%;">
            <v-col v-if="campaignAssets && !reloadingFlights" cols="12">
              <div v-if="flight.previewPackage || flight.mediaOwnerPackage">
                {{ embeddedAssets(flight.assets) }}
              </div>

              <v-select v-else v-model="flight.assets" @change="updateFlightAssets(flight)" multiple
                :items="campaignAssets" item-text="media.originalFileName" item-value="id"
                :label="(flight && flight.assets && flight.assets.length > 0) ? 'Attached Assets' : 'Attach Assets'"
                icon="mdi-filter" outlined dense class="text-capitalize">
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    <span>{{ (hideText(item.media.originalFileName, ($vuetify.breakpoint.name === 'xl') ? 15 : 25, true))
                    }}</span>
                  </span>

                  <span v-if="index === 1" class="ml-1 grey--text text-caption">
                    (+{{ flight.assets.length - 1 }})
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <!-- Confirm Generate Format Dialog -->
      <v-dialog v-if="selectedTestFlight" v-model="confirmGenerateFormatDialog" width="500" persistent>
        <v-card>
          <p class="px-5 pt-3 pb-1">
            Are you sure you wish to re-generate a file build?
          </p>
          <p class="px-5 pt-0 caption">
            This will overwrite the
            v{{ selectedTestFlight.majorVersion }}.{{ selectedTestFlight.minorVersion }}.{{
              selectedTestFlight.patchVersion }} build
          </p>

          <div class="d-flex justify-center pt-6 pb-3">
            <v-btn
              @click="generateFormat(selectedTestFlight); confirmGenerateFormatDialog = false; selectedTestFlight = null;"
              class="mx-5">
              confirm
            </v-btn>
            <v-btn color="red" @click="confirmGenerateFormatDialog = false; selectedTestFlight = null;" class="mx-5">
              Cancel
            </v-btn>
          </div>
        </v-card>
      </v-dialog>

      <!--  -->
      <v-dialog v-if="selectedFlight" v-model="confirmActivePackageChangeDialog" width="600">
        <v-card class="pa-4">
          Are you sure you want to make {{ selectedFlight.name }} the active package ?
          <v-card-actions>
            <v-col cols="12" class="text-right">
              <v-btn color="primary" @click="setActivePackage(selectedFlight.id)">
                Confirm
              </v-btn>
              <v-btn color="red" class="mr-3" @click="confirmActivePackageChangeDialog = false;">
                Cancel
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Confirm Generate Format Dialog -->
      <!--- Start of Broadsign Scheduling Dialog -->
      <v-dialog v-if="selectedTestFlight" v-model="confirmBroadsignScheduleDialog" width="600" persistent>
        <v-card>
          <p class="px-5 pt-3 pb-1">
            Are you sure you want to schedule this to Broadsign?
          </p>
          <p class="px-5 pt-0 caption">
            This will try to schedule
            v{{ selectedTestFlight.majorVersion }}.{{ selectedTestFlight.minorVersion }}.{{
              selectedTestFlight.patchVersion }} build
          </p>

          <v-text-field type="number" v-model="broadsign_duration" label="Enter duration in seconds" outlined
            style="width: 200px;" />

          <v-combobox 
            v-show="(this.selectedScreen.dynamicScreen.operatingSystem && this.selectedScreen.dynamicScreen.operatingSystem.name.includes('Windows'))||broadsignIntegrationBypassOScheck"
            v-model="selectedWindowsPlayers" 
            :items="windowsPlayers" 
            label="Windows Players" 
            multiple outlined clearable
            persistent-hint
            hint="Select Windows players for content to be played on. Linux content will likely not work."></v-combobox>

          <v-combobox 
            v-show="(this.selectedScreen.dynamicScreen.operatingSystem && this.selectedScreen.dynamicScreen.operatingSystem.name.includes('Linux'))||broadsignIntegrationBypassOScheck"
            v-model="selectedLinuxPlayers" 
            :items="linuxPlayers" 
            label="Linux Players"
            multiple outlined clearable
            persistent-hint
            hint="Select Linux players for content to be played on. Windows content will likely not work."></v-combobox>

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div>&nbsp
            <input type="checkbox" id="checkbox" v-model="broadsignIntegrationBypassOScheck" />
            <label for="checkbox"> Bypass OS Check</label>
          </div>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <div class="d-flex justify-center pt-6 pb-3">
            <v-btn @click="scheduleBroadsign(selectedTestFlight); selectedTestFlight = null;" class="mx-5">
              confirm
            </v-btn>
            <v-btn color="red" @click="confirmBroadsignScheduleDialog = false; selectedTestFlight = null;" class="mx-5">
              Cancel
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <!--- End of Broadsign Scheduling Dialog -->
    </v-row>

    <!----- Spacer to make the footer line up -->
    <div style="height:250px">&nbsp</div>
  </v-container>
</template>
<script>
import CampaignController from '@/services/controllers/Campaign'
import BroadsignController from '@/services/controllers/Broadsign'
import axios from 'axios'

// Mixins
import helpersConcatText from '@/mixins/helpers/concatText'

export default {
  mixins: [
    helpersConcatText
  ],

  props: ['selectedBurst', 'selectedScreen', 'selectedCampaignBurstScreen', 'campaign'],

  data: () => ({
    broadsignIntegrationBypassOScheck: false,
    broadsign_duration: 6,
    reloadingFlights: false,
    selectedTestFlight: null,
    confirmGenerateFormatDialog: false,
    selectedFlight: null,
    livePackage: [],
    previewPackage: [],
    confirmActivePackageChangeDialog: false,
    campaignBurstScreens: null,
    campaignBursts: null,
    fallback: [],
    fallbackMediaId: null,
    sizeMismatchWarning: null,
    flights: [],
    generatingFormat: false,
    campaignAssets: null,
    selectedCampaignAsset: null,
    confirmBroadsignScheduleDialog: false,
    selectedLinuxPlayers: [],
    linuxPlayers: [],
    selectedWindowsPlayers: [],
    windowsPlayers: [],
  }),

  async created() {
    await this.getFlights();
    await this.getCampaignAssets();
    if (this.$store.state.Permissions.broadsignContentSchedule)
      await this.getBroadsignCampaigns();
  },

  watch: {
    selectedCampaignBurstScreen: async function (newVal) {
      await this.getFlights()
      await this.getCampaignAssets()
    },
  },

  methods: {
    // Try to get the campaigns from Deliver API for broadsign
    async getBroadsignCampaigns() {
      const { data = null } = await BroadsignController.getCampaigns()
        .catch((err) => {
          console.log(err);
        });
      var campaignsRes = data;
      (campaignsRes).forEach(e => {
        //console.log(e.name);
        if (e.name.indexOf("Windows") != -1) {
          this.windowsPlayers.push(e.name);
        } else {
          this.linuxPlayers.push(e.name);
        }
      });
      this.windowsPlayers.sort();
      this.linuxPlayers.sort();
    },

    // Purpose: Get array of flights
    async getFlights() {
      this.reloadingFlights = true

      const formData = new FormData()
      formData.append('screenId', this.selectedScreen.id)
      formData.append('campaignId', parseInt(this.$route.query.cid))

      // Get all flights
      const { data = null } = await CampaignController.getFlights(formData)
        // Catch error
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

      if (data) {
        this.flights = data
        this.$nextTick()
        await this.getAllFlightAssets(data)
      }
      this.reloadingFlights = false
    },

    // PURPOSE: Get assets for flights & then merge to existing global flights variable
    async getAllFlightAssets() {
      let flights = this.flights

      for (let index = 0; index < flights.length; index++) {
        let flight = flights[index]

        const assets = await this.getAssetsForFlight(flight.id)

        const campaignAssets = assets.map(e => e.campaignAsset)

        flights[index] = { ...flight, assets: campaignAssets }
      }

      this.flights = flights
    },

    // PURPOSE: Get assets for flight
    async getAssetsForFlight(flightId) {
      // Get assets for flight
      const { data = null } = await CampaignController.getAssetsForFlight(flightId)
        // Catch error
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

      return data
    },

    // PURPOSE: Get assets for campaign added in campaign/AssetsForm (Campaign Asset Library)
    async getCampaignAssets() {
      const { data = null } = await CampaignController.getAssets(this.campaign.id)
        // Catch error
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

      this.campaignAssets = data
    },

    // PURPOSE: Post asset to flight
    async addAssetToFlight(flight, campaignAssetId) {
      const { data = null } = await CampaignController.addAssetForFlight({ flightId: flight.id, campaignAssetId })
        // Catch error
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

      if (data) {
        this.$root.$emit("snackbarSuccess", `Asset added to flight V${flight.majorVersion}.${flight.minorVersion}.${flight.patchVersion}`)
      }

      return data
    },

    // PURPOSE: Post asset to flight
    async deleteAssetFromFlight(flight, flightAssetId) {
      const { data = null } = await CampaignController.removeAssetFromFlight(flightAssetId)
        // Catch error
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

      if (data) {
        this.$root.$emit("snackbarSuccess", `Asset removed from flight V${flight.majorVersion}.${flight.minorVersion}.${flight.patchVersion}`)
      }

      return data
    },

    // PURPOSE: Update flight assets handler (Post & Delete)
    async updateFlightAssets(flight) {
      // Get current server assets
      const serverAssets = await this.getAssetsForFlight(flight.id)
        // Catch error
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

      // First: Loop through local flight assets to see if an asset should be added to server (updated in dropdown box) 
      for (let index = 0; index < flight.assets.length; index++) {
        // Get current local asset
        const assetId = flight.assets[index]

        // Does local asset exist in server assets
        const matchingAsset = serverAssets.find(serverAsset => serverAsset.campaignAsset.id == assetId)

        // Add asset to server
        if (!matchingAsset) {
          // Add asset
          await this.addAssetToFlight(flight, assetId)
        }
      }

      // Second: Loop through server flight assets to see if an asset should be removed from the server
      for (let index = 0; index < serverAssets.length; index++) {
        // Get current server asset
        const serverAsset = serverAssets[index]

        // Does server asset exist in local assets
        const matchingAsset = flight.assets.find(localAsset => localAsset === serverAsset.campaignAsset.id)

        // Remove asset from server
        if (!matchingAsset) {
          await this.deleteAssetFromFlight(flight, serverAsset.id)
        }
      }

      this.getFlights()
    },

    // PURPOSE: Clone latest flight (with fallback and new version)
    async cloneFlight() {
      const flight = {
        campaignId: this.campaign.id,
        screenId: this.selectedScreen.id,
        fallbackMediaId: this.flights[0].fallbackMedia.id,
      }

      const { data = null } = await CampaignController.addFlight(flight)
      if (data) this.getFlights()
    },

    embeddedAssets(assets) {
      let results = []

      assets.forEach(asset => {
        results.push(asset.media.originalFileName)
      })

      return results.join(', ')
    },

    generateFormatDialog(flight) {
      this.selectedTestFlight = flight;
      this.confirmGenerateFormatDialog = true;
    },

    broadsignScheduleDialog(flight) {
      this.broadsign_duration = 10;
      this.selectedTestFlight = flight;
      this.confirmBroadsignScheduleDialog = this.selectedScreen.dynamicScreen.operatingSystem && ((this.selectedScreen.dynamicScreen.operatingSystem.name == "Windows"
        || this.selectedScreen.dynamicScreen.operatingSystem.name == "Linux"
        || this.selectedScreen.dynamicScreen.operatingSystem.name == "Linux/Windows") &&
        this.selectedScreen.dynamicScreen.playerSoftware.name.includes('Broadsign'));
      if (!this.confirmBroadsignScheduleDialog) {
        this.$root.$emit('snackbarError', "Not Broadsign Content")
      }
      this.selectedLinuxPlayers = [];
      this.selectedWindowsPlayers = [];
    },

    prettifyFlightCheckData(flight) {
      var json = JSON.parse(flight.flightChecks);
      var output = "";
      for (const param in json) {
        let value = json[param]
        if (param.toLowerCase().indexOf("preflightcheck") == 0) {
          output += "<b>" + param + ": </b>" + value + "<br/>";
        }
        else if (param.toLowerCase().indexOf("flightcheck") == 0) {
          output += "<b>" + param + ": </b>" + value.result + ":" + value.message + "<br/>";
        }
        else {
          output += "<b>" + param + ": </b>" + value + "<br/>";
        }
      }
      return output;
    },

    generateAdvancedConfig(flight) {
      axios.get(process.env.VUE_APP_GENERATOR_URL + 'generate-advanced-config?fid=' + flight.id + "&cbsid=" + this.selectedCampaignBurstScreen.id, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      ).then((res) => {
        const file = new File([res.data], 'advanced-config.txt', { type: 'application/txt' })
        const downloadUrl = URL.createObjectURL(file)
        const a = document.createElement('a')
        a.href = downloadUrl

        a.download = `${this.campaign.campaignFolderName}-${this.selectedScreen.width}x${this.selectedScreen.height}-${this.selectedScreen.mediaOwner.name.toLowerCase().replace(/ /g, '-')}-${this.selectedScreen.name.toLowerCase().replace(/ /g, '-')}-${flight.majorVersion}.${flight.minorVersion}.${flight.patchVersion}.txt`
        document.body.appendChild(a)
        a.click()
      })
    },

    runFlightCheck(flight) {
      this.$root.$emit('snackbarSuccess', "Running flight check, this may take a moment ...");
      CampaignController.runFlightCheck(flight.id)
        .then((res) => {
          this.getFlights()
          this.$root.$emit('snackbarSuccess', "Flight Check Complete. See results pane.")
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        });
    },

    async scheduleBroadsign(flight) {
      // fix to not make it randomly send a request without a button click... why does this happen!??
      if (this.confirmBroadsignScheduleDialog == false) {
        return;
      }
      this.confirmBroadsignScheduleDialog = false;

      this.$root.$emit('snackbarSuccess', "Scheduling content to broadsign, this might take a while.");
      const { data = null } = await BroadsignController.getFlightPackageURL(flight.id)
        .catch((err) => {
          console.log(err);
        });
      let xhtmlpackageURL = data;
      /*     axios.get('http://localhost:5002/api/Airport/GetFlightPackageURL/' + flight.id)
     .then((res) => {
           console.log(res.data);
           xhtmlpackageURL = res.data;
           */

      //        const configRes = await axios.get('http://localhost:3001/generate-advanced-config?fid=' + flight.id + "&cbsid=" + this.selectedCampaignBurstScreen.id, {


      // can't do this locally through the vue controllers...
      const configRes = await axios.get(process.env.VUE_APP_GENERATOR_URL + 'generate-advanced-config?fid=' + flight.id + "&cbsid=" + this.selectedCampaignBurstScreen.id, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      //console.log(configRes);
      //     .then((res) => {
      var enc = new TextDecoder("utf-8");
      var adv_str = enc.decode(configRes.data);
      var adv_config_str = adv_str.substring(adv_str.indexOf('<monitor_sync_config'), adv_str.indexOf('</monitor_sync_config>') + 22).replaceAll('\r', '').trim();
      //console.log(adv_config_str);
      let contentName = `${this.campaign.campaignFolderName}-${this.selectedScreen.width}x${this.selectedScreen.height}-${this.selectedScreen.mediaOwner.name.toLowerCase().replace(/ /g, '-')}-${this.selectedScreen.name.toLowerCase().replace(/ /g, '-')}-${flight.majorVersion}.${flight.minorVersion}.${flight.patchVersion}`;

      let bsPostURLdata = {
        "attributes": "main_html_file_path=index.html\nduration=10000",
        "container_id": 657630794,
        "domain_id": 142901010,
        "name": contentName,
        "url": xhtmlpackageURL,
      };

      //console.log(JSON.stringify(new Array(bsPostURLdata, adv_config_str,[...this.selectedWindowsPlayers, ...this.selectedLinuxPlayers])));

      /*****/
      const result = await BroadsignController.postToSchedule(
        JSON.stringify(new Array(bsPostURLdata, adv_config_str,
          [...this.selectedWindowsPlayers, ...this.selectedLinuxPlayers],
          "xhtml", this.broadsign_duration)))
        .catch((err) => {
          console.log(err);
          this.$root.$emit('snackbarError',
            "Content failed to schedule to broadsign: " + err.response.data.message);
        });
      //console.log(result);
      this.$root.$emit('snackbarSuccess', "Content successfully scheduled to broadsign.");
      //console.log('Success:', data);
      /******/ /*
                fetch('http://localhost:5002/api/BroadSignAPI/PostSchedule', {
                  method: 'POST', // or 'PUT'
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: 
                    JSON.stringify(new Array(bsPostURLdata, adv_config_str, 
                      [...this.selectedWindowsPlayers, ...this.selectedLinuxPlayers]))
                })
                  .then((response) => console.log("Post request sent to Deliver"))
                  .then((data) => {
                    this.$root.$emit('snackbarSuccess', "Content successfully scheduled to broadsign.");
                    console.log('Success:', data);
                  })
                  .catch((error) => {
                    this.$root.$emit('snackbarError', error);
                    console.error('Fetch Error:', error);
                  });
              /*****/
    },

    generateFormat(flight) {
      this.generatingFormat = true
      this.generatingFormatFlightId = flight.id

      axios.get(process.env.VUE_APP_GENERATOR_URL + 'generate?fid=' + flight.id, {
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(async (res) => {
          let fileName = `${this.campaign.campaignFolderName}-${this.selectedScreen.width}x${this.selectedScreen.height}-${this.selectedScreen.mediaOwner.name.toLowerCase().replace(/ /g, '-')}-${this.selectedScreen.name.toLowerCase().replace(/ /g, '-')}-${flight.majorVersion}.${flight.minorVersion}.${flight.patchVersion}.zip`
          const file = new File([res.data], fileName, { type: 'application/zip' })
          // Send blob to API to create new version
          const formData = new FormData()
          formData.append('file', file)
          formData.append('campaignId', parseInt(this.$route.query.cid))

          // Uploading fallback to aws and capturing the response 
          const uploadMedia = await CampaignController.uploadMedia(formData)
            .catch(err => { this.$root.$emit('snackbarError', err) })

          await this.setPackage(flight.id, uploadMedia.data.id, 'live')
          await this.setPackage(flight.id, uploadMedia.data.id, 'preview')

          await this.getPackage(flight.id, 'live')

          this.generatingFormat = false
        })
        .catch(() => {
          this.generatingFormat = false
          this.$root.$emit('snackbarError', 'Failed connecting to Generator, please try again.')
        })
    },

    async setPackage(flightId, packageMediaId, packageType) {
      const setPackage = await CampaignController.setPackage({ flightId, packageMediaId, packageType })
        .catch(err => { this.$root.$emit('snackbarError', err) })

      setPackage && this.$root.$emit('snackbarSuccess', packageType + ' HTML Package uploaded Successfully.') && this.getFlights()
    },

    async getPackage(flightId, packageType) {
      this.generatingFormat = true
      const getPackage = CampaignController.getPackage(flightId, packageType)
        .then((res) => {
          const folderPath = decodeURI(res.headers['content-disposition'].split("filename=")[1].split(';')[0]).replaceAll('"', '').split('\\')
          const fileName = folderPath[folderPath.length - 1]
          const blob = new Blob([res.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((err) => {
          err.response.data.text().then((errRes) => {
            this.$root.$emit('snackbarError', JSON.parse(errRes).message) // because responseType is set to blob
          })
        })
        .finally(() => {
          this.generatingFormat = false
        });

      return getPackage && this.$root.$emit('snackbarSuccess', 'Downloading Flight. Check your download bar!')
    },

    setActivePackage(flightId) {
      CampaignController.setActivePackage(flightId).then((res) => {
        this.getFlights()

        this.$root.$emit('snackbarSuccess', 'Successfully swapped active flight')
        this.confirmActivePackageChangeDialog = false
      })
        .catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        })
    },

    uploadHtmlPackage(e, type, id) {
      const formData = new FormData()

      if (e.target.files) {
        formData.append('file', Object.values(e.target.files)[0])
      }
      else {
        formData.append('file', type === 'live' ? this.livePackage[0] : this.previewPackage[0])
      }

      formData.append('campaignId', parseInt(this.$route.query.cid))

      CampaignController.uploadMedia(formData)
        .then((res) => {
          CampaignController.setPackage({
            flightId: id,
            packageMediaId: res.data.id,
            packageType: type,
          })
            .then(() => {
              this.$root.$emit('snackbarSuccess', 'HTML Package uploaded Successfully.')
              this.getFlights()
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', '' + err.response.data.message)
            })
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        })
    },

    uploadFallback() {
      const formData = new FormData()
      formData.append('file', this.fallback[0])
      formData.append('campaignId', parseInt(this.$route.query.cid))
      CampaignController.uploadMedia(formData)
        .then((res) => {
          this.$root.$emit('snackbarSuccess', 'Successfully uploaded fallback')
          CampaignController.addFlight({
            campaignId: parseInt(this.$route.query.cid),
            screenId: this.selectedScreen.id,
            fallbackMediaId: res.data.id,
          })
            .then(() => {
              this.sizeMismatchWarning = null
              this.fallback = []
              this.getFlights()
              this.$nextTick()
            })
            .catch((errFlight) => {
              this.$root.$emit(
                'snackbarError',
                errFlight.response.data.message,
              )
            })
        })
        .catch((err) => {
          this.$root.$emit('snackbarError', '' + err.response.data.message)
        })
    },

    dragFallback(e) {
      if (e.dataTransfer === undefined) {
        this.fallback = Object.values(e.target.files)
      } else {
        this.fallback = Object.values(e.dataTransfer.files)
      }
      this.$refs.dragDiv1.style.backgroundColor = 'transparent'
      const reader = new FileReader()

      // Read the contents of Image File.
      reader.readAsDataURL(this.fallback[0])
      reader.onload = (e) => {
        // Initiate the JavaScript Image object.
        var image = new Image()

        // Set the Base64 string return from FileReader as source.
        image.src = Object.values(e.target.result)

        // Validate the File Height and Width.
        image.onload = () => {
          var height = image.height
          var width = image.width
          if (this.selectedScreen.width === width && this.selectedScreen.height === height) {
            this.sizeMismatchWarning = null
          } else {
            this.sizeMismatchWarning = `Warning! Uploaded fallback is ${width}x${height} screen dimensions are ${this.selectedScreen.width}x${this.selectedScreen.height}`
          }
        }
      }
    },

    dragPreview(e, flightId) {
      this.previewPackage = Object.values(e.dataTransfer.files)
      this.uploadHtmlPackage(e, 'preview', flightId)
    },

    dragLive(e, flightId) {
      this.livePackage = Object.values(e.dataTransfer.files)
      this.uploadHtmlPackage(e, 'live', flightId)
    },
  },
}
</script>
<style scoped>
.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>